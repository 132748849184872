import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { adminMenu, modulesMenu, superAdminMenu } from '../../../../menu';
import { useContext } from 'react';
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider';

/**
 * Filter menu based on user permissions
 */
const filterMenu = (menu: any, userCan: Function) => {
  return Object.keys(menu).reduce((obj: any, key: string) => {
    const menuItem = menu[key];
    let clonedMenuItem = { ...menuItem };

    if (menuItem.permissions_required !== undefined) {
      const { action, entity } = menuItem.permissions_required;
      if (!userCan(action, entity)) {
        return obj;
      }
    }

    if (menuItem.subMenu !== undefined && menuItem.subMenu !== null) {
      const filteredSubMenu = filterMenu(menuItem.subMenu, userCan);
      if (Object.keys(filteredSubMenu).length === 0) {
        // Excluir el elemento principal si todos los elementos del submenú están excluidos
        return obj;
      }
      clonedMenuItem.subMenu = filteredSubMenu;
    }

    obj[key] = clonedMenuItem;
    return obj;
  }, {});
};

// Define la función para renderizar los elementos de menú
const renderMenuItems = (menu: any , isSub: boolean = false) => {
  return Object.keys(menu).map((key) => {
    const menuItem = menu[key];
    if (menuItem.subMenu) {
      return (
        <SidebarMenuItemWithSub key={menuItem.id + "-sidebar-item-with-sub-" + menuItem.path} to={menuItem.path} title={menuItem.text} icon={menuItem.icon}>
          {renderMenuItems(menuItem.subMenu , true)}
        </SidebarMenuItemWithSub>
      );
    } else if (menuItem.isTitle === true) {
      return(
        <div className='menu-item' key={menuItem.id + "-sidebar-item-title"}>
          <div className='menu-content pb-2'>
            <span className='menu-section text-light text-uppercase fs-8 ls-1' key={menuItem.text + "-sidebar-item-title"}>{menuItem.text}</span>
          </div>
        </div>
      )
    } else {
      return <SidebarMenuItem key={menuItem.id + "-sidebar-item-" + menuItem.path} to={menuItem.path} title={menuItem.text} icon={menuItem.icon} isSub={isSub}/>;
    }
  });
};

const SidebarMenuMain = () => {

  const { userCan, permissions } = useContext(PrivilegeContext);

  const filteredMenuAdmin = filterMenu(adminMenu, userCan);
  const filteredMenuSuperAdmin = filterMenu(superAdminMenu, userCan);
  const filteredMenuModules = filterMenu(modulesMenu, userCan);

  const renderedMenuItemAdmin = renderMenuItems(filteredMenuAdmin);
  const renderedMenuItemSuperAdmin = renderMenuItems(filteredMenuSuperAdmin);
  const renderedMenuItemModules = renderMenuItems(filteredMenuModules);

  return (
    <>
      {renderedMenuItemModules}
      {renderedMenuItemSuperAdmin}
      {renderedMenuItemAdmin}
    </>
  )
}

export {SidebarMenuMain}
