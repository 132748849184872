import { Fragment } from 'react';
import { FiltersProvider } from '../../../../components/filters/FiltersProvider';
import { MembersCard } from '../../../../components/Cards/MembersCard';
import { useParams } from 'react-router-dom';

export interface IProjectViewMembersProps {
}

export default function ProjectViewMembers(props: IProjectViewMembersProps) {

    const { id = '' } = useParams<{ id: string }>();

    return (
        <Fragment>

            <FiltersProvider defaultFilterFilters={{ projectId: id, search_array: '' }}>
                <MembersCard id={id} />
            </FiltersProvider>
        </Fragment>
    );
}
