import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

interface IConfirmationAlert {
    title: string;
    text?: string;
    html?: JSX.Element;
    projectName?: string;
    onDeny: () => void;
    icon: 'warning' | 'success' | 'error' | 'info' | 'question';
    onConfirm: () => void;
}

const MySwal = withReactContent(Swal);

export const handleDeleteAlert = async (props: IConfirmationAlert) => {
    
    let options: any = {
        heightAuto: false,
    };
    
    if(props.text) {
        options.text = props.text;
    }

    if(props.html) {
        options.html = props.html;
    }

    options = {
        ...options,
        title: props.title,
        icon: props.icon,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#247586',
        cancelButtonColor: '#e5133d',
        confirmButtonText: 'Borrar',
        denyButtonText: 'Deshabilitar',
        denyButtonColor: '#f0ad4e',
        cancelButtonText: 'Cancelar',
    }

    return MySwal.fire(options).then(async (result) => {
        if (result.isConfirmed) {
            const { value : projectName } = await Swal.fire({
                title: '¿Estás seguro?',
                text: "Escribe el nombre del proyecto para confirmar.",
                input: "text",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#247586',
                cancelButtonColor: '#e5133d',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                inputValidator: (value) => {
                    if (value !== props.projectName) {
                        return 'El nombre del proyecto no coincide';
                    }
                }});

            if (projectName) {
                    props.onConfirm();
            }

        } else if (result.isDenied) {
          props.onDeny();
        }
    });
}
