import { Fragment } from "react"
import { UserList } from "./list"
import { FiltersProvider } from "../../components/filters/FiltersProvider"

const UserListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <UserList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default UserListWrapper 