import * as React from 'react';
import AsyncImg from '../form/AsyncImg';
import { KTSVG } from '../Icon/KTSVG';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/authSlice';
import { MenuComponent } from '../../utils/MenuComponent';

export interface IUserActionMenuProps {
}

export function UserActionMenu(props: IUserActionMenuProps) {


    const user = useSelector((state: RootState) => state.auth.user);
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    React.useEffect(() => {
        // Execute dropdown elements
        MenuComponent.updateDropdowns();
      }, [isAuthenticated]);

    return (
        <>
            <div className="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
                <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
                    <div className="symbol symbol-50px">
                        <AsyncImg transparent src={user?.profilePictureId || ""} type={"avatar"}></AsyncImg>
                    </div>
                    <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
                        <div className="d-flex">
                            <div className="flex-grow-1 me-2">

                                <a href="#" className="text-white text-hover-primary fs-6 fw-bold">{user?.name}</a>

                                <span className="text-gray-600 fw-semibold d-block fs-8 mb-1">{user?.email}</span>

                                <div className="d-flex align-items-center text-success fs-9">
                                    <span className="bullet bullet-dot bg-success me-1"></span>online
                                </div>

                            </div>

                            <div className="me-n2">
                                <div
                                    className="cursor-pointer symbol symbol-30px symbol-md-40px"
                                    data-kt-menu-trigger="{default: 'click'}"
                                    data-kt-menu-attach="parent"
                                    data-kt-menu-placement="bottom-end"
                                >
                                    <KTSVG path="/media/icons/duotune/coding/cod001.svg" className='svg-icon-1' />
                                </div>

                                <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                                    data-kt-menu="true"
                                >
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            <div className="symbol symbol-50px me-5">
                                                <AsyncImg transparent src={user?.profilePictureId || ""} type={"avatar"}></AsyncImg>
                                            </div>

                                            <div className="d-flex flex-column">
                                                <div className="fw-bold d-flex align-items-center fs-5">
                                                    {user?.name}
                                                </div>
                                                <a
                                                    href="#"
                                                    className="fw-semibold text-muted text-hover-primary fs-7"
                                                >
                                                    {user?.email}
                                                </a>
                                                <span className="badge badge-success text-light fw-bold fs-8">
                                                    {user?.roles[0]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="separator my-2"></div>

                                    <div className="menu-item px-5">
                                        <a
                                            onClick={() => { navigate('/profile') }}
                                            className="menu-link px-5"
                                        >
                                            Perfil
                                        </a>
                                    </div>
                                    <div className="separator my-2"></div>

                                    <div className="menu-item px-5">
                                        <div
                                            onClick={() => { dispatch(logout()) }}
                                            className="menu-link px-5"
                                        >
                                            Cerrar Sesión
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
