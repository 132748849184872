import { Fragment } from "react"
import { FiltersProvider } from "../../components/filters/FiltersProvider"
import { BackupList } from "./list"

const BackupsListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <BackupList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default BackupsListWrapper