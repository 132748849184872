import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { ProjectTypeService } from "../../../services/project/projectTypeService";
import {CirclePicker} from 'react-color';
import { Button, CloseButton, Form, FormControl, FormGroup, FormLabel, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { KTSVG } from "../../../components/Icon/KTSVG";

interface RoleCreateModalProps {
  isOpen: boolean;
  setIsOpen(...args: unknown[]): unknown;
  mode: 'create' | 'edit';
  projectType?: any;
  onClose?: (message?: string) => void;
}

const ProjectTypeCreateModal: FC<RoleCreateModalProps> = ({ isOpen, setIsOpen, onClose, mode, projectType }) => {
  const [loadign, setLoading] = useState(false);

  const createProjectType = async (values: any) => {
    try {

      setLoading(true);

      let response = await (
        await new ProjectTypeService().createProjectType(values)
      ).getResponseData();

      if (response.success) {
        setLoading(false);
        setIsOpen(false);
        onClose && onClose("Tipo de Proyecto creado correctamente");
      } else {
        setLoading(false);
        toast.error(response.message);
      }      

    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };

  const updateProjectType = async (values: any) => {
    try {

        setLoading(true);

        values.projectTypeId = projectType?.id ?? '';

        let response = await (await (new ProjectTypeService()).editProjectType(values)).getResponseData();

        if (response.success) {
            setLoading(false);
            setIsOpen(false);
            onClose && onClose("Tipo de Proyecto actualizado correctamente");
        } else {
            setLoading(false);
            toast.error(response.message);
        }

    } catch (e) {
        console.log('error', e);
        setLoading(false);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
        mode === 'create'
            ?
            {
                name: '',
                description: '',
                color: '',
            }
            :
            {
                name: projectType?.name ?? '',
                description: projectType?.description ?? '',
                color: projectType?.color ?? '',
            }
    ,
    onSubmit: (values) => {
        if (mode === 'create') {
          if (values.color === '') {
            values.color = '#000000';
            toast.warning("Si no eliges ningun color se le asignara el color negro por defecto")
          } else {
            createProjectType(values as any);
          }
        } else {
          updateProjectType(values);
        }
    },
  });

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header onHide={setIsOpen} className="pb-0 border-0 bg-success pb-2 pt-5 pb-4">
        <div className="modal-title">
          <h2 className="text-light align-text-bottom">{mode === 'create' ? 'Nuevo' : 'Editar'} Tipo de Proyecto</h2>
        </div>
        <CloseButton className="btn-close-light custom-close-btn" onClick={ () => {setIsOpen(false)}} />

      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
      <Modal.Body className="mx-5 mx-xl-18 pt-5 pb-15">
        <div className='row g-4'>
          <FormGroup id='name' className='col-md-12' >
            <FormLabel className="form-label fs-6 fw-bolder text-dark mb-3">Nombre <span className="text-danger">*</span></FormLabel>
            <FormControl name="name" value={formik.values.name} required onChange={formik.handleChange} />
          </FormGroup>
        </div>
        <div className='row g-4 mt-1'>
          <FormGroup id='description' className='col-md-6' >
            <FormLabel className="form-label fs-6 fw-bolder text-dark mb-3">Descripcion <span className="text-danger">*</span></FormLabel>
            <FormControl as="textarea" required rows={4} name="description" value={formik.values.description == null ? '' : formik.values.description} onChange={formik.handleChange} />
          </FormGroup>
          <FormGroup id='color' className='col-md-6'>
            <FormLabel className="form-label fs-6 fw-bolder text-dark mb-3">Color <span className="text-danger">*</span></FormLabel>
            <div className="d-flex justify-content-center">
              <CirclePicker color={formik.values.color} onChangeComplete={(color: any) => {
                formik.setFieldValue('color', color.hex)
              }}/>
            </div>
          </FormGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" disabled={loadign} className="fw-bold btn-info">
          {loadign  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
          {loadign  ? <Spinner className="text-center text-light"/> : mode === 'create' ? 'Guardar Tipo de Proyecto' : 'Editar Tipo de Proyecto'}
        </Button>
      </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ProjectTypeCreateModal;
