import moment from "moment";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomDrawer } from "../../../components/Drawer/CustomDrawer";
import { KTSVG } from "../../../components/Icon/KTSVG";
import PageLayoutData from "../../../components/Page/PageLayoutData";
import PageLayoutDataActionButtons from "../../../components/Page/PageLayoutDataActionButtons";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import { handleDeleteAlert } from "../../../components/SweetAlert/HandleDeleteAlert";
import { useFiltersPR } from "../../../components/filters/FiltersProvider";
import AsyncImg from "../../../components/form/AsyncImg";
import ColorPicker from "../../../components/form/ColoPicker";
import StatusCheck from "../../../components/form/StatusCheck";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { TablesWidget9 } from "../../../components/widgets";
import useFetch from "../../../hooks/useFetch";
import { ProjectService } from "../../../services/project/projectService";
import { UsersApiResponse } from "../../../types/user-type";
import ProjectListFilters from "../filters/ProjectListFilters";

const breadcrumbs = [
  {
    title: 'ADMINISTRACIÓN',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'TIPOS DE PROYECTOS',
    path: 'admin/projects',
    isSeparator: true,
    isActive: true,
  },
]

const breadcrumbs2 = [
  {
      text: 'Módulos',
      link: '/'
  },
  {
      text: 'Proyectos',
      link: '/projects'
  }
]



export const ProjectList: FC = () => {

  const navigate = useNavigate();

  const {userCan} = useContext(PrivilegeContext)

  const actions = [
    {
      show: userCan('create','project'),
      title: "Crear Proyecto",
      icon : '/media/icons/duotune/general/gen041.svg',
      className: 'btn-success btn-sm text-light me-3 fw-bold',
      colorIcon : "text-light",
      callback: () => {
        userCan('create','project') && navigate('create');
      }
    },
    {
      title: "Filtros",
      icon : '/media/icons/duotune/general/gen031.svg',
      className: 'btn-info btn-sm me-3 fw-bold',
      callback: () => {
        setOpenFilterDrawer(true);
      }
    }
  ]

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchProjects = useCallback(async () => {
    const projectService = new ProjectService();
    const response = await projectService.getProject(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchProjects);

  const handleSearch = (search: string) => {
    updateFilters({ search_array : search });
  }

	const deleteProject = async (id: string) => {
		let response = (await new ProjectService().deleteProject(id)).getResponseData();
		if (response.success) {
			toast.success('Proyecto eliminado correctamente');
			refetch();
		}
	}

  const _onCloseModal = (message?: string) => {
    toast.success(message ? message : "Accion Realizada correctamente");
    refetch();
  }

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const toggleProjectStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (await new ProjectService().toggleProjectStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status); 
        toast.success(`Proyecto ${status ? 'activado' : 'desactivado'} correctamente`);
        refetch();
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  return (
    <>
      {
        data ? (
            <>
              <CustomDrawer title="Proyectos" anchor="right" open={openFilterDrawer} onClose={handleClose}>
                <ProjectListFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
              </CustomDrawer>
              <PageLayoutData pageTitle='Proyectos' breadcrumb={breadcrumbs2}/>
              <PageLayoutDataActionButtons>
                {
                  userCan('create','project') && (
                    <div className="d-flex">
                        <button className="btn btn-flush" title="Crear Proyecto" data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => navigate('/admin-projects/create')}>
                          <KTSVG path="/media/icons/duotune/general/gen041.svg" className='svg-icon-1' />
                        </button>
                    </div>
                  )
                }
                <div className="d-flex">
                    <button className="btn btn-flush" title="Filtrar" data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => setOpenFilterDrawer(true)}>
                      <KTSVG path="/media/icons/duotune/general/gen031.svg" className='svg-icon-1' />
                    </button>
                </div>
              </PageLayoutDataActionButtons>


              <TablesWidget9 className="card-primary m-3" title="Projects"
                subtitle="List of Projects"
                searchInput={{ placeholder: "Buscar...", onSearch: handleSearch }}
                data={data?.projects ? data.projects : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: (data as UsersApiResponse) ? data.lastPage : 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={
                  [
                    {
                      name: "",
                      keyValue: "name",
                      className: "text-center",
                      cellClassName: "p-0",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center" key={element.projectImg?.id}>
                            <Link to={`/projects/show/${element.id}`}>
                              <AsyncImg transparent src={element.projectImg?.id || ""} type={"avatar"}></AsyncImg>
                            </Link>
                          </div>
                        );
                      },
                    },
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Descripción",
                      keyValue: "description",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.description || "-"}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Tipo de Proyecto",
                      keyValue: "projectType",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span>{element.projectType?.name || ""}</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Color",
                      keyValue: "color",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center" key={element.color}>
                                <ColorPicker item={element} handleSave={() => { }} disabled={true} filters={filters} />
                          </div>
                        )
                      }
                    },
                    {
                      name: "Fecha de creación",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                          </div>
                        )
                      },
                    },   
                    {
                      name: "Estado", keyValue: 'enabled', className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={"text-center"} key={element.id + '-' + element.status}>
                          <StatusCheck
                            disabled={changingStatus.includes(element.id)}
                            itemId={element.id}
                            status={element.status}
                            change={toggleProjectStatus}
                          />
                        </div>
                        );
                      }
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]
                }
                actions={
                  [
                    {
                      title: "Ver",
                      buttonType: "icon",
                      iconColor: "text-info",
                      iconPath: "/media/icons/duotune/general/gen004.svg",
                      description: "Ver Proyecto",
                      callback: (element: any) => {
                        navigate(`/projects/show/${element.id}/resume`);
                      }
                    },
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-black',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-black ${!userCan('edit','project') ? 'd-none' : ''}`,
                      description: "Editar Proyecto",
                      callback: (item: any) => {
                        userCan('edit','project') && navigate(`edit/${item.id}`);
                      },
                    },
      
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: 'text-danger',
                      description: "Eliminar tipo de proyecto",
                      callback: (item: any) => {
                          handleDeleteAlert({
                            projectName: item.name,
                            title: "Eliminar Proyecto",
                            text: "¿Está seguro que desea eliminar este proyecto? Todos los datos relacionados a este proyecto se quedaran huerfanos.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteProject(item.id);
                            },
                            onDeny: () => {
                              toggleProjectStatus(item.id, !item.status, () => { });
                            }
                          });
                      },
                    },
                  ]
                }
              ></TablesWidget9>
            </>
        ) : (
          <>
            <SpinnerCustom />
          </>
        )
      }
    </>
  );
}