import React from "react";
import { useCallback, useState, FC, useEffect, Fragment } from "react";
import useFetch from "../../../hooks/useFetch";
import { PermissionService } from "../../../services/auth/permissionService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserService } from "../../../services/user/userService";
import { UserApiResponse } from "../../../types/user-type";
import { Permission, PermissionGroup, PermissionsApiResponse, RolePermission } from "../../../types/role-type";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import { KTSVG } from "../../../components/Icon/KTSVG";
import { handleConfirmationAlert } from "../../../components/SweetAlert/ConfirmationAlert";
import { Accordion, Button, CloseButton, FormCheck, Modal, Spinner } from "react-bootstrap";

interface UserEditPermissionProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	userPermissions: RolePermission[] | null;
	setUserPermissions: (permissions: RolePermission[]) => void;
	userId: string;
}

const UserEditPermission: FC<UserEditPermissionProps> = ({ isOpen, setIsOpen, userPermissions, userId, setUserPermissions }) => {

	const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState<number[]>([]);

	const [updating, setUpdating] = useState<boolean>(false);

	const fetchPermissionsGroups = useCallback(async () => {
		const permissionService = new PermissionService();
		const response = await permissionService.getPermissions();
		return response.getResponseData() as PermissionsApiResponse;
	}, []);

	const [permissionsData, permissionsLoading, error] = useFetch(fetchPermissionsGroups);

	const updatePermissions = async () => {
		try {
			setUpdating(true);
			let response = await (await (new UserService()).editUserPermissions(userId, selectedPermissions)).getResponseData() as UserApiResponse;
			if (response.success && response.data) {
				setUserPermissions(response.data.userPermissions);
				toast.success(response.message);
			} else {
				toast.error(response.message);
			}
		} catch (error: any) {
			setIsOpen(false);
			toast.error('Error al Actualizar Permisos');
		} finally {
			setUpdating(false);
			setIsOpen(false);
		}

	};

	const getContent = () => {
		if (permissionsLoading)
			return (
				<div className="text-center">
					{" "}
					<SpinnerCustom />
					{" "}
				</div>
			);

		if (error) return <div>Error</div>;

		return permissionsData?.map((group: PermissionGroup, index: number) => {
			return (
				<div className="col-6 mb-2" key={index}>
					<Accordion key={`kt_accordion_${group.id}`} defaultActiveKey={`${group.id}`} flush>
						<div className="accordion-custom">
						<Accordion.Item eventKey={`${group.id}`} title={`${group.label}`}>
						<Accordion.Header>
							<div className="accordion-button-custom">
								<KTSVG
									path="/media/icons/duotune/general/gen044.svg"
									className="svg-icon-2 me-2 icon-color"
								/>
								{group.label}
							</div>
						</Accordion.Header>
							<Accordion.Body>
									<Fragment>
										<div className="mb-check">
											<FormCheck
												label="Seleccionar todos"
												value="true"
												checked={selectAll.includes(group.id)}
												onChange={() => {
													const list = group.permissions.map((item: Permission) => item.id);
													if (selectAll.includes(group.id)) {
														setSelectAll(selectAll.filter((id: number) => id !== group.id));
														setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
													} else {
														setSelectAll([...selectAll, group.id]);
														setSelectedPermissions([...selectedPermissions.concat(list)]);
													}
												}}
											/>
										</div>
									</Fragment>
							{group.permissions.map((permission: Permission, index: number) => {
								return (
									<div key={index} title={`${permission.action}: ${permission.description}`} className="mb-check">
										<FormCheck
											label={
												<div className="d-flex align-items-center" title={`${permission.action}: ${permission.description}`}>
													<KTSVG
														path="/media/icons/duotune/general/gen044.svg"
														className="svg-icon-2 me-2 text-info"
													/>
													{permission.label}
												</div>
											}
											value={permission.id}
											checked={selectedPermissions.includes(permission.id)}
											onChange={() => {
												selectedPermissions.includes(permission.id) ?
												setSelectedPermissions(selectedPermissions.filter((id: number) => id !== permission.id)) :
												setSelectedPermissions([...selectedPermissions, permission.id])
											}}
										/>
									</div>
								);
							})}
							</Accordion.Body>
						</Accordion.Item>
						</div>
					</Accordion>
				</div>
			);
		});
	}

	const _restorePermissions = async () => {
		
		handleConfirmationAlert({
			title: 'Restaurar Permisos',
			text: '¿Está seguro que desea restaurar los permisos del usuario?',
			icon: 'warning',
			onConfirm: async () => {
				setUpdating(true);
				const response = await (await (new UserService()).restoreUserPermissions(userId)).getResponseData();
				
				if(response.success) {
					toast.success(response.message);
					setUpdating(false);
					setIsOpen(false);
				} else {
					toast.error(response.message);
					setUpdating(false);
				}
			}
		})
	}

	useEffect(() => {
		if (userPermissions) {
			setSelectedPermissions(userPermissions.map((permission: RolePermission) => permission.permission.id));
		}
	}, [userPermissions]);


	return (
		<Modal show={isOpen} size="lg" titleId='Nuevo Permisos'>
			<Modal.Header onHide={setIsOpen} className="pb-0 border-0 bg-success pb-2 pt-5 pb-4" >
				<div className="modal-title justify-content-start flex-row">
					<h2 className="text-light align-text-bottom">Editar Permisos</h2>
				</div>
				<Button className="ms-2 btn-info p-2 d-flex justiff-content-center" title="Restaura todos los permisos del usuario estableciendo los del ROL asignado por defecto" onClick={_restorePermissions}>
					<KTSVG path="/media/icons/duotune/arrows/arr029.svg" className="svg-icon-2 ms-2" />
				</Button>
				<CloseButton className="btn-close-light custom-close-btn" onClick={ () => {setIsOpen(false)}} />
			</Modal.Header>
			<Modal.Body className='px-4 pb-15'>
				<div className="row">
					{getContent()}
				</div>
			</Modal.Body>
			<Modal.Footer className='px-4 pb-4'>
				<Button disabled={updating} className="fw-bold" variant="info" onClick={updatePermissions}>
					{updating ? (<Spinner className="text-light"/>) : 'Actualizar Permisos'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default UserEditPermission;