import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

/**
 * @ES Endpoint de proyectos
 * @EN Project endpoint
 */

const PROJECT_ENDPOINT = '/projects';

export class ProjectService extends RestServiceConnection {

 /**
  * 
  * @ES Crea un nuevo proyecto
  * @EN Create a new project 
  * 
  * @param values 
  * @returns response
  */  

  createProject = async (values: any) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: PROJECT_ENDPOINT + '/create',
        data: values,
    }, true);
    return this;
  }

 /**
  * 
  * @ES Edita un tipo de proyecto
  * @EN Edit a project type
  * 
  * @param values 
  * @returns response
  */

  editProject = async (values: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_ENDPOINT + '/edit',
          data: values,
          headers: {
              "Content-Type": "application/json"
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Obtiene todos los proyectos
  * @EN Get all projects
  * 
  * @param filters 
  * @returns response
  */

  getProject = async (filters: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_ENDPOINT + '/list',
          data: {
              ...filters
          },
      }, true) as AxiosResponse;
      return this;
  }

 /**
  * 
  * @ES Obtiene un proyecto por id
  * @EN Get a project by id
  * 
  * @param id 
  * @returns response
  */ 

  getProjectById = async (id: string) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_ENDPOINT + '/get',
          data: {
            projectId: id
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Borra un proyecto
  * @EN Delete a project
  * 
  * @param projectId 
  * @returns response
  */ 

  deleteProject = async (projectId: string) => {

      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_ENDPOINT + '/delete',
          data: {
            projectId: projectId
          }
      }, true);

      return this
  }

 /**
  * 
  * @ES Cambia el estado de un proyecto
  * @EN Change the status of a project
  * 
  * @param id 
  * @param status 
  * @returns 
  */ 

  toggleProjectStatus = async (id: string, status: boolean) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: PROJECT_ENDPOINT + '/toggle',
        data: {
            projectId: id,
            active: status
        }
    }, true);
    return this;
  }

 /**
  * 
  * @ES Actualiza la imagen de un proyecto
  * @EN Update a project image
  * 
  * @param file 
  * @param projectId 
  * @returns API response
  */ 

   updateImage = async (file: File, projectId: string) => {
    let formData = new FormData();
    formData.append('profileImg', file);
    formData.append('projectId', projectId);

    this.response = await this.makeRequest({
        method: 'POST',
        url: PROJECT_ENDPOINT + '/add-image',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: formData
    }, true);

    return this
    }

  /**
   * 
   * @ES Obtiene la miembros de un proyecto
   * @EN Get the members of a project
   * 
   * @param projectId
   * @returns API response
   */
  
    getProjectMembers = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/get-members',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

   /**
    *
    * @ES Agrega miembros a un proyecto
    * @EN Add members to a project
    * 
    * @param projectId
    * @param users
    * @returns API response 
    */ 

    addMembers = async (projectId: string, users : any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/add-member',
            data: {
                projectId: projectId,
                users: users
            }
        }, true);
        return this;
    }

    /**
     *  
     *  
     * @ES Elimina miembros de un proyecto
     * @EN Delete members from a project
     * 
     * @param projectId
     * @param users
     * @returns API response
     */
    
    deleteMember = async (projectId: string, users: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/delete-member',
            data: {
                projectId: projectId,
                users: users
            }
        }, true);
        return this;
    }

    /**
     * 
     * @ES Obtiene las configuraciones de backups de un proyecto
     * @EN Get all the project backup settings
     * 
     * @param projectId
     * @returns API response
     */

    getProjectBackupSettings = async (projectId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/list-backups',
            data: {
                projectId: projectId
            }
        }, true);
        return this;
    }
}