import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../redux/authSlice";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { KTSVG } from "../../../components/Icon/KTSVG";
import BackupForm from "../form/BackupFom";
import { BackupService } from "../../../services/backup/backupService";

const EditBackup: FC = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();

  const fetchBackup = useCallback(async () => {
    const backupService = new BackupService();
    const response = await backupService.getBackupById(id as string);
    return response.getResponseData() as User;
  }, [id]);

  const [data] = useFetch(fetchBackup);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  const handleUpdate = async (values: any) => {
    setLoading(true);

    try {
      setLoading(true)
      let response = await (await (new BackupService()).editBackup({ ...values, backupId: values.id })).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error('Error al editar la configuracion de backup');
    } finally {
      setLoading(false);
    }

  };

  const getContent = () => {
    if (loading) return <div> Loading </div>;

    if (error) return <div> Error </div>;

    if (data !== null) {

      const backupData = {
        ...data,
        backupProjects : data.backupProjects[0]?.project?.id,
      };
      
      return (
        <div className="container-fluid">
          <div className={`card mt-3`}>
            <div className="card-header bg-success mb-3">
              <div className="card-title flex-row d-flex justify-content-between w-100">
                <div className="d-flex flex-row">
                <span className='card-label fw-bolder text-light fw-bold fs-1'>
                  <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-1 p-0 text-light" />
                </span>
                <span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
                  EDITAR CONFIGURACION DE BACKUP
                </span>
                </div>
              </div>
            </div>
            <div className="card-body pt-3">
              <BackupForm isLoading={false} submit={handleUpdate} backupData={backupData} />
            </div>
			    </div>
        </div>
      );
    }
  };

  return (
    <Fragment>
        {getContent()}
    </Fragment>
  );
};

export default EditBackup;
