import React from "react";
import * as Yup from 'yup';
import clsx from 'clsx';
import { Button, Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { LoginService } from "../../services/auth/loginService";
import { LoginContainer } from "./LoginContainer";

interface LoginFormProps {
}


const loginSchema = Yup.object().shape({
    resetEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
})

export const ResetForm: React.FC<LoginFormProps> = () => {

    const [isLoading, setIsLoading] = React.useState(false);

    // Controlar el envío del formulario
    const handleSubmit = async (email: string) => {
        sendEmail(email);
    };

    // Enviar el correo electrónico al servidor
    const sendEmail = async (email: string) => {
        try {
            setIsLoading(true);
            await (new LoginService()).sendEmailToResetPassword(email);
            toast.success('Correo electrónico enviado, por favor, revisa tu bandeja de entrada');
        } catch (error: any) {
            toast.error('Error al enviar el correo electrónico');
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik({
		enableReinitialize: true,
		validationSchema: loginSchema,
        initialValues: {
			resetEmail: '',
		},
		onSubmit: (values) => {
            handleSubmit(values.resetEmail);
		},
	});

    return(
        <LoginContainer>
            <form onSubmit={formik.handleSubmit} className='row g-4'>
                <div className='col-12'>
                    <div className='mb-7'>
                        <h3 className='text-gray-600 mb-3'>Ayuda de contraseña</h3>
                        <div className='text-gray-600 fs-4'>Introduzca la dirección de correo electrónico asociada a la cuenta 
                        y le enviaremos un enlace para ayudarle a restablecer su contraseña.</div>
                    </div>
                </div>
                <div className='col-12'>
                    <Form.Group className="fv-row mb-8" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Correo Electrónico"
                        className={clsx(
                            'form-control bg-transparent',
                            {'is-invalid': formik.touched.resetEmail && formik.errors.resetEmail},
                            {
                            'is-valid': formik.touched.resetEmail && !formik.errors.resetEmail,
                            }
                        )}
                        name='resetEmail'
                        autoComplete='off'
                        onChange={formik.handleChange}
                        />
                        {formik.touched.resetEmail && formik.errors.resetEmail && (
                        <div className='fv-plugins-message-container mt-3'>
                            <span role='alert' className="text-danger fs-6">{formik.errors.resetEmail}</span>
                        </div>
                        )}
                    </Form.Group>
                </div>
                <div className='col-12'>
                <Button variant='primary' className='w-100 fs-6 fw-bold' type='submit' disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <Spinner/>
                        </>
                    ) : (
                        'Solicitar nueva contraseña'
                    )}
                </Button>
                </div>
            </form>
        </LoginContainer>
    ) 
}