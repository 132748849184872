import { FC, ReactNode } from "react";

interface LoginContainerProps {
    children: ReactNode;
    reset ?: boolean;
}

export const LoginContainer: FC<LoginContainerProps> = ({children, reset}) => {

    return (
        <>
                <div className="d-flex align-align-items-center flex-column h-100" id="kt_app_root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 h-100 bg-login">
                            <div className="d-flex flex-center flex-column flex-lg-row-fluid h-100">
                                <div className="w-lg-500px p-10 rounded" style={{border: '1px solid', borderColor: '#E0E3E9', backgroundColor: 'white'}}>
                                    <div className="text-center mb-11"> 
                                        <img className="d-block d-lg-none h-100px m-auto mb-5" src="/media/logos/logo128kUP.png" alt="" />
                                        <h1 className="text-dark fw-bolder mb-3">
                                            {reset ? 'Restaurar Contraseña' : 'Panel de Administración'}
                                            </h1>
                                    </div>
                                    {children}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bg-secondary d-none d-lg-block">
                            <div className="d-flex flex-column flex-center py-10 w-100">
                                <a className="mb-0">
                                    <img alt="Logo" src="/media/logos/logo128kUP.png" className="h-60px h-lg-75px" />
                                </a>
                                <div>
                                <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-600px" src="./media/login/loginImg.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		</>
    )
}