import React, { Fragment, useEffect } from 'react';
import { useLayout } from '../../pages/layout/LayoutProvider';

export type BreadcrumbObject = {
    text: string;
    link: string | undefined;
};

type PageLayoutDataProps = {
  pageTitle: string;
  breadcrumb: BreadcrumbObject[];
  sidebar?: boolean;
  headerColor?: string;
};

const PageLayoutData: React.FC<PageLayoutDataProps> = ({ pageTitle, breadcrumb, sidebar, headerColor }) => {
    
    const layout = useLayout();

    useEffect(() => {
        layout.setPageTitle(pageTitle);
        layout.setBreadcrumb(breadcrumb);

        if(sidebar !== undefined) {
            layout.setSidebar(sidebar);
        }
        
        if(headerColor !== undefined) {
            //ayout.setHeaderColor(headerColor);
        }

        // Reset layout header on unmount component
        return () => {
            layout.resetLayoutHeader();

            if(headerColor !== undefined) {
                //layout.resetHeaderColor();
            }
        }
    }, []);

    return (
        <Fragment></Fragment>
    );
};

export default PageLayoutData;