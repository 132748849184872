
export const rolesMenu = {
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/users/roles',
		icon: 'general/gen051.svg',
		permissions_required: {
			entity: 'roles',
			action: 'list'
		},
		subMenu: null,
	}
}

export const usersMenu = {
	users: {
		id: 'users',
		text: 'Usuarios',
		path: '/users',
		icon: 'communication/com014.svg',
		permissions_required: {
			entity: 'user',
			action: 'list'
		},
		subMenu: {
			userList: {
				id: 'user-list',
				text: 'Lista',
				path: '/users',
				icon: 'abstract/abs015.svg',
			},
			...rolesMenu
		},
	},
}

export const projectsMenu = {
	projects: {
		id: 'admin-projects',
		text: 'Proyectos',
		path: '/admin-projects',
		icon: 'files/fil012.svg',
		permissions_required: {
			entity: 'project',
			action: 'admin_projects'
		},
		subMenu: {
			projectList: {
				id: 'admin-project-list',
				text: 'Lista',
				path: '/admin-projects',
				icon: 'abstract/abs015.svg',
			},
			projectCreate: {
				id: 'admin-project-create',
				text: 'Crear',
				path: '/admin-projects/create',
				icon: 'abstract/abs011.svg',
			},
			projectTypes: {
				id: 'admin-projectTypes',
				text: 'Tipos de proyecto',
				path: '/admin-projects/project-types',
				icon: 'abstract/abs026.svg',
				permissions_required: {
					entity: 'projectTypes',
					action: 'admin_project_types'
				},
			},
		},
	},
}

export const backupMenu = {
	backups: {
		id: 'backup',
		text: 'Backup',
		path: '/backup',
		icon: 'technology/teh012.svg',
		permissions_required: {
			entity: 'backup',
			action: 'list'
		},
		subMenu: {
			backupList: {
				id: 'backup-list',
				text: 'Lista',
				path: '/backup',
				icon: 'abstract/abs015.svg',
			},
			backupCreate: {
				id: 'backup-create',
				text: 'Crear',
				path: '/backup/create',
				icon: 'abstract/abs011.svg',
			}
		},
	},
}


export const adminMenu = {
	title: {
		id: 'title-admin',
		text: 'Administración',
		icon: 'Extension',
        isTitle: true,
		permissions_required: {
			entity: 'user',
			action: 'list'
		},
	},
	...usersMenu,
	...projectsMenu,
	...backupMenu
}

export const superAdminMenu = {
	title: {
		id: 'title-super-admin',
		text: 'SuperAdmin',
		icon: 'Extension',
        isTitle: true,
		permissions_required: {
			entity: 'companies',
			action: 'list'
		},
	},
	organizations: {
		id: 'organizations',
		text: 'Organizaciones',
		path: '/organizations',
		icon: 'ecommerce/ecm008.svg',
		permissions_required: {
			entity: 'companies',
			action: 'list'
		},
		subMenu: null,
	},
};



export const moduleProjectsMenu = {
	projects: {
		id: 'module-projects',
		text: 'Proyectos',
		path: '/projects',
		icon: 'abstract/abs027.svg',
		permissions_required: {
			entity: 'project',
			action: 'list'
		},
		subMenu: null,
	}
}



export const modulesMenu = {
	title: {
		id: 'title-modules',
		text: 'Módulos',
		icon: 'Extension',
        isTitle: true,
	},
	...moduleProjectsMenu
}