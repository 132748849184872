import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { TablesWidget9 } from "../../../components/widgets";
import moment from "moment";
import { useFiltersPR } from "../../../components/filters/FiltersProvider";
import { UsersApiResponse } from "../../../types/user-type";
import useFetch from "../../../hooks/useFetch";
import { UserService } from "../../../services/user/userService";
import { Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import PageContentTitle from "../../../components/Page/PageData";
import { CustomDrawer } from "../../../components/Drawer/CustomDrawer";
import UserListFilters from "../filters/UserListFilters";
import AsyncImg from "../../../components/form/AsyncImg";
import { handleConfirmationAlert } from "../../../components/SweetAlert/ConfirmationAlert";
import { toast } from "react-toastify";
import StatusCheck from "../../../components/form/StatusCheck";

const breadcrumbs = [
  {
    title: 'ADMINISTRACIÓN',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'COMPANY LIST',
    path: 'admin/company/list',
    isSeparator: true,
    isActive: true,
  },
]

export const UserList: FC = () => {

  const navigate = useNavigate();

  const {userCan} = useContext(PrivilegeContext)

  const actions = [
    {
      show: userCan('create','user'),
      title: "Crear Usuario",
      icon : '/media/icons/duotune/general/gen041.svg',
      className: 'btn-success text-light me-3 fw-bold',
      colorIcon : "text-light",
      callback: () => {
        userCan('create','user') && navigate('create');
      }
    },
    {
      title: "Filtros",
      icon : '/media/icons/duotune/general/gen031.svg',
      className: 'btn-info me-3 fw-bold',
      callback: () => {
        setOpenFilterDrawer(true);
      }
    }
  ]

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchUsers = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUsers(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchUsers);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const handleSearch = (search: string) => {
    updateFilters({ search_array : search });
  }

  const deleteUser = async (id: string) => {
    let response = (await new UserService().deleteUser(id)).getResponseData();
    if (response.success) {
      toast.success("Usuario eliminado correctamente");
      refetch();
    }
  };

  
  const toggleUserStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (await new UserService().toggleUserStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status); 
        toast.success(`Usuario ${status ? 'activado' : 'desactivado'} correctamente`);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  return (
    <>
      {
        data ? (
          <>
              <CustomDrawer title="Usuarios" anchor="right" open={openFilterDrawer} onClose={handleClose}>
                <UserListFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
              </CustomDrawer>
              <PageContentTitle title='Usuarios' breadcrumbs={breadcrumbs} actions={actions}/>
              <TablesWidget9 className="card-primary m-3" title="Users"
                subtitle="List of Cognito users accesing this application"
                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                data={data?.users ? data.users : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: (data as UsersApiResponse) ? data.lastPage : 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={[
                  {
                    name: "",
                    keyValue: "name",
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center" key={element.profileImg?.id}>
                          <Link to={`/users/${element.id}/profile`}>
                            <AsyncImg transparent src={element.profileImg?.id || ""} type={"avatar"}></AsyncImg>
                          </Link>
                        </div>
                      );
                    },
                  },
                  {
                    name: "Name", sortable: true, keyValue: 'name', className: "min-w-150px",
                    sortColumn: updateFilterOrder,
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark d-block fs-6'>
                            {element.name}
                          </div>
                        </>
                      );
                    }
                  },
                  {
                    name: "Email",
                    keyValue: "email",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "min-w-150px",
                    render: (element: any) => {
                      return (
                        <div>
                          {element.email}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Fecha de creación",
                    keyValue: "createdAt",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    render: (element: any) => {
                      return (
                        <div>
                          <span className={"text-muted"}>
                            {moment(element.createdAt.date).format(
                              "DD-MM-YYYY HH:mm"
                              )}
                          </span>
                        </div>
                      );
                    },
                  },
                  {
                    name: "Último acceso",
                    keyValue: "lastLogin",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    render: (element: any) => {
                      return (
                        <div>
                          <span className={"text-muted"}>
                            {element.lastLogin?.date &&
                              moment(element.lastLogin?.date).format(
                                "DD-MM-YYYY HH:mm"
                                ) || "-"}
                          </span>
                        </div>
                      );
                    },
                  },
                  {
                    name: "Rol",
                    keyValue: "role",
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center">
                          {element.userRoles.map((userRole: any) => (
                            <Badge
                            key={userRole.id}
                            className="px-3 py-2 bg-success text-light"
                            >
                              {userRole.role.name}
                            </Badge>
                          ))}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Estado", keyValue: 'enabled', className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={"text-center"} key={element.id}>
                        <StatusCheck
                          disabled={changingStatus.includes(element.id)}
                          itemId={element.id}
                          status={element.active}
                          change={toggleUserStatus}
                        />
                      </div>
                      );
                    }
                  },
                  { name: "Actions", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={[
                  {
                    title: "Edit",
                    buttonType: "icon",
                    iconColor: "text-dark",
                    iconPath: "/media/icons/duotune/general/gen055.svg",
                    description: "Edit user",
                    callback: (element: any) => {
                      navigate(`/users/edit/${element.id}`);
                    }
                  },
                  {
                    title: "Delete",
                    buttonType: "icon",
                    iconColor: "text-danger",
                    iconPath: "/media/icons/duotune/general/gen027.svg",
                    description: "Remove user",
                    callback: (item: any) => {
                      if (userCan("delete", "user")) {
                        handleConfirmationAlert({
                          title: "Eliminar Usuario",
                          text: "¿Está seguro que desea eliminar este usuario? Borrará todos los datos asociados a este usuario",
                          icon: "warning",
                          onConfirm: () => {
                            deleteUser(item.id);
                          },
                        });
                      }
                    },
                  }
                ]}
              ></TablesWidget9>
          </>
        ) : (
          <>
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <SpinnerCustom/>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
}