import React from "react";
import { FC, Fragment, useState } from "react";
import { Form, Spinner } from "react-bootstrap";

interface StatusDropdownProps {
    itemId: string;
    status: boolean;
    change: (id: string, status: boolean, toggleStatus: Function) => void;
    disabled?: boolean;
    additionalInfo?: string;
}

const StatusCheck: FC<StatusDropdownProps> = ({itemId, status, change, disabled=false, additionalInfo}) => {

    const [isActive, setIsActive] = useState<boolean>(status);

    const toggleState = (newStatus: boolean) => {
        change(itemId, newStatus, setIsActive);
    }
   
    return (
        <Form.Check
            title={isActive ? 'Desactivar' : 'Activar'}
            type="switch"
            className={'m-auto'}
            id="custom-switch"
            checked={isActive}
            onChange={(e: any) => toggleState(e.target.checked)}
            disabled={disabled}
        />
    )
}

export default StatusCheck;

