
import { NewCompany } from '../../types/company-type';
import { RestServiceConnection } from '../restServiceConnection';

/**
 * @ES Endpoint de compañias
 * @EN Company endpoint
 */

const COMPANIES_ENDPOINT = '/companies';

export class CompanyService extends RestServiceConnection {

   /**
    * 
    * @ES Crea una nueva compañia
    * @EN Create a new company
    * 
    * @param company 
    * @param hasImg 
    * @returns API response
    */ 
    
    createCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => {if(value == ''){companyObj[key] = null;}else (companyObj[key] = value)});
        companyObj['logo'] = null;
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/create',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            },
        }, true);
        return this;
    }

   /**
    * 
    * @ES Edita una compañia
    * @EN Edit a company
    * 
    * @param company 
    * @param hasImg 
    * @returns API response
    */ 

    editCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => {if(value == ''){companyObj[key] = null;}else (companyObj[key] = value)});
        companyObj['logo'] = null;
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/edit',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Edita una compañia
    * @EN Edit a company
    * 
    * @param company 
    * @returns API response
    */ 

    updateCompany = async (company: NewCompany) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/edit',
            data: company,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Obtiene todas las compañias
    * @EN Get all companies
    * 
    * @param filters 
    * @returns API response
    */ 

    getCompanies = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/list',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Obtiene una compañia por id
    * @EN Get a company by id
    * 
    * @param id 
    * @returns API response
    */ 

    getCompanyById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/get',
            data: {
                companyId: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Cambia el estado de una compañia
    * @EN Change the status of a company
    * 
    * @param id 
    * @param status 
    * @returns API response
    */ 

    toggleCompanyStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/toggle',
            data: {
                companyId: id,
                active: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Borra una compañia
    * @EN Delete a company
    * 
    * @param companyId 
    * @returns API response
    */ 

    deleteCompany = async (companyId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/delete',
            data: {
                companyId: companyId
            }
        }, true);

        return this
    }

   /**
    * 
    * @ES Actualiza el logo de una compañia
    * @EN Update a company logo
    * 
    * @param companyId 
    * @param logo 
    * @returns API response
    */ 

    updateCompanyLogo = async (companyId: string, logo: any) => {  
        let formData = new FormData();
        formData.append('logo', logo);
        formData.append('companyId', companyId);

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/add-image',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData
        }, true);

        return this
    }
}