import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { useFiltersPR } from "../filters/FiltersProvider";
import useFetch from "../../hooks/useFetch";
import { TablesWidget9 } from "../widgets";
import SpinnerCustom from "../Spinner/SpinnerCustom";
import { BackupService } from "../../services/backup/backupService";
import { Badge, Button } from "react-bootstrap";
import { KTSVG } from "../Icon/KTSVG";
import moment from "moment";

interface MembersCardProps {
  id: string;
}

export const ExecutionCard : FC<MembersCardProps> = ({id}) => {

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  const [users, setUsers] = useState<any>([]);

  const fetchExecutions = useCallback(async () => {
    const projectService = new BackupService();
    const response = await projectService.getExecutionsFromBackup(id);
    return response.getResponseData() as any;
  }, [id]);

  const [data, loading, error, refetch] = useFetch(fetchExecutions);

  const handleCheckboxChange = (
    user: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    if (checked) {
      setUsers((prevSelectedUsers : any) => [...prevSelectedUsers, user]);
    } else {
      setUsers((prevSelectedUsers : any) =>
        prevSelectedUsers.filter((selectedUser : any) => selectedUser !== user)
      );
    }
  };

  return (
    <>
      {
        data ? (
            <>
              <TablesWidget9 className="card-primary m-3" title="Projects"
                subtitle="List of Executions"
                selectableItems={true}
                onSelectAllItems={(checked: boolean) => {
                  if (checked) {
                    const newSelecteds = data.map((n : any) => n.id);
                    setUsers(newSelecteds);
                    return;
                  }
                  setUsers([]);
                }}
                onChangeCheckedItems={handleCheckboxChange}
                data={data}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={
                  [
                    {
                      name: "Nombre del Archivo",
                      keyValue: "nameFile",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.nameFile || '-'}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Fecha de Creacion",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{
                              element.createdAt?.date ?
                                moment(element.createdAt?.date).format('DD-MM-YYYY HH:mm')
                                : '-'
                            }</span>
                          </div>
                        )
                       },
                    },
                    {
                      name: "Mensaje",
                      keyValue: "message",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.message || '-'}
                          </div>
                        )
                       },
                    },
                    {
                      name: "Estado",
                      keyValue: "status",
                      className: "text-center",
                      render: (element: any) => {
                        return (
                              <div className="d-flex justify-content-center">
                                <Badge
                                key={element.id}
                                className={`px-3 py-2 fs-6 d-flex justify-content-center  ${element.status == 'Created' ? 'badge-valid' : element.status == 'Failed' ? 'badge-invalid' : 'badge-pending'}`}
                                >
                                  <KTSVG path={`/media/icons/duotune/${element.status == 'Created' ? 'arrows/arr084.svg' : element.status == 'Failed' ? 'arrows/arr011.svg' : 'general/gen013.svg'}`} 
                                  className='svg-icon-5 text-light me-2' />
                                  {element.status}
                                </Badge>
                                {element.status == 'Failed' ? (
                                  <Badge className="px-3 py-2 bg-danger fs-4 ms-3" key={element.id}>
                                      <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-5 text-light' />
                                  </Badge>
                                ): <></> }
                        </div>
                        )
                       },
                    },
                  ]
                }
              ></TablesWidget9>
            </>
        ) : (
          <>
            <SpinnerCustom />
          </>
        )
      }
    </>
  );
}