import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

/**
 * @ES Endpoint de tipos de proyectos
 * @EN Project types endpoint
 */

const PROJECT_TYPE_ENDPOINT = '/projectTypes';

export class ProjectTypeService extends RestServiceConnection {

 /**
  * 
  * @ES Crea un nuevo tipo de proyecto
  * @EN Create a new project type
  * 
  * @param values 
  * @returns response
  */  

  createProjectType = async (values: any) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: PROJECT_TYPE_ENDPOINT + '/create',
        data: values,
    }, true);
    return this;
  }

 /**
  * 
  * @ES Edita un tipo de proyecto
  * @EN Edit a project type
  * 
  * @param values 
  * @returns response
  */

  editProjectType = async (values: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_TYPE_ENDPOINT + '/edit',
          data: values,
          headers: {
              "Content-Type": "application/json"
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Obtiene todos los tipos de proyectos
  * @EN Get all project types
  * 
  * @param filters 
  * @returns response
  */

  getProjectTypes = async (filters?: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_TYPE_ENDPOINT + '/list',
          data: filters,
      }, true) as AxiosResponse;
      return this;
  }

 /**
  * 
  * @ES Obtiene un tipo de proyecto por id
  * @EN Get a project type by id
  * 
  * @param id 
  * @returns response
  */ 

  getProjectTypeById = async (id: string) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_TYPE_ENDPOINT + '/get',
          data: {
            projectTypeId: id
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Cambia el estado de un tipo de proyecto
  * @EN Change the status of a project type
  * 
  * @param projectTypeId 
  * @returns response
  */ 

  deleteProjectType = async (projectTypeId: string) => {

      this.response = await this.makeRequest({
          method: 'POST',
          url: PROJECT_TYPE_ENDPOINT + '/delete',
          data: {
            projectTypeId: projectTypeId
          }
      }, true);

      return this
  }
}