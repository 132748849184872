import * as React from 'react';
import { useLayout } from '../../pages/layout/LayoutProvider';

export interface IPageBreadCrumbProps {
}

export function PageBreadCrumb(props: IPageBreadCrumbProps) {

    const layoutData = useLayout();

    const renderBreadcrumb = () => {
        return layoutData.breadcrumb?.map((item, index) => {

            if (layoutData.breadcrumb === undefined) {
                return;
            }

            let defaultClass = "text-muted";
            let separator: boolean = true;

            if (index === layoutData.breadcrumb?.length - 1) {
                defaultClass = "text-primary fw-bold";
                separator = false;
            }

            return (
                <React.Fragment key={index}>
                    <li className="breadcrumb-item text-muted">
                        <a href={item.link} className="text-muted text-hover-primary">
                            {item.text}
                        </a>
                    </li>
                    {
                        separator && (
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-300 w-5px h-2px"></span>
                            </li>
                        )
                    }
                </React.Fragment>

            )
        }
        )
    }

    return (
        <div className="page-title d-flex justify-content-center flex-column me-5 pt-2">

            <h1 className="d-flex flex-column text-gray-900 fw-bold fs-3 mb-0">
                {layoutData.pageTitle?.toUpperCase()}
            </h1>

            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 pt-1">
                {renderBreadcrumb()}
            </ul>

        </div>
    );
}
