import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { RoleService } from "../../../services/auth/roleService";
import { PermissionService } from "../../../services/auth/permissionService";
import { toast } from "react-toastify";
import { Role } from "../../../types/role-type";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import RoleForm from "../form/RoleForm";
import { KTSVG } from "../../../components/Icon/KTSVG";

const RoleEdit: FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const fetchRole = useCallback(async () => {
    const roleService = new RoleService();
    const response = await roleService.getRoleById(id as string);
    return response.getResponseData() as Role;
  }, [id]);

  const [dataRole, loadingRole, errorRole] = useFetch(fetchRole);

  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);

  const fetchPermissions = useCallback(async () => {
    const permissionService = new PermissionService();
    const response = await permissionService.getPermissions();
    return response.getResponseData();
  }, []);

  const [permissionsData, permissionsLoading, error] = useFetch(fetchPermissions);

  const handleUpdate = async (values: any) => {
    const formData = new FormData();

    for (let value in values) {
      if (values[value]) {
        formData.append(value, values[value]);
      }
    }

    try {

      const response = await (await (new RoleService()).editRole(formData as any)).getResponseData();
      if (response.success) {
        navigate(-1);
        setTimeout(() => {
          toast.success('Rol editado correctamente');
        }, 500);
      } else {
        toast.error(response.message || "Error al editar el rol");
      }
    } catch (error: any) {
      toast.error(error.message || "Error al editar el rol");
    }
  };

  const getContent = () => {
    if (loadingRole) return <SpinnerCustom />;

    if (errorRole) return <div>Error al cargar el rol</div>;

    if (dataRole !== null) {
      const roleData = {
        name: dataRole.name !== null ? dataRole.name : "",
        description: dataRole.description !== null ? dataRole.description : "",
        company: dataRole.company !== null ? dataRole.company : "",
        permissions: dataRole.permissions !== null ? dataRole.permissions : [],
      };

      return (
        <RoleForm isLoading={false} submit={handleUpdate} roleData={roleData} />
      );
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className={`card mt-3`}>
          <div className="card-header bg-success mb-3">
            <div className="card-title flex-row">
              <span className='card-label fw-bolder text-light fw-bold fs-1 d-flex align-items-end'>
                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-1 p-0 text-light" />
              </span>
              <span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
                EDITAR ROL
              </span>
            </div>
          </div>
          {getContent()}
        </div>
      </div>
    </Fragment>
  );
};

export default RoleEdit;