import { adminMenu, backupMenu, moduleProjectsMenu, projectsMenu, superAdminMenu, usersMenu } from "../menu";
import BackupsWrapper from "../pages/backups";
import BackupsListWrapper from "../pages/backups/BackupsListIndex";
import CreateBackup from "../pages/backups/create";
import EditBackup from "../pages/backups/edit/BackupEdit";
import CompaniesWrapper from "../pages/companies";
import CompaniesListWrapper from "../pages/companies/CompaniesListIndex";
import CompanyCreate from "../pages/companies/create";
import CompanyEdit from "../pages/companies/edit/OrganizationEdit";
import ProfileUserPage from "../pages/extra/ProfileUserPage";
import ProjectTypesListWrapper from "../pages/project-types/ProjectTypesListIndex";
import ProjectsWrapper from "../pages/projects";
import ProjectsListWrapper from "../pages/projects/ProjectsListIndex";
import CreateProject from "../pages/projects/create";
import ProjectEdit from "../pages/projects/edit/ProjectEdit";
import ProjectView from "../pages/projects/view/ProjectView";
import ProjectViewBackups from "../pages/projects/view/backups/ProjectViewBackups";
import ProjectViewMembers from "../pages/projects/view/members/ProjectViewMembers";
import ProjectViewResume from "../pages/projects/view/resume/ProjectViewResume";
import RoleWrapper from "../pages/roles";
import RoleListWrapper from "../pages/roles/RoleListIndex";
import RoleEdit from "../pages/roles/edit/RoleEdit";
import UserListWrapper from "../pages/user/UserListIndex";
import CreateUser from "../pages/user/create";
import UserEdit from "../pages/user/edit/UserEdit";
import UsersWrapper from "../pages/user/index";

const protectedRoutes = [
	{
		path: usersMenu.users.path,
		element: <UsersWrapper />,	
		sub: [
			{
				element: <UserListWrapper />,
				access: {
					group: 'users',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'users',
					action: 'create'
				},
				element: <CreateUser/>,
			},
			{
				path: 'edit/:id',
				access: {
					group: 'users',
					action: 'edit'
				},
				element: <UserEdit/>,
			},
			{
				path: usersMenu.users.subMenu.roles.path,
				element: <RoleWrapper />,	
				sub: [
					{
						element: <RoleListWrapper />,
						access: {
							group: 'roles',
							action: 'list'
						},
						index: true,
					},
					{
						path: 'edit/:id',
						access: {
							group: 'roles',
							action: 'edit'
						},
						element: <RoleEdit/>,
					}
				]
			},	
		]
	},
	{
		path: moduleProjectsMenu.projects.path,
		element: <ProjectsWrapper />,
		sub: [
			{
				element: <ProjectsListWrapper />,
				access: {
					group: 'project',
					action: 'list'
				},
				index: true,
			},	
			{
				path: 'show/:id',
				access: {
					group: 'project',
					action: 'show'
				},
				element: <ProjectView/>,
				sub: [
					{
						path: 'resume',
						access: {
							group: 'project',
							action: 'show'
						},
						index: true,
						element: <ProjectViewResume/>,
					},
					{
						path: 'backups',
						access: {
							group: 'project',
							action: 'show'
						},
						element: <ProjectViewBackups/>,
					},
					{
						path: 'members',
						access: {
							group: 'project',
							action: 'show'
						},
						element: <ProjectViewMembers/>,
					},

					
				]
			},
			
		]	
	},
	{
		path: projectsMenu.projects.path,
		element: <ProjectsWrapper />,	
		sub: [
			{
				element: <ProjectsListWrapper />,
				access: {
					group: 'projects',
					action: 'admin_projects'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'projects',
					action: 'create'
				},
				element: <CreateProject />,
			},
			{
				path: 'edit/:id',
				access: {
					group: 'projects',
					action: 'edit'
				},
				element: <ProjectEdit/>,
			},	
			{
				path: 'show/:id',
				access: {
					group: 'projects',
					action: 'show'
				},
				element: <ProjectView/>,
			},
			{
				path: projectsMenu.projects.subMenu.projectTypes.path,
				element: <ProjectTypesListWrapper />,
				access: {
					group: 'projects',
					action: 'admin_project_types'
				},
			}
		]
	},
	{
		path: backupMenu.backups.path,
		element: <BackupsWrapper />,
		sub: [
			{
				element: <BackupsListWrapper />,
				access: {
					group: 'backup',
					action: 'admin_backups'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'backup',
					action: 'admin_backups'
				},
				element: <CreateBackup />,
			},
			{
				path: 'edit/:id',
				access: {
					group: 'backup',
					action: 'admin_backups'
				},
				element: <EditBackup />,
			}
		]
	},
	
	{
		path: superAdminMenu.organizations.path,
		element: <CompaniesWrapper />,	
		sub: [
			{
				element: <CompaniesListWrapper />,
				access: {
					group: 'companies',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'companies',
					action: 'create'
				},
				element: <CompanyCreate/>,
			},
			{
				path: 'edit/:id',
				access: {
					group: 'companies',
					action: 'edit'
				},
				element: <CompanyEdit/>,
			}	
		]
	},
	{
		path: '/profile',
		element: <ProfileUserPage />
	}
];
const contents = [...protectedRoutes];

export default contents;
