import { FC, useState } from "react";
import _ from "lodash";
import CustomSelect from "../../../components/form/CustomSelect";
import { Button, Form } from "react-bootstrap";

type Props = {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number, hardReset: boolean) => void
  filters: any
}

const statuses = [
  { value: '', label: 'Todos'},
  { value: 1, label: 'Activo' },
  { value: 0, label: 'Disactivado' },
]

const CompanyListFilters: FC<Props> = ({ updateFilters, filters , resetFilters}) => {

  const [resetKey, setResetKey] = useState("filters");

  return (

    <div className="column" key={resetKey}>
      <div className="row">
        <Form.Label className="fs-4 fw-bolder tex-dark">Estado</Form.Label>
        <CustomSelect 
          options={statuses} 
          defaultValue={statuses.find(status => filters.filter_filters?.active?.toString() == status.value) || ''}
          onChangeSingle={(selected: any) => updateFilters({active: selected.value})}
        />
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <Button
        className='mt-5 bg-info fw-bold'
        onClick={() => {
          resetFilters(50,true)
          setResetKey(resetKey == "filters" ? "resetFilters" : "filters")
        }}
        >
            Resetear
        </Button>
      </div>
    </div>
  )
}


export default CompanyListFilters;