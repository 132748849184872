import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrivilegeContext } from "../priviledge/PriviledgeProvider";
import { useFiltersPR } from "../filters/FiltersProvider";
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";
import { TablesWidget9 } from "../widgets";
import { handleConfirmationAlert } from "../SweetAlert/ConfirmationAlert";
import SpinnerCustom from "../Spinner/SpinnerCustom";
import AsyncImg from "../form/AsyncImg";
import { ProjectService } from "../../services/project/projectService";
import AddWizardUser from "../../pages/projects/create/AddWizardUsers";
import PageLayoutDataActionButtons from "../Page/PageLayoutDataActionButtons";
import CustomSearchInput from "../form/CustomSearchInput";
import { KTSVG } from "../Icon/KTSVG";

interface MembersCardProps {
  id: string;
}

export const MembersCard: FC<MembersCardProps> = ({ id }) => {

  const navigate = useNavigate();

  const { userCan } = useContext(PrivilegeContext)

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [users, setUsers] = useState<any>([]);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchProjectsMembers = useCallback(async () => {
    const projectService = new ProjectService();
    const response = await projectService.getProjectMembers(filters);
    return response.getResponseData() as any;
  }, [filters]);


  const [data, loading, error, refetch] = useFetch(fetchProjectsMembers);

  const handleSearch = (search: string) => {
    updateFilters({ search_array: search });
  }

  const deleteMember = async (users: any) => {
    let response = (await new ProjectService().deleteMember(id, users)).getResponseData();
    if (response.success) {
      toast.success('Miembro eliminado correctamente');
      refetch();
    }
  }

  const handleCheckboxChange = (
    user: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    if (checked) {
      setUsers((prevSelectedUsers: any) => [...prevSelectedUsers, user]);
    } else {
      setUsers((prevSelectedUsers: any) =>
        prevSelectedUsers.filter((selectedUser: any) => selectedUser !== user)
      );
    }
  };


  const _onCloseModal = async (message?: string, values?: any) => {
    if (values) {
      const projectService = new ProjectService();
      const response = (await projectService.addMembers(id, values)).getResponseData();
      if (response.success) {
        setIsModalOpen(false);
        toast.success(message ? message : "Accion Realizada correctamente");
        refetch();
      } else {
        toast.error(response.data.errors[0].message);
      }
    }
  }

  return (
    <>
      {
        data ? (
          <>

            <PageLayoutDataActionButtons>

              <div className='d-flex pe-4'>
                <CustomSearchInput placeholder={"Buscar participante"} onSearch={handleSearch} />
              </div>

              {
                userCan('edit', 'project') && (
                  <div className="d-flex">
                    <button className="btn btn-flush" title="Añadir participante" data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => setIsModalOpen(true)}>
                      <KTSVG path="/media/icons/duotune/general/gen041.svg" className='svg-icon-1' />
                    </button>
                  </div>
                )
              }

            </PageLayoutDataActionButtons>
            <TablesWidget9 className="card-primary m-3" title="Projects"
              subtitle="List of Projects"
              selectableItems={true}
              onSelectAllItems={(checked: boolean) => {
                if (checked) {
                  const newSelecteds = data.map((n: any) => n.id);
                  setUsers(newSelecteds);
                  return;
                }
                setUsers([]);
              }}
              onChangeCheckedItems={handleCheckboxChange}
              deleteButton={{
                title: "Borrar Seleccionados",
                icon: '/media/icons/duotune/general/gen027.svg',
                className: `btn-sm btn-danger fw-bold ${(users && users.length > 0) ? "" : "d-none"}`,
                callback: () => {
                  handleConfirmationAlert({
                    title: "Eliminar Miembros",
                    text: "¿Está seguro que desea eliminar este miembros?",
                    icon: "warning",
                    onConfirm: () => {
                      deleteMember(users);
                    },
                  });
                }
              }}
              data={data ? data : []}
              pagination={true}
              paginationData={{
                pageSize: filters.limit,
                pageCount: 1,
                currentPage: filters.page,
                handlePagination: updatePage,
                handlePerPage: updatePageSize
              }}
              columns={
                [
                  {
                    name: "",
                    keyValue: "name",
                    className: "text-center",
                    cellClassName: "p-0",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center" key={element.profileImg?.id}>
                          <Link to={`users/show/${element.id}`}>
                            <AsyncImg transparent src={element.profileImg?.id || ""} type={"avatar"}></AsyncImg>
                          </Link>
                        </div>
                      );
                    },
                  },
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="text-center">
                          {element.name}
                        </div>
                      )
                    },
                  },
                  {
                    name: "Email",
                    keyValue: "email",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="text-center">
                          {element.email}
                        </div>
                      )
                    },
                  },
                  { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]
              }
              actions={
                [
                  {
                    title: "Editar",
                    buttonType: 'icon',
                    iconColor: 'text-black',
                    iconPath: '/media/icons/duotune/general/gen055.svg',
                    additionalClasses: `text-black ${!userCan('edit', 'user') ? 'd-none' : ''}`,
                    description: "Editar miembro",
                    callback: (item: any) => {
                      userCan('edit', 'user') && navigate(`/users/edit/${item.id}`);
                    },
                  },
                  {
                    title: "Eliminar",
                    buttonType: 'icon',
                    iconColor: 'text-danger',
                    iconPath: '/media/icons/duotune/general/gen027.svg',
                    additionalClasses: 'text-danger',
                    description: "Eliminar miembro",
                    callback: (item: any) => {
                      handleConfirmationAlert({
                        title: "Eliminar Miembro",
                        text: "¿Está seguro que desea eliminar este miembro?",
                        icon: "warning",
                        onConfirm: () => {
                          deleteMember([item.id]);
                        },
                      });
                    },
                  },
                ]
              }
            ></TablesWidget9>

            {isModalOpen && (
              <AddWizardUser
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                mode="create"
                onClose={_onCloseModal}
                projectId={id}
              />
            )}
          </>
        ) : (
          <>
            <SpinnerCustom />
          </>
        )
      }
    </>
  );
}