import { FC, Fragment, useCallback, useContext, useState } from "react";
import { useFormik } from "formik";
import { RoleService } from "../../../services/auth/roleService";
import { PermissionService } from "../../../services/auth/permissionService";
import { NewRole, Permission, PermissionGroup, PermissionsApiResponse } from "../../../types/role-type";
import useFetch from "../../../hooks/useFetch";
import { CompanyService } from "../../../services/company/companyService";
import { Companies, CompaniesApiResponse } from "../../../types/company-type";
import { Accordion, Button, CloseButton, Form, Modal, Spinner } from "react-bootstrap";
import CustomSelect from "../../../components/form/CustomSelect";
import { KTSVG } from "../../../components/Icon/KTSVG";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

interface RoleCreateModalProps {
  isOpen: boolean;
  setIsOpen(...args: unknown[]): unknown;
  handleSuccessCreation(): void;
}

const RoleCreateModal: FC<RoleCreateModalProps> = ({ isOpen, setIsOpen, handleSuccessCreation }) => {
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<number[]>([]);
  const {userCan} = useContext(PrivilegeContext)
  const [loadign, setLoading] = useState(false);

  const fetchPermissions = useCallback(async () => {
    const permissionService = new PermissionService();
    const response = await permissionService.getPermissions();
    return response.getResponseData() as PermissionsApiResponse;
  }, []);

  const [permissions, fetchingPermissions, permissionError] =
    useFetch(fetchPermissions);

  const fetchCompanies = useCallback(async () => {
    if (!userCan("list", "companies")) return; 
    const companyService = new CompanyService();
    const response = await companyService.getCompanies();
    return response.getResponseData() as CompaniesApiResponse;
  }, []);

  const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

  const createRole = async (values: NewRole) => {
    values.permissions = selectedPermissions;

    try {
      setLoading(true);
      let response = await (
        await new RoleService().createRole(values)
      ).getResponseData();
      setLoading(false);
      handleSuccessCreation();
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      company: "",
      permissions: [],
    },
    onSubmit: (values: NewRole) => {
      if (selectedPermissions.length === 0) {
        toast.error("Debe seleccionar al menos un permiso");
        return;
      }
      createRole(values);
    },
  });

  const getCompanyList = () => {
    if (companies as Companies) {
      return companies.companies.map((productType: { id: any; name: any }) => {
        return {
          value: productType.id,
          label: productType.name,
        };
      });
    }
    return [];
  };

  const getContent = () => {
    if (fetchingPermissions || fetchingCompanies)
      return (
        <div className="text-center">
          {" "}
          <Spinner />{" "}
        </div>
      );

    if (permissionError) return <div>Error</div>;

    return (
      <Fragment>
        <div className="row g-4">
          <Form.Group
            id="name"
            className="col-md-6"
          >
            <Form.Label className="form-label fs-6 fw-bolder text-dark mb-3">Nombre <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Form.Group
            id="description"
            className="col-md-6"
          >
            <Form.Label className="form-label fs-6 fw-bolder text-dark mb-3">Descripción</Form.Label>
            <Form.Control
              name="description"
              type="text"
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Group>
          {userCan("list", "companies") && (
            <Form.Group
              id="company"
              className="col-md-6"
            >
              <Form.Label className="form-label fs-6 fw-bolder text-dark mb-3">Organización <span className="text-danger">*</span></Form.Label>
              <CustomSelect
                onChange={formik.handleChange}
                defaultValue={formik.values.company}
                placeholder="Elegir organización..."
                options={getCompanyList()}
              />
            </Form.Group>
          )}
        </div>
        <div className="row mt-5">
        {permissions?.map((group: PermissionGroup, index: number) => {
            return (
                <div className="col-lg-3 col-md-6 col-sm-6 mt-5" key={index}>
                    <Accordion key={`kt_accordion_${group.id}`} defaultActiveKey={`${group.id}`} flush>
                        <div className="accordion-custom">
                          <Accordion.Item eventKey={`${group.id}`}>
                              <Accordion.Header><div className="accordion-button-custom">{group.label}</div></Accordion.Header>
                              <Accordion.Body>
                                <Fragment>
                                  <Form.Check
                                    label="Seleccionar todos"
                                    value="all"
                                    checked={selectAll.includes( group.id )}
                                    onChange={() => {
                                      const list = group.permissions.map((item:Permission) => item.id);
                                      if (selectAll.includes(group.id)) {
                                        setSelectAll(selectAll.filter((id: number) => id !== group.id));
                                        setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
                                      }else{
                                        setSelectAll([ ...selectAll, group.id ]); 
                                        setSelectedPermissions([...selectedPermissions.concat(list) ]);
                                      }
                                    }}
                                  />
                                </Fragment>

                                {group.permissions.map( (permission: Permission, index: number) => {
                                    return (
                                      <div key={index}>
                                        <Form.Check
                                          label={permission.label}
                                          value={permission.id}
                                          checked={selectedPermissions.includes(
                                            permission.id
                                          )}
                                          onChange={() => {
                                            selectedPermissions.includes(permission.id)
                                              ? setSelectedPermissions(
                                                  selectedPermissions.filter(
                                                    (id: number) => id !== permission.id
                                                  )
                                                )
                                              : setSelectedPermissions([
                                                  ...selectedPermissions,
                                                  permission.id,
                                                ]);
                                          }}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </Fragment>
    );
  };

  return (
    <Modal show={isOpen} size="xl">
      <Modal.Header onHide={setIsOpen} className="pb-0 border-0 bg-success pb-2 pt-5 pb-4">
        <div className="modal-title justify-content-start">
          <h2 className="text-light align-text-bottom">Nuevo Rol</h2>
        </div>
        <CloseButton className="btn-close-light custom-close-btn" onClick={ () => {setIsOpen(false)}} />
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
      <Modal.Body className="mx-5 mx-xl-18 pt-5 pb-15">
        {getContent()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='info' type="submit" disabled={loadign} className="fw-bold">
          {loadign  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
          {loadign  ? <Spinner className="text-center text-light"/> : "Guardar Rol"}
        </Button>
      </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RoleCreateModal;
