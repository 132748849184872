import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

/**
 * @ES Endpoint de usuarios
 * @EN User endpoint
 */

const USER_ENDPOINT = '/users';

export class UserService extends RestServiceConnection {

 /**
  * 
  * @ES Crea un nuevo usuario
  * @EN Create a new user
  * 
  * @param values 
  * @returns API response
  */

  createUser = async (values: any) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: USER_ENDPOINT + '/create',
        data: values,
    }, true);
    return this;
  }

 /**
  * 
  * @ES Edita un usuario
  * @EN Edit a user
  * 
  * @param values 
  * @returns API response
  */ 

  editUser = async (values: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: USER_ENDPOINT + '/edit',
          data: values,
          headers: {
              "Content-Type": "application/json"
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Obtiene todos los usuarios
  * @EN Get all users
  * 
  * @param filters 
  * @returns API response
  */ 

  getUsers = async (filters: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: USER_ENDPOINT + '/list',
          data: {
              ...filters
          },
      }, true) as AxiosResponse;
      return this;
  }

 /**
  * 
  * @ES Obtiene un usuario por id
  * @EN Get a user by id
  * 
  * @param id 
  * @returns API response
  */ 

  getUserById = async (id: string) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: USER_ENDPOINT + '/get',
          data: {
              userId: id
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Cambia el estado de un usuario
  * @EN Change a user status
  * 
  * @param id 
  * @param status 
  * @returns API response
  */ 

  toggleUserStatus = async (id: string, status: boolean) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: USER_ENDPOINT + '/toggle',
          data: {
              userId: id,
              active: status
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Borra un usuario
  * @EN Delete a user
  * 
  * @param userId 
  * @returns API response
  */ 

  deleteUser = async (userId: string) => {

      this.response = await this.makeRequest({
          method: 'POST',
          url: USER_ENDPOINT + '/delete',
          data: {
              userId: userId
          }
      }, true);

      return this
  }

 /**
  * 
  * @ES Obtiene el usuario actual
  * @EN Get current user
  * 
  * @returns API response
  */ 

  me = async () => {
      this.response = await this.makeRequest({
          method: 'GET',
          url: USER_ENDPOINT + '/me',
      }, true);

      return this
  }

 /**
  * 
  * @ES Actualiza la imagen de un usuario
  * @EN Update a user image
  * 
  * @param file 
  * @param userId 
  * @returns API response
  */ 

  updateImage = async (file: File, userId: string) => {
    let formData = new FormData();
    formData.append('profileImg', file);
    formData.append('userId', userId);

    this.response = await this.makeRequest({
        method: 'POST',
        url: USER_ENDPOINT + '/add-image',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: formData
    }, true);

    return this
    }
   
   /**
    * 
    * @ES Actualiza los permisos de un usuario
    * @EN Update a user permissions
    * 
    * @param user 
    * @param permissions 
    * @returns API response
    */ 

    editUserPermissions = async (user: string, permissions: number[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit-permissions',
            data: {
                userId: user,
                permissions: permissions
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Restaura los permisos de un usuario
    * @EN Restore a user permissions
    * 
    * @param user 
    * @returns API response
    */ 

    restoreUserPermissions = async (user: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/reset-permissions',
            data: {
                userId: user,
            }
        }, true);
        return this;
    }

   /**
    * 
    * @ES Cambia la contraseña de un usuario
    * @EN Change a user password
    * 
    * @param values
    * @returns API response
    */

    changePassword = async (values: any) => {
         this.response = await this.makeRequest({
              method: 'POST',
              url: USER_ENDPOINT + '/change-password',
              data: values
         }, true);
         return this;
    }
}