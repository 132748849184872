import React, { Fragment, PropsWithChildren, ReactNode, useEffect } from 'react';
import { useLayout } from '../../pages/layout/LayoutProvider';
import { MenuComponent } from '../../utils/MenuComponent';


type PageLayoutDataActionButtonsProps = {};

const PageLayoutDataActionButtons = ({ children }: PropsWithChildren<PageLayoutDataActionButtonsProps>) => {
    
    const layout = useLayout();

    useEffect(() => {
        layout.setActionButtons(children);
        
        // Reset layout header on unmount component
        return () => {
            layout.resetActionButtons();
        }
    }, []);

    return (
        <Fragment></Fragment>
    );
};

export default PageLayoutDataActionButtons;