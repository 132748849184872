import { Fragment } from "react"
import { FiltersProvider } from "../../components/filters/FiltersProvider"
import { RoleList } from "./list"

const RoleListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <RoleList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default RoleListWrapper