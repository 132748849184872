import { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserService } from "../../../services/user/userService";
import useFetch from "../../../hooks/useFetch";
import { CompanyService } from "../../../services/company/companyService";
import { CompaniesApiResponse } from "../../../types/company-type";
import { RolesApiResponse } from "../../../types/role-type";
import { RoleService } from "../../../services/auth/roleService";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/form/AsyncImg";
import CustomSelect from "../../../components/form/CustomSelect";
import { KTSVG } from "../../../components/Icon/KTSVG";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    userData?: any;
}

interface IUserForm {
    name: string;
    lastName: string;
    email: string;
    roleId: string;
    password: string | null;
    re_password: string | null;
    company: string;
    picture?: any;
}

const UserEditSchema = yup.object({
    name: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    lastName: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    email: yup.string().email('Correo Invalido').required('Campo Obligatorio'),
    roleId: yup.string().required('Debes elegir un rol de la organización para poder crear un usuario'),
    password: yup.string().min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').nullable(),
    re_password: yup.string().when('password', {
        is: (val: string | null | undefined) => val !== null && val !== undefined && val.length > 0,
        then: (schema: yup.Schema) => schema.required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
        otherwise: (schema: yup.Schema) => schema.nullable(),
    }).nullable(),
});

const UserCreateSchema = UserEditSchema.concat(
    yup.object({
        roleId: yup.string().required('Debes elegir un rol de la organización para poder crear un usuario'),
        password: yup.string().required('Contraseña Obligatoria').min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres'),
        re_password: yup.string().required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
    })
);


const UserForm:FC<CreateFormProps> = ({isLoading, submit, userData}) => {

    const mode = userData ? 'Editar' : 'Crear';

    const { userCan } = useContext(PrivilegeContext);

    const user = useSelector((state: RootState) => state.auth.user);

    const [pictureValue, setPictureValue] = useState<string | ArrayBuffer | null>(null);

    const [picturePreview, setPicturePreview] = useState<string | ArrayBuffer | null>('/media/misc/image.png');

    const userInitialValues: IUserForm = {
        name: userData?.name,
        lastName: userData?.lastName,
        email: userData?.email,
        roleId: userData?.role,
        password: null,
        re_password: null,
        company: userData?.company,
        picture: null,
    }

    useEffect(() => {
        if (userData?.profileImg) {
            setPicturePreview(userData?.profileImg?.id);
        }
    }, [userData?.profileImg]);

    const handleUploadPicture = async (e: React.ChangeEvent<any>) => {

        if (e.target !== null && e.target.files?.length) {
            const files = e.target.files;
            let myFiles = Array.from(files)
            formik.setFieldValue("picture", myFiles);
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setPictureValue(fileReader.result);
            }
        };

        if (e.target !== null && e.target.files?.length) {
            fileReader.readAsDataURL(e.target?.files[0]);
        }

        if (userData?.id) {
            const response = (await (new UserService()).updateImage(e.target?.files[0], userData?.id)).getResponseData()

            if (!response.success) {
                toast.error(response.message);
                return;
            }

            console.log(response.data.img);

            setPicturePreview(response.data.img);

            toast.success('Imagen actualizada correctamente');
        }
    };

    const formik = useFormik({
        initialValues: userInitialValues,
        validationSchema: (mode === 'Editar') ? UserEditSchema : UserCreateSchema,
        onSubmit: values => {
            values = {
                ...userData,
                ...values
            }
            
            submit(values)
        },
    });

    const fetchCompanies = useCallback(async () => {
        const companyService = new CompanyService();
        if (userCan("list" , "companies")) return;
        const response = await companyService.getCompanies();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

    const getCompaniesList = () => {
        if (companies as any) {
            return companies?.companies?.map((company: any) => {
                return {
                    value: company.id,
                    label: company.name
                }
            }) || [];
        }
    }
    
    const fetchRoles = useCallback(async () => {
        const roleService = new RoleService();
        const response = await roleService.listRolesByCompany(formik.values.company);
        return response.getResponseData() as RolesApiResponse;
    }, [formik.values.company]);
    
    const [roles, fetchingRoles, roleError] = useFetch(fetchRoles);
    
    const getRolesList = () => {
        if (roles as any) {
            return roles?.roles?.map((role: any) => {
                return {
                    value: role.id,
                    label: role.name + (role.company !== null ? ` (${role.company?.name})` : '')
                }
            }) || [];
        }
    };

    const verifyClass = (inputFieldID: keyof IUserForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof IUserForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                {mode === 'Editar' && (
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='form-group p-2 mb-5 d-flex justify-content-center flex-column align-items-center'>
                                <div className="preview">
                                    <input
                                        id="upload_create_user_picture"
                                        form="edit-user-form"
                                        type="file"
                                        name="picture"
                                        hidden
                                        accept="image/*"
                                        onChange={
                                            (e) => {
                                                handleUploadPicture(e);
                                            }
                                        } />
                                <div onClick={() => {document.getElementById('upload_create_user_picture')?.click()}}>
                                    <AsyncImg transparent src={picturePreview} type={"preview"} styles="mw-250px"></AsyncImg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                <div className="row mb-3">
                    <div className="col-md-6">
                        <Form.Group className="mt-3" controlId="formName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Nombre</Form.Label>
                            <Form.Control type="text"
                                autoComplete='off'
                                name="name"
                                value={formik.values.name || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('name')}
                            />
                            {showErrors('name')}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mt-3" controlId="formLastName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Apellidos</Form.Label>
                            <Form.Control type="text"
                                autoComplete='off'
                                name="lastName"
                                value={formik.values.lastName || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('lastName')}
                            />
                            {showErrors('lastName')}
                        </Form.Group>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className={`${userCan("list" , "roles") ? 'col-md-6' : 'col-md-12'}`}>
                        <Form.Group className="mt-3" controlId="formEmail">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Email</Form.Label>
                            <Form.Control type="email"
                                autoComplete='off'
                                name="email"
                                value={formik.values.email || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('email')}
                            />
                            {showErrors('email')}
                        </Form.Group>
                    </div>
                    {userCan("list" , "roles") && roles && (
                        <div className="col-md-6" key={formik.values.company}>
                            <Form.Group className="mt-3" controlId="formPassword">
                                <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Rol</Form.Label>
                                <CustomSelect key={"role"} is_multi={false} options={getRolesList()} defaultValue={getRolesList().find((role: any) => role.value === formik.values.roleId) || ''}
                                onChangeSingle={(selected: any) =>{formik.setFieldValue('roleId', selected.value)}}  />
                                {showErrors('roleId')}
                            </Form.Group>
                        </div>
                    )}
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <Form.Group className="mt-3" controlId="formPassword">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Contraseña</Form.Label>
                            <Form.Control type="password"
                                autoComplete='off'
                                name="password"
                                onChange={formik.handleChange}
                                className={verifyClass('password')}
                            />
                            {showErrors('password')}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mt-3" controlId="formRepeatPassword">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Confirmar contraseña</Form.Label>
                            <Form.Control type="password"
                                autoComplete='off'
                                name="re_password"
                                onChange={formik.handleChange}
                                className={verifyClass('re_password')}
                            />
                            {showErrors('re_password')}
                        </Form.Group>
                    </div>
                </div>
                {userCan("list" , "companies") && companies && (
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <Form.Group className="mt-3" controlId="formCompany">
                                <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Empresa</Form.Label>
                                <CustomSelect is_multi={false} options={getCompaniesList()} defaultValue={getCompaniesList().find((projectType: any) => projectType.value === formik.values.company) || ''}
                                onChangeSingle={(selected: any) =>{formik.setFieldValue('company', selected.value)}}  />
                                {showErrors('company')}
                            </Form.Group>
                        </div>
                    </div>
                )}
                <div className='col-12 d-flex justify-content-center'>
                    <Button variant='info' className='fs-6 fw-bold mt-10 m-auto' type='submit' disabled={isLoading}>
                        {isLoading  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
                        {isLoading ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : mode === 'Editar' ? 'Editar Usuario' : 'Crear Usuario'}
                    </Button>
                </div>
            </form>
        </Fragment>
    );
};

export default UserForm;