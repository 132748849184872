import { FC } from "react";
import clsx from 'clsx'
import { Link } from "react-router-dom";
import { KTSVG } from "../Icon/KTSVG";
import { Button } from "react-bootstrap";

interface IAction {
  show?: boolean,
  title: string,
  icon?: string,
  className?: string,
  callback?(element: any): void
  element?: any,
  colorIcon?: string
}

type Actions = Array<IAction>;

interface IPageData {
  title: string,
  breadcrumbs: any,
  actions?: Actions,
}

const PageContentTitle:FC<IPageData> = ({
  title,
  breadcrumbs,
  actions,
}) => {

  const callToAction = (action: any, element: any) => {
    if (action.callback) {
      action.callback(element);
    }
  }

  window.addEventListener("scroll", function() {
    var scrollPosition = window.scrollY;
    var div = document.getElementById("fixed-div");
    if (div) {
      if (scrollPosition >= 100) {
        div.classList.add('fixed-div');
      } else {
        div.classList.remove('fixed-div');
      }
    }
  });

  return (
    <div className="card bg-white m-3" id="fixed-div">
      <div className="card-body p-5 d-flex justify-page-title flex-wrap">
      { breadcrumbs && breadcrumbs.length > 0 && (
        <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(breadcrumbs).map((item : any, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className='text-muted h3 mb-0 text-hover-info' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr071.svg'
                      className={`svg-icon-1hx text-muted`}
                    />
                  )}
                </li>
              ))}
              <li className='breadcrumb-item fs-6 text-dark'>{title}</li>
          </ul>
      )}
      { actions && actions.length > 0 && (
        <div className='d-flex align-items-center'>
          {Array.from(actions).map((item : any, index) => (
            <Button className={`btn ${item.className} ${item.show == false ? 'd-none' : ''}`} 
              key={`${item.title}${index}`} onClick={() => {
              callToAction(item, item.element);
            }}>
                {item.icon && ( <KTSVG path={item.icon} className={`svg-icon-2 ${item.colorIcon == null || item.colorIcon == undefined  ? 'text-ligth' : item.colorIcon}`} /> )}
                {item.title}
            </Button>
          ))}
        </div>
      )}
      </div>
    </div>
  );
}

export default PageContentTitle;