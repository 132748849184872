import * as React from 'react';
import { User } from '../../types/user-type';
import AsyncImg from '../form/AsyncImg';

export interface IUserCardListProps {
    users: User[];
}

export default function UserCardList({ users }: IUserCardListProps) {
    return (
        <React.Fragment>
            <div className="card card-xl-stretch mb-xl-8 p-0 border-0">

                <div className="card-body pt-2">
                    {
                        users && users.map(
                            (user, index) => (
                                <React.Fragment key={user.id}>
                                    <div className="d-flex align-items-center mb-7">
                                        <div className="symbol symbol-50px me-5">
                                            <AsyncImg transparent src={user?.profileImg?.id || ""} type={"avatar"} />
                                        </div>

                                        <div className="flex-grow-1">
                                            <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{user.name} {user.lastName}</a>
                                            <span className="text-muted d-block fw-bold">{user.email}</span>
                                        </div>

                                    </div>
                                </React.Fragment>
                            )
                        )
                    }
                </div>
            </div>


        </React.Fragment>
    );
}
