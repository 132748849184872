import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { TablesWidget9 } from "../../../components/widgets";
import { useFiltersPR } from "../../../components/filters/FiltersProvider";
import { UsersApiResponse } from "../../../types/user-type";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import moment from "moment";
import PageContentTitle from "../../../components/Page/PageData";
import { CustomDrawer } from "../../../components/Drawer/CustomDrawer";
import { handleConfirmationAlert } from "../../../components/SweetAlert/ConfirmationAlert";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import { BackupService } from "../../../services/backup/backupService";
import BackupListFilters from "../filters/BackupListFilters";

const breadcrumbs = [
  {
    title: 'ADMINISTRACIÓN',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'TIPOS DE PROYECTOS',
    path: 'admin/projects',
    isSeparator: true,
    isActive: true,
  },
]

export const BackupList: FC = () => {

  const navigate = useNavigate();

  const {userCan} = useContext(PrivilegeContext)

  const actions = [
    {
      show: userCan('create','backup'),
      title: "Crear Backup",
      icon : '/media/icons/duotune/general/gen041.svg',
      className: 'btn-success me-3 fw-bold text-light',
      colorIcon : "text-light",
      callback: () => {
        userCan('create','backup') && navigate('create');
      }
    },
    {
      title: "Filtros",
      icon : '/media/icons/duotune/general/gen031.svg',
      className: 'btn-info me-3 fw-bold',
      callback: () => {
        setOpenFilterDrawer(true);
      }
    }
  ]

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchBackups = useCallback(async () => {
    const backupService = new BackupService();
    const response = await backupService.getBackups(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchBackups);

  const handleSearch = (search: string) => {
    updateFilters({ search_array : search });
  }

	const deleteBackup = async (id: string) => {
		let response = (await new BackupService().deleteBackup(id)).getResponseData();
		if (response.success) {
			toast.success('Backup eliminado correctamente');
			refetch();
		}
	}

  return (
    <>
      {
        data ? (
            <>
              <CustomDrawer title="Backups" anchor="right" open={openFilterDrawer} onClose={handleClose}>
                <BackupListFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
              </CustomDrawer>
              <PageContentTitle title='Backups' breadcrumbs={breadcrumbs} actions={actions}/>
              <TablesWidget9 className="card-primary m-3" title="Backups"
                subtitle="List of Backups"
                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                data={data?.backups ? data.backups : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: (data as UsersApiResponse) ? data.lastPage : 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={
                  [
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Tipo de Backup",
                      keyValue: "backupType",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.backupType?.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Proyecto",
                      keyValue: "proyect",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.backupProjects[0]?.project?.name || '-'}
                          </div>
                        )
                      },
                    },
                    {
                      name: "IP",
                      keyValue: "ip",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.ip}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Frecuencia",
                      keyValue: "frequency_backup",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span>{element.frequencyBackup}</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Fecha",
                      keyValue: "date_backup",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{
                              element.dateBackup?.date ?
                                moment(element.dateBackup?.date).format('DD-MM-YYYY HH:mm')
                                : '-'
                            }</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Max Backups",
                      keyValue: "max_backup",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span>{element.maxBackup}</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Última Respuesta",
                      keyValue: "last_response",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{
                              element.lastResponse?.date ?
                                moment(element.lastResponse?.date).format('DD-MM-YYYY HH:mm')
                                : '-'
                            }</span>
                          </div>
                        )
                      },
                    },   
                    {
                      name: "Última Ejcución",
                      keyValue: "last_execution",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return <div className="text-center text-muted">
                          <span className={'text-muted'}>{
                          element.lastExecution?.date ?
                            moment(element.lastExecution?.date).format('DD-MM-YYYY HH:mm')
                            : '-'
                        }</span></div>;
                      }
                    }, 
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]
                }
                actions={
                  [
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-black',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-primary ${!userCan("edit" , "backup") && 'd-none'}`,
                      description: "Editar Backup",
                      callback: (item: any) => {
                        userCan("edit" , "backup") && navigate('edit/' + item.id );
                      },
                    },
      
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${!userCan("delete" , "backup") && 'd-none'}`,
                      description: "Eliminar Backup",
                      callback: (item: any) => {
                          handleConfirmationAlert({
                            title: "Eliminar Backup",
                            text: "¿Está seguro que desea eliminar este backup? Esta acción borrará todos los datos relacionados a este backup.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteBackup(item.id);
                            },
                          });
                      },
                    },
                  ]
                }
              ></TablesWidget9>
            </>
        ) : (
          <>
            <SpinnerCustom />
          </>
        )
      }
    </>
  );
}