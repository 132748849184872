import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

interface IConfirmationAlert {
    title: string;
    text?: string;
    html?: JSX.Element;
    preConfirm?: () => void;
    icon: 'warning' | 'success' | 'error' | 'info' | 'question';
    onConfirm: () => void;
}

const MySwal = withReactContent(Swal);

export const handleConfirmationAlert = async (props: IConfirmationAlert) => {
    
    let options: any = {
        heightAuto: false,
    };
    
    if(props.text) {
        options.text = props.text;
    }

    if(props.html) {
        options.html = props.html;
    }

    if(props.preConfirm) {
        options.preConfirm = props.preConfirm;
    }

    options = {
        ...options,
        title: props.title,
        icon: props.icon,
        showCancelButton: true,
        confirmButtonColor: '#247586',
        cancelButtonColor: '#e5133d',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
    }

    return MySwal.fire(options).then((result) => {
        if (result.isConfirmed) {
          props.onConfirm();
        }
    });
}
