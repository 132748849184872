import { useFormik } from "formik";
import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PermissionService } from "../../../services/auth/permissionService";
import { Permission, PermissionGroup, RolesApiResponse } from "../../../types/role-type";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import useFetch from "../../../hooks/useFetch";
import { RoleService } from "../../../services/auth/roleService";
import { Accordion, Button, Form, Spinner } from "react-bootstrap";
import { KTSVG } from "../../../components/Icon/KTSVG";
import CustomSelect from "../../../components/form/CustomSelect";
import { CompanyService } from "../../../services/company/companyService";
import { UserService } from "../../../services/user/userService";
import { CompaniesApiResponse } from "../../../types/company-type";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

interface CreateFormProps {
    isLoading: boolean;
    submit: (values: RoleForm) => void;
    roleData?: any;
}

export interface RoleForm {
    name:        string;
    description: string;
    permissions: RolePermissions;
    company:     string;
}

export interface RolePermissions {
    users:     string[];
    companies: string[];
    roles:     string[];
}

const roleInitialValues: RoleForm = {
    name: '',
    description: '',
    company: '',
    permissions: {
        users: [],
        companies: [],
        roles: [],
    },
}

const RoleForm: FC<CreateFormProps> = ({ isLoading, submit, roleData }) => {
    const navigate = useNavigate();
    const { userCan } = usePrivilege();
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState<number[]>([]);

    const verifyClass = (inputFieldID: keyof RoleForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof RoleForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }


    const fetchPermissions = useCallback(async () => {
        const permissionService = new PermissionService();
        const response = await permissionService.getPermissions();

        const allPermissionsData = response.getResponseData().data;

        // find permissions selected in roleData
        const defaultSelectedPermissions = allPermissionsData.reduce((acc: any, group: PermissionGroup) => {
            let permissionIdsOfGroup: number[] = [];
            group.permissions.forEach((permission: Permission) => {
                if (roleData?.permissions.find((objeto: any) => objeto.permission.id === permission.id)) {
                    permissionIdsOfGroup.push(permission.id);
                }
            });
            return [...acc, ...permissionIdsOfGroup];
        }, []);

        setSelectedPermissions(defaultSelectedPermissions);

        return response.getResponseData() as RolesApiResponse;

    }, []);


    const [permissionData, loadingPermission, errorPermission, fetchPermissionData] = useFetch(fetchPermissions);

    const showPermissions = () => {
        if (loadingPermission) return (<div className="text-center">{" "}<SpinnerCustom />{" "}</div>);

        if (errorPermission) return <div className="text-center">{" "}Error al cargar los permisos{" "}</div>;

        if (permissionData) {
            return (
                <div className="row w-100 d-flex justify-content-center">
                        {permissionData?.map((group: PermissionGroup, index: number) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5 min-w-75" key={index}>
                                    <Accordion key={`kt_accordion_${group.id}`} defaultActiveKey={`${group.id}`} flush>
                                        <div className="accordion-custom">
                                    <Accordion.Item eventKey={`${group.id}`}>
                                    <Accordion.Header><div className="accordion-button-custom">{group.label}</div></Accordion.Header>
                                    <Accordion.Body>
                                            <Fragment>
                                                <Form.Check
                                                    label="Seleccionar todos"
                                                    className="mb-2"
                                                    value="all"
                                                    checked={selectAll.includes(group.id)}
                                                    onChange={() => {
                                                        const list = group.permissions.map((item: Permission) => item.id);
                                                        if (selectAll.includes(group.id)) {
                                                            setSelectAll(selectAll.filter((id: number) => id !== group.id));
                                                            setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
                                                        } else {
                                                            setSelectAll([...selectAll, group.id]);
                                                            setSelectedPermissions([...selectedPermissions.concat(list)]);
                                                        }
                                                    }}
                                                />
                                            </Fragment>

                                            {group.permissions.map((permission: Permission, index: number) => {

                                                return (
                                                    <div key={index}>
                                                        <Form.Check
                                                            className="mb-2"
                                                            label={permission.label}
                                                            value={permission.id}
                                                            name={`permissions[]`}
                                                            checked={selectedPermissions.includes(
                                                                permission.id
                                                            )}
                                                            onChange={() => {
                                                                selectedPermissions.includes(permission.id)
                                                                ? setSelectedPermissions(
                                                                    selectedPermissions.filter(
                                                                        (id: number) => id !== permission.id
                                                                    )
                                                                )
                                                                : setSelectedPermissions([
                                                                    ...selectedPermissions,
                                                                    permission.id,
                                                                ]);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            },
                                            )}
                                    </Accordion.Body>
                                </Accordion.Item>
                                </div>
                            </Accordion>
                        </div>
                            );
                        })}
                    </div>
            )
        }
    }

    const { id } = useParams<{ id: string }>();

    const handleEditRole = async (values: any) => {
        if(id) values.roleId = parseInt(id);
        if (selectedPermissions.length === 0) toast.error('Debes seleccionar al menos un permiso');
        values.permissions = selectedPermissions;
        if (values && id) {
            try {
                const response = await (await new RoleService().editRole(values)).getResponseData();

                if (response.success) {
                    navigate(-1);
                    setTimeout(() => {
                        toast.success('Rol editado correctamente');
                    }, 500);
                } else {
                    throw new Error(response.message);
                }
            } catch (e: any) {
                console.error(e);
                toast.error(e.message || 'Error al editar el rol');
            }
        }
    }

    const formik = useFormik({
        initialValues: roleData ? roleData : roleInitialValues,
        onSubmit: handleEditRole,
    });

    const fetchCompanies = useCallback(async () => {
        const companyService = new CompanyService();
        const response = await companyService.getCompanies();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

    const getCompaniesList = () => {
        if (companies as any) {
            return companies?.companies?.map((company: any) => {
                return {
                    value: company.id,
                    label: company.name
                }
            }) || [];
        }
    }

    return (
        <Fragment>
            <div className="card border-0">
            <form onSubmit={formik.handleSubmit}>
                <div className="card-body p-3 border-0">
                    <div className="row">
                                <div className="row d-flex justify-content-center ">
                                    <Form.Group className='col-md-4'>
                                        <Form.Label className="fs-4 fw-bolder tex-dark">Nombre</Form.Label>
                                        <Form.Control id='name' onChange={formik.handleChange} required value={formik.values.name} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </Form.Group>
                                    <Form.Group className='col-md-8'>
                                        <Form.Label className="fs-4 fw-bolder tex-dark">Descripción</Form.Label>
                                        <Form.Control id='description' onChange={formik.handleChange} value={formik.values.description} className={verifyClass('description')} />
                                        {showErrors('description')}
                                    </Form.Group>
                                </div>
                                {userCan("list" , "companies") && companies && (
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <Form.Group className="mt-3" controlId="formCompany">
                                                <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Empresa</Form.Label>
                                                <CustomSelect is_multi={false} options={getCompaniesList()} defaultValue={getCompaniesList().find((projectType: any) => projectType.value === formik.values.company) || ''}
                                                onChangeSingle={(selected: any) =>{formik.setFieldValue('company', selected.value)}}  />
                                                {showErrors('company')}
                                            </Form.Group>
                                        </div>
                                    </div>
                                )}
                                <div className="row mt-5 w-100 d-flex justify-content-center flex-wrap">
                                    {showPermissions()}
                                </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <Button type="submit" size='lg' variant='info' disabled={isLoading} className="fw-bold">
                        {isLoading  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
                        {isLoading ? <Spinner className="text-center text-light"/> : 'Guardar'}               
                    </Button>
                </div>
            </form>
        </div>
        </Fragment>
    )
}

export default RoleForm;