import {Link} from 'react-router-dom'
import {MutableRefObject, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import { KTSVG } from '../../../components/Icon/KTSVG'
import { ToggleComponent } from '../../../utils/menu/_ToggleComponent'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = (props: PropsType) => {

  const toggleRef = useRef<HTMLDivElement>(null)

  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  return (
    <div className='app-sidebar-logo' id='kt_app_sidebar_logo'>
      <Link to='/'>
        <img
          alt='Logo'
          src='/media/logos/logo-studio128up-b-8.png'
          className='h-60px ms-5 mt-3 app-sidebar-logo-default px-6'
        />
        <img
          alt='Logo'
          src='/media/logos/logo-b.png'
          className='h-20px app-sidebar-logo-minimize px-3'
        />
      </Link>
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: toggleState}
          )}
          onClick={() => { 
            setToggleState(!toggleState);  
            if (document.getElementById('kt_app_body')?.getAttribute('data-kt-app-sidebar-minimize') === 'on') { 
              document.getElementById('kt_body')?.setAttribute('data-kt-app-sidebar-minimize', 'off');
              document.getElementById('kt_app_body')?.setAttribute('data-kt-app-sidebar-minimize', 'off');
              document.getElementById('kt_app_body')?.setAttribute('data-kt-app-header-minimize', 'off');
            } else {
              document.getElementById('kt_body')?.setAttribute('data-kt-app-sidebar-minimize', 'on'); 
              document.getElementById('kt_app_body')?.setAttribute('data-kt-app-sidebar-minimize', 'on');
              document.getElementById('kt_app_body')?.setAttribute('data-kt-app-header-minimize', 'on');
            }
          }}
          data-kt-toggle={toggleState ? 'true' : 'false'}
          data-kt-toggle-state={'active'}
          data-kt-toggle-target='body'
          data-kt-toggle-name={'app-sidebar-minimize'}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-1 rotate-180'/>
        </div>
    </div>
  )
}

export {SidebarLogo}
