import { ChangeEvent, FC, useCallback, useState } from "react";
import { useFormik } from "formik";
import { User, UserApiResponse } from "../../../types/user-type";
import { UserService } from "../../../services/user/userService";
import useFilters from "../../../hooks/useFilters";
import useFetch from "../../../hooks/useFetch";
import { Badge, Button, CloseButton, FormControl, FormGroup, FormLabel, Modal, ModalBody, ModalHeader, ModalTitle, Spinner } from "react-bootstrap";
import Wizard, { WizardItem } from "../../../components/Cards/Wizard";
import { TablesWidget9 } from "../../../components/widgets";
import { RoleService } from "../../../services/auth/roleService";
import { RolesApiResponse } from "../../../types/role-type";
import CustomSelect from "../../../components/form/CustomSelect";
import AsyncImg from "../../../components/form/AsyncImg";
import { toast } from "react-toastify";
import Option from "react-select/dist/declarations/src/components/Option";
import Select from "react-select/dist/declarations/src/Select";
import ReactSelect from "react-select";
import { ProjectService } from "../../../services/project/projectService";
import UserCardList from "../../../components/User/UserCardList";

export interface IUsersForm {
    userSelected: User[] | null;
}

const UsersInitialValues: IUsersForm = {
    userSelected: null,
}

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    onClose?: (message: string, values?: any) => void;
    projectId: string;
}

export interface IOperationForm {
    rol: string;
    office: string;
    email: string;
    name: string;
}

export interface IProductsFilters {
}

const AddWizardUser: FC<CreateModalProps> = ({ isOpen, setIsOpen, mode, onClose, projectId }) => {

    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

    const ProductsFilters: IProductsFilters = {
        active: 1
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(ProductsFilters, [], 50);

    const fetchUsers = useCallback(async () => {
        const userService = new UserService();
        const response = await userService.getUsers(filters);
        return response.getResponseData() as UserApiResponse;
    }, [filters]);

    const [availableUsers, loading, error, refetch] = useFetch(fetchUsers);

    const fetchAlreadyParticipants = useCallback(async () => {
        const projectService = new ProjectService();
        const response = await projectService.getProjectMembers({ projectId: projectId });
        return response.getResponseData() as UserApiResponse;
    }, []);

    const [alreadyParticipants, alreadyParticipantsLoading, alreadyParticipantsError, alreadyParticipantsRefetch] = useFetch(fetchAlreadyParticipants);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: UsersInitialValues,
        onSubmit: (values) => {
            if (selectedUsers.length > 0) {
                onClose && onClose("Usuario seleccionado correctamente", selectedUsers.map((user) => user.id));
            } else {
                toast.warning('No hay usuarios seleccionados');
            }
        },
    });

    // Get all available users that not are already participants
    const renderUserOptions = (users: User[]) => {
        users = users.filter((user) => !alreadyParticipants?.some((aleradyUser: any) => aleradyUser.id === user.id));
        return users.map((user) => {
            return {
                value: user,
                label: `${user.name} ${user.lastName} (${user.email})`,
            }
        });
    }


    return (
        <Modal show={isOpen} size='lg' title='Elegir participantes'>
            <Modal.Header onHide={setIsOpen} className="pb-0 border-0 bg-primary pb-2 pt-5 pb-4">
                <div className="modal-title">
                    <h2 className="text-light align-text-bottom">{mode === 'create' ? 'Nuevo' : 'Editar'} participante</h2>
                </div>
                <CloseButton className="btn-close-light custom-close-btn" onClick={() => { setIsOpen(false) }} />
            </Modal.Header>
            <ModalBody>
                {availableUsers && alreadyParticipants && (
                    <FormGroup className="pt-3">
                        <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Elige participantes</FormLabel>
                        <CustomSelect
                            options={renderUserOptions(availableUsers.users)}
                            defaultValue={null}
                            is_multi={true}
                            onChange={(selected: any) => { 
                                const values = selected.map((s: any) => s.value);
                                setSelectedUsers(values);
                                formik.setFieldValue('userSelected', values.map((user: User) => user.id));
                             }}
                        />
                    </FormGroup>
                )}

                <FormGroup className="pt-3 text-end">
                    <Button type="submit" color="primary" size="sm" onClick={() => formik.handleSubmit()} className="btn btn-primary">Añadir</Button>
                </FormGroup>

                <hr className="mt-5 mb-5" />
                <h2 className="align-text-bottom text-center">Participantes</h2>

                <UserCardList users={alreadyParticipants}></UserCardList>

            </ModalBody>
            {/*
                <Wizard
                    isHeader={false}
                    title="Selección de usuario"
                    color={'info'}
                    onSubmitFunct={formik.handleSubmit}
                    submitTipe="submit"
                    onNext={(active: number) => {
                        if (active === 0) {
                            updateFilters({
                                name: formik.values.name,
                                email: formik.values.email,
                                roles: formik.values.rol,
                            })
                        }
                    }}
                >
                    <WizardItem
                        id={'options_user'}
                        title={'Opciones del Usuario'}
                    >
                        <div className="row">
                            <FormGroup className="mt-3" controlId="formPassword">
                                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Rol</FormLabel>
                                <CustomSelect key={"role"} is_multi={true} options={getRolesList()}
                                onChange={(selected: any) => { formik.setFieldValue('rol',  selected.map((s: any) => s.value)) }}/>
                            </FormGroup>
                        </div>
                        <div className="row mt-5">
                            <FormGroup id='name' className='col-md-6'>
                                <FormLabel> Nombre: </FormLabel>
                                <FormControl id="name" value={formik.values.name || ''} onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id='email' className='col-md-6'>
                                <FormLabel> Correo Electronico: </FormLabel>
                                <FormControl id="email" type="email" value={formik.values.email || ''} onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </WizardItem>
                    <WizardItem
                        id={'choose_user'}
                        title={'Elije el usuario'}
                    >
                        {
                            availableUsers ? (
                                <TablesWidget9
                                    data={availableUsers.users}
                                    className={"table table-hover p-3 fs-7"}
                                    pagination={true}
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: availableUsers as UserApiResponse ? availableUsers.lastPage : 1,
                                        handlePagination: updatePage,
                                        handlePerPage: updatePageSize,
                                    }}
                                    columns={[
                                        {
                                            name: "",
                                            keyValue: "selected",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <div key={item.id} className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`checkbox-${item.id}`}
                                                                checked={selectedUsers.some((user) => user.id === item.id)}
                                                                onChange={(event) => handleCheckboxChange(event, item)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div >
                                            },
                                        },
                                        {
                                            name: "Nombre",
                                            keyValue: "name",
                                            className: "text-center",
                                            cellClassName: "text-center",
                                        },
                                        {
                                            name: "Email",
                                            keyValue: "email",
                                            className: "text-center",
                                            cellClassName: "text-center",
                                        },
                                        {
                                            name: "Rol",
                                            keyValue: "role",
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                  <div className="d-flex justify-content-center">
                                                    {element.userRoles.map((userRole: any) => (
                                                      <Badge
                                                      key={userRole.id}
                                                      className="px-3 py-2 text-light bg-success"
                                                      >
                                                        {userRole.role.name}
                                                      </Badge>
                                                    ))}
                                                  </div>
                                                );
                                              },
                                        },
                                    ]}
                                />
                    ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
                        }
                    </WizardItem>
                    <WizardItem
                        id={'resume_user'}
                        title={'Resumen Usuario'}
                    >
                        {getResume()}

                    </WizardItem>
                </Wizard>
            */}

        </Modal>
    );
};

export default AddWizardUser;
