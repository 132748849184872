import { FC, Fragment, useCallback, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Form, InputGroup } from "react-bootstrap";
import CustomSelect from "../../../components/form/CustomSelect";
import { KTSVG } from "../../../components/Icon/KTSVG";
import RenderBackupTypeField from "./RenderBackupTypeField";
import { ProjectService } from "../../../services/project/projectService";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import RenderDateBackup from "../../../components/Backups/RenderDateBackup";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    backupData?: any;
}

interface IBackupForm {
    name: string;
    ip: string;
    user: string;
    password: string;
    maxBackup: number;
    frequencyBackup: 'Monthly' | 'Weekly' | 'Daily';
    monthDayBackup: number;
    weekDayBackup: number;
    dayBackup: string;
    backupType: number;
    project: string;
    databaseName: string | null;
    databaseUser: string | null;
    databasePassword: string | null;
    databaseType: number | null;
    dirnamePath: string | null;
    serverPath: string;
    containerName: string | null;
}

const backupSchemaEdit= yup.object({
    name: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    ip: yup.string().test('ip', 'IP Inválida', function(value) {
        return !!value && /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i.test(value);
    }).required('Campo Obligatorio'),
    user: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    password: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').nullable(),
    maxBackup: yup.number().min(1, 'Debe tener como mínimo un backup').required('Campo Obligatorio'),
    databaseName: yup.string().test('isRequired', 'Campo Obligatorio', function(value) {
        const { backupType } = this.parent;
        if (backupType === 1) {
            return !!value;
        }
        return true;
    }).nullable(),
    databaseUser: yup.string().test('isRequired', 'Campo Obligatorio', function(value) {
        const { backupType } = this.parent;
        if (backupType === 1) {
            return !!value;
        }
        return true;
    }).nullable(),
    databaseType: yup.string().test('isRequired', 'Campo Obligatorio', function(value) {
        const { backupType } = this.parent;
        if (backupType === 1) {
            return !!value;
        }
        return true;
    }).nullable(),
    containerName: yup.string().test('isRequired', 'Campo Obligatorio', function(value) {
        const { backupType } = this.parent;
        if (backupType === 1) {
            return !!value;
        }
        return true;
    }).nullable(),
    dirnamePath: yup.string().test('isRequired', 'Campo Obligatorio', function(value) {
        const { backupType } = this.parent;
        if (backupType === 2) {
            return !!value;
        }
        return true;
    }).nullable(),
});

const backupSchemaCreate = backupSchemaEdit.concat(
    yup.object({
        password: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
        databasePassword: yup.string().test('isRequired', 'Campo Obligatorio', function(value) {
            const { backupType } = this.parent;
            if (backupType === 1) {
                return !!value;
            }
            return true;
        }).nullable(),
    })
);


const BackupForm:FC<CreateFormProps> = ({isLoading, submit, backupData}) => {

    const backupTypes = [
        { value: 1, label: 'Base de Datos' },
        { value: 2, label: 'Recursos' }
    ]

    const frequencies = [
        { value: 'Monthly', label: 'Mensual' },
        { value: 'Weekly', label: 'Semanal' },
        { value: 'Daily', label: 'Diario' }
    ]

    const mode = backupData ? 'Editar' : 'Crear';

    const [showPass, setShowPass] = useState(false);

    const backupInitialValues: IBackupForm = {
        name: backupData?.name,
        ip: backupData?.ip,
        user: backupData?.user,
        password: backupData?.password,
        maxBackup: backupData?.maxBackup,
        frequencyBackup: backupData?.frequencyBackup,
        monthDayBackup: backupData?.monthDayBackup,
        weekDayBackup: backupData?.weekDayBackup,
        dayBackup: backupData?.dayBackup?.date.substring(11, 16),
        backupType: backupData?.backupType.id,
        project: backupData?.backupProjects, 
        databaseName: backupData?.databaseName,
        databaseUser: backupData?.databaseUser,
        databasePassword: backupData?.databasePassword,
        databaseType: backupData?.databaseType?.id,
        serverPath: backupData?.serverPath,
        dirnamePath : backupData?.dirnamePath,
        containerName : backupData?.containerName,
    }
    
    const formik = useFormik({
        initialValues: backupInitialValues,
        validationSchema: mode === 'Editar' ? backupSchemaEdit : backupSchemaCreate,
        onSubmit: values => {
            if (values.backupType === undefined) {
                toast.error('Debe seleccionar un tipo de backup');
                return;
            }
            if (values.frequencyBackup === undefined) {
                toast.error('Debe seleccionar una frecuencia de backup');
                return;
            }
            if (values.project === undefined) {
                toast.error('Debe seleccionar un proyecto');
                return;
            }
            if (values.backupType == 1) {
                values.dirnamePath = null;
            }else if (values.backupType == 2) {
                values.databaseName = null;
                values.databaseUser = null;
                values.databasePassword = null;
                values.databaseType = null
                values.containerName = null
            }
            values = {
                ...backupData,
                ...values
            }
            submit(values)
        },
    });
    
    const fetchProjects = useCallback(async () => {
        const projectService = new ProjectService();
        const response = await projectService.getProject({});
        return response.getResponseData() as any;
    }, []);

    const [projects] = useFetch(fetchProjects);

    const getProjectList = () => {
        if (projects as any) {
            return projects?.projects?.map((company: any) => {
                return {
                    value: company.id,
                    label: company.name
                }
            }) || [];
        }
    }

    const verifyClass = (inputFieldID: keyof IBackupForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof IBackupForm) => {
        return (formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }

    const clickHandler = () => {
        setShowPass(!showPass);
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} id="backupForm">
                <div className="row mb-3">
                    <div className="col-md-3">
                        <Form.Group className="mt-3" controlId="formName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Nombre</Form.Label>
                            <Form.Control type="text"
                                autoComplete='off'
                                name="name"
                                value={formik.values.name || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('name')}
                            />
                            {showErrors('name')}
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mt-3" controlId="formLastName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>IP</Form.Label>
                            <Form.Control type="text"
                                autoComplete='off'
                                name="ip"
                                value={formik.values.ip || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('ip')}
                            />
                            {showErrors('ip')}
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mt-3" controlId="formLastName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Usuario del Servidor</Form.Label>
                            <Form.Control type="text"
                                autoComplete='off'
                                name="user"
                                value={formik.values.user || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('user')}
                            />
                            {showErrors('user')}              
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mt-3" controlId="formLastName">
                        <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Contraseña del Servidor</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control type={showPass ? 'text' : 'password'}
                                    autoComplete='off'
                                    name="password"
                                    value={formik.values.password || ''}
                                    onChange={formik.handleChange}
                                    className={verifyClass('password')}
                                />
                                <Button variant="light" onClick={clickHandler}>
                                    {showPass ? <KTSVG path="/media/icons/duotune/general/gen071.svg" className="svg-icon-2 svg-icon-success me-1" /> : 
                                    <KTSVG path="/media/icons/duotune/general/gen072.svg" className="svg-icon-2 svg-icon-success me-1" />}
                                </Button>
                            </InputGroup>
                            {showErrors('password')}
                        </Form.Group>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Form.Group className="mt-3" controlId="formName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Tipo de backup</Form.Label>
                            <CustomSelect
                                key={formik.values.backupType}
                                defaultValue={backupTypes.find((backupType: any) => backupType.value == formik.values.backupType) || ''}
                                onChangeSingle={(selected: any) =>{formik.setFieldValue('backupType', selected.value)}}
                                options={backupTypes}/>
                            {showErrors('backupType')}
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mt-3" controlId="formName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Maximo de backups</Form.Label>
                            <Form.Control type="number"
                                autoComplete='off'
                                name="maxBackup"
                                value={formik.values.maxBackup || ''}
                                onChange={formik.handleChange}
                                className={verifyClass('maxBackup')}
                            />
                            {showErrors('maxBackup')}
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mt-3" controlId="formLastName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Proyecto</Form.Label>
                            {projects && (
                            <CustomSelect
                                    key={formik.values.project}
                                    defaultValue={getProjectList().find((project: any) => project.value == formik.values.project) || ''}
                                    onChangeSingle={(selected: any) =>{formik.setFieldValue('project', selected.value)}}
                                    options={getProjectList()}/>
                            )}
                            {showErrors('project')}
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <Form.Group className="mt-3" controlId="formPathServer">
                        <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Ruta de la carpeta del Servidor</Form.Label>
                        <Form.Control type="text"
                        autoComplete='off'
                        name="serverPath"
                        value={formik.values.serverPath || ''}
                        onChange={formik.handleChange}
                        className={verifyClass('serverPath')}
                        />
                        {showErrors('serverPath')}
                    </Form.Group>
                </div>
                <div className="row mb-3">
                    <div className={`${formik.values.frequencyBackup ? 'col-md-6' : 'col-md-12'}`}>
                        <Form.Group className="mt-3" controlId="formLastName">
                            <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Frecuencia de backups</Form.Label>
                            <CustomSelect
                                defaultValue={frequencies.find((frequency: any) => frequency.value == formik.values.frequencyBackup) || ''}
                                onChangeSingle={(selected: any) =>{formik.setFieldValue('frequencyBackup', selected.value)}}
                                options={frequencies}/>
                            {showErrors('frequencyBackup')}
                        </Form.Group>
                    </div>
                    <div className={`${formik.values.frequencyBackup ? 'col-md-6' : ''}`} key={formik.values.frequencyBackup}>
                        <RenderDateBackup formik={formik} showErrors={showErrors} verifyClass={verifyClass} />
                    </div>
                </div>
                <RenderBackupTypeField formik={formik} verifyClass={verifyClass} showErrors={showErrors} key={formik.values.backupType}/>
                <div className='col-12 d-flex justify-content-center'>
                    <Button variant='info' className='fs-6 fw-bold mt-10 m-auto' type='submit' disabled={isLoading}>
                        {isLoading  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
                        {isLoading ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : mode === 'Editar' ? 'Editar Backup' : 'Crear Backup'}
                    </Button>
                </div>
            </form>
        </Fragment>
    );
};

export default BackupForm;