import * as React from 'react';
import AsyncImg from '../../../../components/form/AsyncImg';
import { FormGroup, FormLabel } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectService } from '../../../../services/project/projectService';
import useFetch from '../../../../hooks/useFetch';
import PageLayoutData from '../../../../components/Page/PageLayoutData';
import { usePrivilege } from '../../../../components/priviledge/PriviledgeProvider';
import PageLayoutDataActionButtons from '../../../../components/Page/PageLayoutDataActionButtons';
import { KTSVG } from '../../../../components/Icon/KTSVG';

export interface IProjectViewResumeProps {

}

export default function ProjectViewResume(props: IProjectViewResumeProps) {

    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();

    const fetchProject = React.useCallback(async () => {
        const projectService = new ProjectService();
        const response = await projectService.getProjectById(id as string);
        return response.getResponseData() as any;
    }, [id]);

    const [data] = useFetch(fetchProject);

    return (
        <React.Fragment>

            {data && (
                <>
                    <PageLayoutDataActionButtons>
                        {
                            userCan('edit', 'project') && (
                                <div className="d-flex">
                                    <button className="btn btn-flush" title="Editar Proyecto" data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => navigate(`/admin-projects/edit/${data.id}`)}>
                                        <KTSVG path="/media/icons/duotune/general/gen055.svg" className='svg-icon-1' />
                                    </button>
                                </div>
                            )
                        }
                    </PageLayoutDataActionButtons>
                    <div className={`card mt-3`}>
                        <div className="card-body pt-3">
                            <div className="separator my-2"></div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <div className="d-flex justify-content-center" key={data.projectImg?.id}>
                                        <AsyncImg transparent styles="max-200px" src={data.projectImg?.id || ""} type={"preview"}></AsyncImg>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 row">
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">NOMBRE:</FormLabel>
                                                <div className="fw-bold ms-2">{data?.name}</div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">TIPO:</FormLabel>
                                                <div className="fw-bold ms-2">{data?.projectType?.name}</div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">COLOR:</FormLabel><br />
                                                <div className={`ms-2 p-3 rounded text-center`}
                                                    style={{ backgroundColor: `${data?.color}`, color: 'white' }}>
                                                    {data?.color}
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="separator mt-2 mb-2"></div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-6">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">CREACION:</FormLabel>
                                                <div>
                                                    <span className={'text-muted'}>{moment(data.createdAt?.date).format('DD-MM-YYYY HH:mm')}</span>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">ULT. EDICION:</FormLabel>
                                                <div>
                                                    <span className={'text-muted'}>{moment(data.updateAt?.date).format('DD-MM-YYYY HH:mm')}</span>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <FormGroup className="ms-4">
                                        <FormLabel className="form-label fw-bold text-muted">DESCRIPCION:</FormLabel>
                                        <div className="fw-bold ms-2">{data?.description || '-'}</div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="separator my-2"></div>
                        </div>
                    </div>
                </>

            )}
        </React.Fragment>
    );
}
