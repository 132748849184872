import { FC, useEffect, useState } from "react";
import { Button, FormControl, FormGroup, FormLabel, InputGroup } from "react-bootstrap";
import CustomSelect from "../../../components/form/CustomSelect";
import { KTSVG } from "../../../components/Icon/KTSVG";

interface RenderBackupTypeFieldProps {
  formik: any;
  verifyClass: Function;
  showErrors: Function;
}

const RenderBackupTypeField: FC<RenderBackupTypeFieldProps> = ({ formik, verifyClass, showErrors }) => {

  const databaseTypes = [
    { value: 1, label: 'MySQL' },
    { value: 2, label: 'MariaDB' }
  ]
  const [showPass, setShowPass] = useState(false);

  const clickHandler = () => {
    setShowPass(!showPass);
  }

  useEffect(() => {
    // Esto es opcional, dependiendo de si necesitas realizar alguna acción cuando se actualice formik
    // Aquí puedes agregar lógica para manejar cambios en formik
  }, [formik]); // Esto asegura que el efecto se active cada vez que formik cambie

  let renderInputs = null;

  if (formik.values.backupType !== '') {
    switch (formik.values.backupType) {
      case 1:
        renderInputs = (
          <div className="row">
            <div className="col-md-4">
              <FormGroup className="mt-3" controlId="formDatabaseName">
                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Nombre Base de Datos</FormLabel>
                <FormControl type="text"
                  autoComplete='off'
                  name="databaseName"
                  value={formik.values.databaseName || ''}
                  onChange={formik.handleChange}
                  className={verifyClass('databaseName')}
                />
                {showErrors('databaseName')}
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup className="mt-3" controlId="formDatabaseUser">
                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Usuario Base de Datos</FormLabel>
                <FormControl type="text"
                  autoComplete='off'
                  name="databaseUser"
                  value={formik.values.databaseUser || ''}
                  onChange={formik.handleChange}
                  className={verifyClass('databaseUser')}
                />
                {showErrors('databaseUser')}
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup className="mt-3" controlId="formDatabasePassword">
                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Contraseña Base de Datos</FormLabel>
                <InputGroup className="mb-3">
                  <FormControl type={showPass ? 'text' : 'password'}
                    autoComplete='off'
                    name="databasePassword"
                    value={formik.values.databasePassword || ''}
                    onChange={formik.handleChange}
                    className={verifyClass('databasePassword')}
                  />
                  <Button variant="light" onClick={clickHandler}>
                    {showPass ? <KTSVG path="/media/icons/duotune/general/gen071.svg" className="svg-icon-2 svg-icon-success me-1" /> : 
                    <KTSVG path="/media/icons/duotune/general/gen072.svg" className="svg-icon-2 svg-icon-success me-1" />}
                  </Button>
                </InputGroup>
                {showErrors('databasePassword')}
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className="mt-3" controlId="formDatabaseType">
                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Tipo de Base de Datos</FormLabel>
                <CustomSelect
                  defaultValue={databaseTypes.find((databaseType: any) => databaseType.value === formik.values.databaseType) || ''}
                  onChangeSingle={(selected: any) => { formik.setFieldValue('databaseType', selected.value) }}
                  options={databaseTypes} />
                {showErrors('databaseType')}
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className="mt-3" controlId="formContainerName">
                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Nombre del Contenedor</FormLabel>
                <FormControl type="text"
                  autoComplete='off'
                  name="containerName"
                  value={formik.values.containerName || ''}
                  onChange={formik.handleChange}
                  className={verifyClass('containerName')}
                />
                {showErrors('containerName')}
              </FormGroup>
            </div>
          </div>
        );
        break;
      case 2:
        renderInputs = (
          <div className="row">
            <div className="col-md-12">
              <FormGroup className="mt-3" controlId="formDirNamePathServer">
                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Ruta de la carpeta a copiar</FormLabel>
                <FormControl type="text"
                  autoComplete='off'
                  name="dirnamePath"
                  value={formik.values.dirnamePath || ''}
                  onChange={formik.handleChange}
                  className={verifyClass('dirnamePath')}
                />
                {showErrors('dirnamePath')}
              </FormGroup>
            </div>
          </div>
        );
        break;
      default:
        renderInputs = null;
    }
  }

  return (
    <div>
      {renderInputs}
    </div>
  );

};

export default RenderBackupTypeField;