import * as React from 'react';
import CardWithTabs from '../../../../components/Cards/CardWithTabs';
import { ProjectService } from '../../../../services/project/projectService';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { FiltersProvider } from '../../../../components/filters/FiltersProvider';
import { ExecutionCard } from '../../../../components/Cards/ExecutionsCard';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveTab, setActiveTab } from '../../../../redux/activeTabSlice';
import { RootState } from '../../../../redux/store';

export interface IProjectViewBackupsProps {
}

export default function ProjectViewBackups(props: IProjectViewBackupsProps) {
    
    const { id = '' } = useParams<{ id: string }>();
    const ACTIVE_TAB_SECTION_BACKUPS = `project_view_backups_${id}`;

    const dispatch = useDispatch();

    const fetchBackups = React.useCallback(async () => {
        const projectService = new ProjectService();
        const response = await projectService.getProjectBackupSettings(id as string);
        return response.getResponseData() as any;
    }, [id]);

    const [backups] = useFetch(fetchBackups);

    const activeTabBackups = useSelector((state: RootState) => state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION_BACKUPS));

    return (
        <React.Fragment>
            {
                backups && backups.length > 0 ? (
                    <CardWithTabs
                        tabs={
                            backups?.map((backup: any, key: number) => {
                                return {
                                    id: backup.id + key,
                                    label: backup.name,
                                    chilldren: (
                                        <FiltersProvider defaultFilterFilters={{ projectId: id, search_array: '' }}>
                                            <ExecutionCard id={backup.id} />
                                        </FiltersProvider>
                                    )
                                }
                            })
                        }
                        //@ts-ignore
                        defaultActiveTab={activeTabBackups?.activeTab || null}
                        onActiveTab={(tab: string) => {
                            dispatch(setActiveTab({ section: ACTIVE_TAB_SECTION_BACKUPS, activeTab: tab }));
                        }}></CardWithTabs>) : (
                    <div className="text-center mt-5">
                        <h3>No hay backups asociados a este proyecto</h3>
                    </div>
                )
            }
        </React.Fragment>
    );
}
