
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface SidebarState {
  isVisible: boolean;
}

const initialState: SidebarState = {
  isVisible: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const { setSidebarVisibility } = sidebarSlice.actions;
//@ts-ignore
export const selectSidebarVisibility = (state: RootState) => state.sidebar.isVisible;
export default sidebarSlice.reducer;