import { ReactNode } from "react";

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
    let timeout: string | number | NodeJS.Timeout | undefined;
  
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        // @ts-ignore
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
};

export type WithChildren = {
  children?: ReactNode;
}

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}
export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname