import { RestServiceConnection } from "../restServiceConnection";

/**
 * @ES Endpoint de permisos
 * @EN Permission endpoint
 */

const PERMISSIONS_ENDPOINT = "/permissions";

export class PermissionService extends RestServiceConnection {

  /**
   * 
   * @ES Obtiene todos los permisos
   * @EN Get all permissions
   * 
   * @returns API response
   */

  getPermissions = async () => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: PERMISSIONS_ENDPOINT + "/get-all",
      },
      true
    );
    return this;
  };
}
