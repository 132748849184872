import { FC, useCallback, useContext, useState } from "react";
import _ from "lodash";
import CustomSelect from "../../../components/form/CustomSelect";
import { Companies, CompaniesApiResponse } from "../../../types/company-type";
import { CompanyService } from "../../../services/company/companyService";
import { UserService } from "../../../services/user/userService";
import useFetch from "../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Button, Form } from "react-bootstrap";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

type Props = {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number, hardReset: boolean) => void
  filters: any
}

const statuses = [  
  { value: '', label: 'Todos'},
  { value: 1, label: 'Activo' },
  { value: 0, label: 'Disactivado' },
]

const RoleListFilters: FC<Props> = ({ updateFilters, filters , resetFilters}) => {

  const user = useSelector((state: RootState) => state.auth.user);

  const [resetKey, setResetKey] = useState("filters");

  const {userCan} = useContext(PrivilegeContext)

  const fetchCompanies = useCallback(async () => {

    const companyService = new CompanyService();
    if (!userCan('list','companies')) return;
    const response = await companyService.getCompanies();
    if (response.getResponseData() == undefined) {
        return ((await new UserService().getUserById(user?.id || '')).getResponseData())
    }
    return response.getResponseData() as CompaniesApiResponse;
  }, []);

  const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

  const getCompaniesList = () => {
    if (companies as Companies) {
        return companies.companies?.map((company: { id: any; name: any; }) => {
            return {
                value: company.id,
                label: company.name,
            }
        })
    }
    return [];
  }

  return (

    <div className="column" key={resetKey}>
      <div className="row">
        <Form.Label className="fs-4 fw-bolder tex-dark">Estado</Form.Label>
        <CustomSelect 
          options={statuses} 
          defaultValue={statuses.find(status => filters.filter_filters?.active?.toString() == status.value) || ''}
          onChangeSingle={(selected: any) => updateFilters({active: selected.value})}
        />
      </div>
      {userCan('list','companies') ??
          (<div className={`row mt-5`}>
            <Form.Label className="fs-4 fw-bolder tex-dark">Organización</Form.Label>
            <CustomSelect options={getCompaniesList()} defaultValue={getCompaniesList().find((company : any) => filters.filter_filters?.company == company.value)|| ''}
               onChangeSingle={(selected: any) =>{updateFilters({companyId: selected.value})}}/>
          </div>)
      }
      <div className='col-12 d-flex justify-content-center'>
        <Button
        className='mt-5 bg-info fw-bold'
        onClick={() => {
          resetFilters(50,true)
          setResetKey(resetKey == "filters" ? "resetFilters" : "filters")
        }}
        >
            Resetear
        </Button>
      </div>
    </div>
  )
}


export default RoleListFilters;