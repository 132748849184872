import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CompanyService } from "../../../services/company/companyService";
import { KTSVG } from "../../../components/Icon/KTSVG";
import CompanyForm from "../form/CompanyForm";

const CompanyCreate: FC = () => {

	const navigate = useNavigate();

	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		const formData = new FormData();
		
		for(let value in values) {
			if(values[value]){
				formData.append(value, values[value]);
			}else{
				formData.append(value, '');
			}
		}
		
		let hasImg = formData.get("logo") ? true : false;

		try {
			setLoading(true)
			let response = await (await (new CompanyService()).createCompany(formData, hasImg)).getResponseData();
			if(response.success) {
				toast.success(response.message);
				navigate(-1);
			} else {
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al crear Organización');
		}finally{
			setLoading(false);
		}
		
	}

	return (
		<Fragment>
			<div className="container-fluid">
				<div className={`card mt-3`}>
				<div className="card-header bg-success mb-3">
					<div className="card-title flex-row">
					<span className='card-label fw-bolder text-light fw-bold fs-1 d-flex align-items-end'>
						<KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-1 p-0 text-light" />
					</span>
					<span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
						NUEVA ORGANIZACIÓN
					</span>
					</div>
				</div>
				<div className="card-body pt-3">
					<CompanyForm submit={handleCreation} isLoading={loading}/>
				</div>
				</div>
			</div>
		</Fragment>
	)
}

export default CompanyCreate;