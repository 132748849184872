import { Children, FC, Fragment, useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HeaderLayout from "./HeaderLayout";
import { Sidebar } from "./sidebar";
import { MenuComponent } from "../../utils/MenuComponent";

interface AppLayoutProps {
  children?: React.ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      setTimeout(() => {
        MenuComponent.bootstrap();
      }, 500);
      return
    }


  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Fragment>
        <div
          id="kt_app_body"
          data-kt-app-layout="dark-sidebar"
          data-kt-app-header-fixed="true"
          data-kt-app-header-minimize="off"
          data-kt-app-sidebar-push-header="true" 
          data-kt-app-sidebar-push-toolbar="true" 
          data-kt-app-sidebar-push-footer="true"
          data-kt-app-sidebar-hoverable="true"
          data-kt-app-sidebar-enabled="true"
          data-kt-app-sidebar-fixed="true"
          data-kt-app-toolbar-enabled="true"
          data-kt-app-sidebar-minimize="off"
          className="app-default"
        >
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <HeaderLayout />
                <div className='d-flex flex-column flex-column-fluid'>
                  <Outlet/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default AppLayout;
