import React, { useState } from "react";
import * as Yup from 'yup';
import clsx from 'clsx';
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { KTSVG } from "../../components/Icon/KTSVG";

interface LoginFormProps {
    isLoading: boolean;
    submit: Function;
    errorsBool: boolean;
}


const loginSchema = Yup.object().shape({
    loginEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    loginPassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
})

interface ILoginForm {
    loginEmail: string;
    loginPassword: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({isLoading = false, submit, errorsBool}) => {

    const [showPass, setShowPass] = useState(false);

    const formik = useFormik({
		enableReinitialize: true,
		validationSchema: loginSchema,
        initialValues: {
			loginEmail: '',
			loginPassword: '',
		},
		onSubmit: (values) => {
            submit(values.loginEmail, values.loginPassword);
		},
	});

    const verifyClass = (inputFieldID: keyof ILoginForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof ILoginForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }
    
    const clickHandler = () => {
        setShowPass(!showPass);
    }

    return(
        <form onSubmit={formik.handleSubmit} className='row g-4'>
            <div className='col-12'>
                <Form.Group className="fv-row mb-8" controlId="formBasicEmail">
                    <Form.Label className='form-label fs-6 fw-bolder text-dark'>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email"
                    className={verifyClass('loginEmail')}
                    name='loginEmail'
                    autoComplete='off'
                    onChange={formik.handleChange}
                    />
                    {showErrors('loginEmail')}
                </Form.Group>
                <Form.Group className="mt-3" controlId="formBasicPassword">
                    <Form.Label className='form-label fs-6 fw-bolder text-dark'>Contraseña</Form.Label>
                    <InputGroup className="mb-3">
                        <FormControl
                            type={showPass ? 'text' : 'password'}
                            name="loginPassword"
                            value={formik.values.loginPassword}
                            onChange={formik.handleChange}
                            className={verifyClass('loginPassword')}
                            placeholder="Password"
                            aria-label="password"
                            aria-describedby="password-input"
                        />
                        <Button variant="light" onClick={clickHandler}>
                            {showPass ? <KTSVG path="/media/icons/duotune/general/gen071.svg" className="svg-icon-2 svg-icon-success me-1" /> : 
                            <KTSVG path="/media/icons/duotune/general/gen072.svg" className="svg-icon-2 svg-icon-success me-1" />}
                        </Button>
                    </InputGroup>
                    {showErrors('loginPassword')}
                </Form.Group>
            </div>
            <div className='d-flex flex-stack flex-wrap justify-content-end gap-3 fs-base fw-semibold mb-3'>
                <Link to='/recover-password' className='link-primary fw-bold fs-6'>
                    ¿Has olvidado tu contraseña?
                </Link>
            </div>
            <div className='col-12'>
            <Button variant='primary' className='w-100 fs-6 fw-bold' type='submit' disabled={isLoading}>
                {isLoading ? (
                    <>
                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </>
                ) : (
                    'Iniciar Sesión'
                )}
            </Button>
            </div>
        </form>
    ) 
}