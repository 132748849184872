import { UserService } from "../../services/user/userService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import SpinnerCustom from "../../components/Spinner/SpinnerCustom";
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import moment from "moment";
import AsyncImg from "../../components/form/AsyncImg";
import { useFormik } from "formik";
import * as yup from "yup";
import { KTSVG } from "../../components/Icon/KTSVG";

interface IProfileForm {
    userId: string
    password: string;
    passwordConfirm: string;
}

const profileSchema = yup.object({
        password: yup.string().required('Contraseña Obligatoria').min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres'),
        passwordConfirm: yup.string().required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
});

const ProfileUserPage = () => {

    const [user, setUser] = useState<any>({});

    const [isLoading, setLoading] = useState<boolean>(false);

    const getUser = async () => {
        const userService = new UserService();
        const response = (await (userService.me())).getResponseData();
        if (response.success) {
            setUser(response.data);
        } else {
            toast.error(response.message);
        }
    }

    const changePassword = async (values: IProfileForm) => {
        setLoading(true);

		try {
			setLoading(true)
            values.userId = user?.id;
            const userService = new UserService();
            const response = (await (userService.changePassword(values))).getResponseData();
            if (response.success) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } catch (error: any) {
			toast.error('Error al cambiar contraseña');  
		}finally{
			setLoading(false);
            formik.resetForm();
		}
    }

    useEffect(() => {
        getUser();
    }, []);

    const profileInitialValues: IProfileForm = {
        userId: '',
        password: '',
        passwordConfirm: '',
    };

    const formik = useFormik({
        initialValues: profileInitialValues,
        validationSchema: profileSchema,
        onSubmit: values => {          
            changePassword(values)
        },
    });

    const verifyClass = (inputFieldID: keyof IProfileForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof IProfileForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }

    return (
        <>
            {user ? (
                <div className="container-fluid">
                    <div className={`card mt-3`}>
                        <div className="card-body pt-3">
                            <div className="separator my-2"></div>
                            <div className="row mt-3">
                                <div className="col-3">
                                    <div className="d-flex justify-content-center" key={user.profileImg?.id}>
                                        <AsyncImg transparent styles="max-200px" src={user.profileImg?.id || ""} type={"preview"}></AsyncImg>
                                    </div>
                                </div>
                                <div className="col-9 row">
                                    <div className="row">
                                        <div className="col-4">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">NOMBRE:</FormLabel>
                                                <div className="ms-2">{user?.name}</div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-4">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">APELLIDOS:</FormLabel>
                                                <div className="ms-2">{user?.lastName || "-"}</div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-4">
                                            <FormGroup className="ms-4">
                                                <FormLabel className="form-label fw-bold text-muted">EMAIL:</FormLabel>
                                                <div className="ms-2">{user?.email}</div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="row mt-2">
                                            <div className="col-4">
                                                <FormGroup className="ms-4">
                                                    <FormLabel className="form-label fw-bold text-muted">ULTIMO LOGIN:</FormLabel>
                                                    <div>
                                                        <span className={'text-muted ms-2'}>{moment(user.lastLogin?.date).format('DD-MM-YYYY HH:mm')}</span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-4">
                                                <FormGroup className="ms-4">
                                                    <FormLabel className="form-label fw-bold text-muted">CREACION:</FormLabel>
                                                    <div>
                                                        <span className={'text-muted ms-2'}>{moment(user.createdAt?.date).format('DD-MM-YYYY HH:mm')}</span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-4">
                                                <FormGroup className="ms-4">
                                                    <FormLabel className="form-label fw-bold text-muted">ULT. EDICION:</FormLabel>
                                                    <div>
                                                        <span className={'text-muted ms-2'}>{moment(user.updateAt?.date).format('DD-MM-YYYY HH:mm')}</span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator my-2"></div>
                        </div>
                    </div>
                    <div className={`card mt-3`}>
                        <div className="card-header mb-3">
						<div className="card-title flex-row">
						<span className='card-label fw-bolder fw-bold fs-2 align-text-bottom'>
							CAMBIO DE CONTRASEÑA
						</span>
						</div>
					    </div>
					    <div className="card-body pt-3">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <FormGroup className="mt-3" controlId="formPassword">
                                            <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Contraseña</FormLabel>
                                            <FormControl type="password"
                                                autoComplete='off'
                                                value={formik.values.password}
                                                name="password"
                                                onChange={formik.handleChange}
                                                className={verifyClass('password')}
                                            />
                                            {showErrors('password')}
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup className="mt-3" controlId="formRepeatPassword">
                                            <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Confirmar contraseña</FormLabel>
                                            <FormControl type="password"
                                                autoComplete='off'
                                                value={formik.values.passwordConfirm}
                                                name="passwordConfirm"
                                                onChange={formik.handleChange}
                                                className={verifyClass('passwordConfirm')}
                                            />
                                            {showErrors('passwordConfirm')}
                                        </FormGroup>
                                    </div>
                                    <div className='col-4 d-flex justify-content-center align-items-end'>
                                        <Button variant='secondary' className='fs-6 fw-bold mt-10' type='submit' disabled={isLoading}>
                                            {isLoading  ? null : <KTSVG path="/media/icons/duotune/general/gen070.svg" className="svg-icon-2 svg-icon-success me-1" />}
                                            {isLoading ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : 'Cambiar Contraseña'}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <SpinnerCustom/>
            )}
        </>
    );
}

export default ProfileUserPage; 