import { FC } from "react";

interface SpinnerCustomProps {
  className?: string,
}

const SpinnerCustom:FC<SpinnerCustomProps> = ( className ) => {
 return (
   <div className={"small-spinner-wrapper" + (className ? " " + className.className : "")}>
     <svg width="150" height="40">
       <text x="50%" y="65%" dy=".35em" textAnchor="middle">
        128K<tspan className="blue-spinner1">U</tspan><tspan className="blue-spinner2">P</tspan>
       </text>
     </svg>
   </div>
 );
};


export default SpinnerCustom;