import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { TablesWidget9 } from "../../../components/widgets";
import { useFiltersPR } from "../../../components/filters/FiltersProvider";
import { UsersApiResponse } from "../../../types/user-type";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { RoleService } from "../../../services/auth/roleService";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import PageContentTitle from "../../../components/Page/PageData";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import { CustomDrawer } from "../../../components/Drawer/CustomDrawer";
import RoleListFilters from "../filters/RoleListFilters";
import RoleCreateModal from "../create/RoleCreateModal";
import { handleConfirmationAlert } from "../../../components/SweetAlert/ConfirmationAlert";
import StatusCheck from "../../../components/form/StatusCheck";

const breadcrumbs = [
  {
    title: 'SUPERADMIN',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'ROLE LIST',
    path: 'admin/users/list',
    isSeparator: true,
    isActive: true,
  },
]

export const RoleList: FC = () => {

  const navigate = useNavigate();

  const {userCan} = useContext(PrivilegeContext)

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [roleCreationModal, setRoleCreationModal] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);

  const actions = [
    {
      show: userCan('create','roles'),
      title: "Crear Rol",
      icon : '/media/icons/duotune/general/gen041.svg',
      className: 'btn-success text-light me-3 fw-bold',
      colorIcon: 'text-light',
      callback: () => {
        if(userCan('create','roles')) setRoleCreationModal(true);
      }
    },
    {
      title: "Filtros",
      icon : '/media/icons/duotune/general/gen031.svg',
      className: 'btn-info me-3 fw-bold',
      callback: () => {
        setOpenFilterDrawer(true);
      }
    }
  ]

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchRoles = useCallback(async () => {
    const roleService = new RoleService();
    const response = await roleService.getRoles(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchRoles);

  const handleSearch = (search: string) => {
    updateFilters({ search_array : search });
  }

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const toggleRoleStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new RoleService().toggleRoleStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success(`Rol ${status ? "activado" : "desactivado"} correctamente`);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await (await (new RoleService()).deleteRole(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Rol eliminado correctamente");
        }, 500);
      } else {
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  return (
    <>
      {
        data ? (
          <>
              <CustomDrawer title="Roles" anchor="right" open={openFilterDrawer} onClose={handleClose}>
                <RoleListFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
              </CustomDrawer>
              <PageContentTitle title='Roles' breadcrumbs={breadcrumbs} actions={actions}/>
              <TablesWidget9 className="card-primary m-3" title="Roles"
                subtitle="List of roles"
                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                data={data?.roles ? data.roles : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: (data as UsersApiResponse) ? data.lastPage : 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={[
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => { 
                      return (
                        <div className="text-center">
                          {element.name}
                        </div>
                      )
                    },
                  },
                  {
                    name: "Organización",
                    keyValue: "company",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      if (element.company) {
                        return (
                        <div className="d-flex justify-content-center">
                          {element?.company?.name || "-"}
                        </div>
                        )
                      } else return(<></>);
                    }
                  },
                  {
                    name: "Descripción",
                    keyValue: "description",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div>
                          {element.description || "-"}
                        </div>
                      );
                    }
                  },
                  {
                    name: "Estado", keyValue: 'enabled', className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={"text-center"} key={element.id}>
                        <StatusCheck
                          disabled={changingStatus.includes(element.id)}
                          itemId={element.id}
                          status={element.active}
                          change={toggleRoleStatus}
                        />
                      </div>
                      );
                    }
                  },
                  { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]
              }
                actions={
                  [
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-success',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: 'text-primary',
                      description: "Editar rol",
                      callback: (item: any) => {
                        if(userCan('edit','roles',true)) navigate(`edit/${item.id}`);
                      },
                    },

                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: 'text-danger',
                      description: "Eliminar rol",
                      callback: (item: any) => {
                        if (userCan('edit','roles',true)) {
                          handleConfirmationAlert({
                            title: "Eliminar Rol",
                            text: "¿Está seguro que desea eliminar este rol? Borrará todos los datos asociados a este rol",
                            icon: "warning",
                            onConfirm: () => {
                              handleDelete(item.id);
                            },
                          });
                        }
                      },
                    },
                  ]
                }
              ></TablesWidget9>
              {
                roleCreationModal && (
                  <RoleCreateModal
                    isOpen={roleCreationModal}
                    setIsOpen={setRoleCreationModal}
                    handleSuccessCreation={() => {
                      toast.success("Rol creado correctamente");
                      setRoleCreationModal(false);
                      refetch()
                  }}
                  />)
              }
          </>
        ) : (
          <>
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <SpinnerCustom/>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
}