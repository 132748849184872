import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import { WithChildren, checkIsActive } from '../../../../utils/utils'
import { KTSVG } from '../../../../components/Icon/KTSVG'
type Props = {
  to: string
  title: string
  icon?: any
  hasBullet?: boolean
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={`/media/icons/duotune/${icon}`} className="svg-icon-muted svg-icon-2hx" />
          </span>
        )}
        <span className='menu-title fw-bold'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
