import { FC, useCallback, useContext, useEffect, useState } from "react";
import _, { get } from "lodash";
import CustomSelect from "../../../components/form/CustomSelect";
import { Companies, CompaniesApiResponse } from "../../../types/company-type";
import { CompanyService } from "../../../services/company/companyService";
import { UserService } from "../../../services/user/userService";
import useFetch from "../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Button, Form, Spinner } from "react-bootstrap";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { ProjectTypeService } from "../../../services/project/projectTypeService";
import { CustomDateRangePicker, RangeDate } from "../../../components/form/CustomDateRangePicker";

type Props = {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number, hardReset: boolean) => void
  filters: any
}

const statuses = [  
  { value: '', label: 'Todos'},
  { value: 1, label: 'Activo' },
  { value: 0, label: 'Disactivado' },
]

const ProjectListFilters: FC<Props> = ({ updateFilters, filters , resetFilters}) => {

  const user = useSelector((state: RootState) => state.auth.user);

  const [userList, setUserList] = useState<any>(null);

  const [companyList, setCompanyList] = useState<any>(null);

  const [projectTypeList, setProjectTypeList] = useState<any>(null);

  const [resetKey, setResetKey] = useState("filters");

  const {userCan} = useContext(PrivilegeContext)

  const fetchCompanies = useCallback(async () => {
    const companyService = new CompanyService();
    if (!userCan('list','companies')) return;
    const response = await companyService.getCompanies();
    return response.getResponseData() as CompaniesApiResponse;
  }, []);

  const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

  const getCompaniesList = useCallback(() => {
    if (companies as Companies) {
        setCompanyList(companies.companies?.map((company: { id: any; name: any; }) => {
            return {
                value: company.id,
                label: company.name,
            }
        }))
    }
    return [];
  }, [companies])

  const fetchProjectTypes = useCallback(async () => {
    const projectTypeService = new ProjectTypeService();
    const response = await projectTypeService.getProjectTypes();
    return response.getResponseData() as CompaniesApiResponse;
  }, []);

  const [projectTypes, fetchingProjectTypes, projectTypesError] = useFetch(fetchProjectTypes);

  const getProjectTypesList = useCallback(() => {
    if (projectTypes as any) {
        setProjectTypeList(projectTypes.projectTypes?.map((projectType: { id: any; name: any; }) => {
            return {
                value: projectType.id,
                label: projectType.name,
            }
        }))
    }
    return [];
  }, [projectTypes])

  const fetchUsers = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUsers({});
    return response.getResponseData() as CompaniesApiResponse;
  }, []);

  const [users, fetchingUsers, UsersError] = useFetch(fetchUsers);

  const getUsersList = useCallback(() => {
    if (users as any) {
        setUserList(users.users?.map((user: { id: any; name: any; }) => {
            return {
                value: user.id,
                label: user.name,
            }
        }))
    }
    return [];
  }, [users])

  const onChangeDate = (range: RangeDate) => {
    if (range.startDate && range.endDate) {
        updateFilters({
            between_dates: {
              startDate: range.startDate,
              endDate: range.endDate
            }
        });
    }
  }

  useEffect(() => {
    getCompaniesList();
    getProjectTypesList();
    getUsersList();
  }, [companies, projectTypes, users])

  return (
    <div className="column" key={resetKey}>
      <div className={`row`}>
        <Form.Label className="fs-4 fw-bolder tex-dark">Fecha de Creación</Form.Label>
        <CustomDateRangePicker onChangeDate={onChangeDate}/>
      </div>
      <div className="row mt-5">
        <Form.Label className="fs-4 fw-bolder tex-dark">Estado</Form.Label>
        <CustomSelect 
          options={statuses} 
          defaultValue={statuses.find(status => filters.filter_filters?.active?.toString() == status.value) || ''}
          onChangeSingle={(selected: any) => updateFilters({active: selected.value})}
        />
      </div>
      {userCan('list','companies') ??
          (<div className={`row mt-5 ${userCan('list','companies') ? '' : 'd-none'}`}>
            <Form.Label className="fs-4 fw-bolder tex-dark">Organización</Form.Label>
            {companyList ? (
                <CustomSelect options={companyList} defaultValue={companyList.find((company : any) => filters.filter_filters?.company == company.value)|| ''}
                   onChangeSingle={(selected: any) =>{updateFilters({companyId: selected.value})}}/>
            ) : <Spinner animation="border" variant="success" />}
          </div>)
      }
      <div className={`row mt-5`}>
        <Form.Label className="fs-4 fw-bolder tex-dark">Tipos de Proyecto</Form.Label>
        {projectTypeList ? (
          <CustomSelect options={projectTypeList} defaultValue={projectTypeList.find((p: any) => p.value == filters.filter_filters?.projectTypeId)}
          onChangeSingle={(selected: any) =>{updateFilters({projectTypeId: selected.value})}}/>
        ) : <Spinner animation="border" variant="success" />}
      </div>
      <div className={`row mt-5`}>
        <Form.Label className="fs-4 fw-bolder tex-dark">Miembros</Form.Label>
        {userList ? (
            <CustomSelect options={userList} defaultValue={userList.filter((u: any) => filters.filter_filters?.membersIds?.includes(u.value))}
              is_multi={true} onChange={(selected: any) => { updateFilters({membersIds: selected.map((s: any) => s.value)})}}/>
        ) : <Spinner animation="border" variant="success" />}
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <Button
        className='mt-5 bg-info fw-bold'
        onClick={() => {
          resetFilters(10,true)
          setResetKey(resetKey == "filters" ? "resetFilters" : "filters")
        }}
        >
            Resetear
        </Button>
      </div>
    </div>
  )
}


export default ProjectListFilters;