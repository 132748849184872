import { Fragment } from "react"
import { FiltersProvider } from "../../components/filters/FiltersProvider"
import { ProjectList } from "./list"
import PageLayoutData from "../../components/Page/PageLayoutData"

const ProjectsListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <ProjectList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default ProjectsListWrapper