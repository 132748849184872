import React, { useEffect, useState } from 'react'
import { KTSVG } from '../Icon/KTSVG'

type Props = {
    placeholder: string,
    onSearch(value: string): void
    className?: string
}

const CustomSearchInput: React.FC<Props> = ({ placeholder, onSearch, className }) => {

    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(()=> {
        const delaySearch = setTimeout(() => {
            onSearch(searchValue);
        }, 1000);

        return () => clearTimeout(delaySearch);
    }, [searchValue])


    return (
        <div className="app-search">
            <div className="position-relative">
                <span><KTSVG path={'/media/icons/duotune/general/gen004.svg'} className='svg-icon-2 text-info' /></span>
                <input className={`form-control form-control-solid ${className}`} placeholder={placeholder} onChange={(e) => setSearchValue(e.target.value)}/>
            </div>
        </div>
    )
}

CustomSearchInput.defaultProps = {

}

export default CustomSearchInput;

