import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { TablesWidget9 } from "../../../components/widgets";
import { useFiltersPR } from "../../../components/filters/FiltersProvider";
import { UsersApiResponse } from "../../../types/user-type";
import useFetch from "../../../hooks/useFetch";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { CompanyService } from "../../../services/company/companyService";
import moment from "moment";
import PageContentTitle from "../../../components/Page/PageData";
import { CustomDrawer } from "../../../components/Drawer/CustomDrawer";
import CompanyListFilters from "../filters/CompanyListFilters";
import AsyncImg from "../../../components/form/AsyncImg";
import { handleConfirmationAlert } from "../../../components/SweetAlert/ConfirmationAlert";
import StatusCheck from "../../../components/form/StatusCheck";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";

const breadcrumbs = [
  {
    title: 'SUPERADMIN',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'COMPANY LIST',
    path: 'admin/company/list',
    isSeparator: true,
    isActive: true,
  },
]

export const CompanyList: FC = () => {

  const navigate = useNavigate();

  const {userCan} = useContext(PrivilegeContext)

  const actions = [
    {
      show: userCan('create','companies'),
      title: "Crear Organización",
      icon : '/media/icons/duotune/general/gen041.svg',
      colorIcon: "text-light",
      className: 'btn-success me-3 fw-bold text-light',
      callback: () => {
        if(userCan('create','companies')) navigate('create');
      }
    },
    {
      title: "Filtros",
      icon : '/media/icons/duotune/general/gen031.svg',
      className: 'btn-info me-3 fw-bold',
      callback: () => {
        setOpenFilterDrawer(true);
      }
    }
  ]

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchCompanies = useCallback(async () => {
    const companyService = new CompanyService();
    const response = await companyService.getCompanies(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchCompanies);

  const handleSearch = (search: string) => {
    updateFilters({ search_array : search });
  }

	const deleteCompany = async (id: string) => {
		let response = (await new CompanyService().deleteCompany(id)).getResponseData();
		if (response.success) {
			toast.success('Compañia eliminada correctamente');
			refetch();
		}
	}

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

	const toggleCompanyStatus = async (
		id: string,
		status: boolean,
		toggleStatus: Function
	) => {
		try {
			setChangingStatus([...changingStatus, id]);
			let response = await (
				await new CompanyService().toggleCompanyStatus(id, status)
			).getResponseData();
			if (response.success) {
				setChangingStatus(changingStatus.filter((item) => item !== id));
				toggleStatus(status);
        toast.success(`Compañia ${status ? "activada" : "desactivada"} correctamente`);
			} else {
				setChangingStatus(changingStatus.filter((item) => item !== id));
			}
		} catch (error: any) {
			setChangingStatus(changingStatus.filter((item) => item !== id));
		}
	};

  return (
    <>
      {
        data ? (
            <>
              <CustomDrawer title="Organizaciones" anchor="right" open={openFilterDrawer} onClose={handleClose}>
                <CompanyListFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
              </CustomDrawer>
              <PageContentTitle title='Organizaciones' breadcrumbs={breadcrumbs} actions={actions}/>
              <TablesWidget9 className="card-primary m-3" title="Companies"
                subtitle="List of companies"
                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                data={data?.companies ? data.companies : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: (data as UsersApiResponse) ? data.lastPage : 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={
                  [
                    {
                      name: "",
                      keyValue: "name",
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center" key={element.logo?.id}>
                            <Link to={`/users/${element.id}/profile`}>
                              <AsyncImg transparent src={element.logo?.id || ""} type={"avatar"}></AsyncImg>
                            </Link>
                          </div>
                        );
                      },
                    },
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "CIF",
                      keyValue: "cif",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span>{element.cif}</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Dirección",
                      keyValue: "address",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span>{element.address}</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Fecha de creación",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                          </div>
                        )
                      },
                    },
                    {
                      name: "Última Modificación",
                      keyValue: "updatedAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (item: any) => {
                        return <div className="text-center text-muted">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                      }
                    },     
                    {
                      name: "Estado", keyValue: 'enabled', className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={"text-center"} key={element.id}>
                          <StatusCheck
                            disabled={changingStatus.includes(element.id)}
                            itemId={element.id}
                            status={element.active}
                            change={toggleCompanyStatus}
                          />
                        </div>
                        );
                      }
                    }, 
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]
                }
                actions={
                  [
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-black',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-black ${!userCan('edit','companies') ? 'd-none' : ''}`,
                      description: "Editar perfil del organización",
                      callback: (item: any) => {
                        if (userCan('edit', 'companies')) navigate(`edit/${item.id}`)
                      },
                    },
      
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${!userCan('delete','companies') ? 'd-none' : ''}`,
                      description: "Eliminar organización",
                      callback: (item: any) => {
                        if (userCan("delete", "companies")) {
                          handleConfirmationAlert({
                            title: "Eliminar Compañía",
                            text: "¿Está seguro que desea eliminar esta compañía? Borrará todos los datos asociados a esta compañía",
                            icon: "warning",
                            onConfirm: () => {
                              deleteCompany(item.id);
                            },
                          });
                        }
                      },
                    },
                  ]
                }
              ></TablesWidget9>
            </>
        ) : (
          <>
            <SpinnerCustom/>
          </>
        )
      }
    </>
  );
}