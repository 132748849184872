import * as React from 'react';
import { KTSVG } from '../Icon/KTSVG';
import { useLayout } from '../../pages/layout/LayoutProvider';

export interface IPageActionButtonsProps {
}

export default function PageActionButtons (props: IPageActionButtonsProps) {

    const {actionButtons} = useLayout();

  return (
    <>
        <div className="toolbar-buttons d-flex align-items-stretch overflow-auto pt-3 pt-lg-0">
            {actionButtons && actionButtons}
        </div>
    </>
  );
}
