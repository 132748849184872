import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { logout } from '../../redux/authSlice';
import { KTSVG } from "../../components/Icon/KTSVG";
import AsyncImg from "../../components/form/AsyncImg";
import { selectSidebarVisibility, setSidebarVisibility } from "../../redux/sidebarSlice";
import { useNavigate } from "react-router-dom";
import { useLayout } from "./LayoutProvider";
import { PageBreadCrumb } from "../../components/Page/PageBreadCrumb";
import PageActionButtons from "../../components/Page/PageActionButtons";

const HeaderLayout = () => {

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    const sidebarVisible = useSelector(selectSidebarVisibility);

    const user = useSelector((state: RootState) => state.auth.user);

    const handleSidebarToggle = () => {
        // Despacha la acción para cambiar el estado del sidebar
        dispatch(setSidebarVisibility(!sidebarVisible));
    };

    const layoutData = useLayout();

    return (
        <>
            {/* Header for NO responsive app */}
            <div id="kt_header" className="app-header">
                <div
                    className="app-container container-fluid d-flex align-items-stretch justify-content-between"
                    id="kt_header_container"
                >
                    <div
                        className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
                        title="Show sidebar menu"
                    >
                        <div
                            className="btn btn-icon btn-active-color-primary w-35px h-35px"
                            id="kt_sidebar_mobile_toggle" onClick={(event) => {
                                event.stopPropagation();
                                handleSidebarToggle();
                            }}
                        >
                            <KTSVG path="/media/icons/duotune/abstract/abs015.svg" className='svg-icon-1' />
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <a href="../../demo1/dist/index.html" className="d-lg-none">
                            <img
                                alt="Logo"
                                src="/media/logos/logo128kUP.png"
                                className="h-60px"
                            />
                        </a>
                    </div>
                    <div
                        className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
                        id="kt_app_header_wrapper"
                    >
                        <div
                            className="app-header-menu app-header-mobile-drawer align-items-stretch"
                            data-kt-drawer="true"
                            data-kt-drawer-name="app-header-menu"
                            data-kt-drawer-activate="{default: true, lg: true}"
                            data-kt-drawer-overlay="true"
                            data-kt-drawer-width="250px"
                            data-kt-drawer-direction="end"
                            data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                            data-kt-swapper="true"
                            data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                            data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                        >
                            <div className="w-100 py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between justify-content-between">
                                <PageBreadCrumb />
                                <PageActionButtons />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>

            {/* Header for responsive app */}
            <div id="kt_header_responsive" className="header align-items-stretch">
                <div className="toolbar d-flex align-items-stretch">
                    <div className=" container-xxl  py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
                        <PageBreadCrumb />

                        <div className="d-flex align-items-stretch overflow-auto pt-3 pt-lg-0">
                            <div className="d-flex">
                                <a href="#" className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
                                    <KTSVG path="/media/icons/duotune/abstract/abs015.svg" className='svg-icon-1' />
                                </a>

                                <div className="d-flex align-items-center">
                                    <a href="/metronic8/demo8/apps/subscriptions/add.html" className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary">
                                        <i className="ki-duotone ki-add-files fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>                        </a>

                                </div>
                                <div className="d-flex align-items-center">
                                    <a href="/metronic8/demo8/apps/file-manager/folders.html" className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary">
                                        <i className="ki-duotone ki-file-up fs-1"><span className="path1"></span><span className="path2"></span></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default HeaderLayout;
