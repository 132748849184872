import React, { Fragment } from "react";
import { toAbsoluteUrl } from "../../utils/utils";
import { KTSVG } from "../../components/Icon/KTSVG";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

type ErrorMessagePageProps = {
  typeError?: string;
}

const ErrorPage: React.FC<ErrorMessagePageProps> = ({typeError}) => {

  const navigate = useNavigate();
 
  return (
    <Fragment>
        <div className="d-flex justify-content-center align-items-center h-100 bg-login">
            <div className="card card-flush w-lg-650px py-5">
                <div className="card-body py-15 py-lg-20 d-flex justify-content-center flex-column">
                    <h1 className="fw-bolder fs-2hx text-gray-900 mb-4 text-center">Oops!</h1>
                    <div className="fw-semibold fs-6 text-gray-500 mb-7 text-center text-muted">We can't find that page.</div>
                    <div className="mb-3 d-flex justify-content-center">
                        <img src={toAbsoluteUrl(`/media/auth/${typeError}-error.png`)} className="mw-100 mh-300px theme-light-show" alt="" />
                    </div>
                    <div className="mb-0 d-flex justify-content-center">
                        <Button variant="primary" className="btn btn-sm btn-info text-center text-light fw-bold fs-3" onClick={() => {navigate('/')}}>
                          <KTSVG path={"/media/icons/duotune/general/gen001.svg"} className="svg-icon-2 svg-icon-success me-2" />
                          Return Home
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  );
};

export default ErrorPage;