import { useFormik } from "formik";
import { LoginContainer } from "./LoginContainer";
import { LoginService } from "../../services/auth/loginService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('La contraseña es obligatoria').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
    repeatPassword: Yup.string().required('Repita la contraseña').oneOf([Yup.ref('password'), ''], 'Las contraseñas no coinciden'),
});

interface ResetPasswordForm {
    password: string;
    repeatPassword: string;
}

const ResetPassword = () => {
    const navigate = useNavigate();

    // Controlar el envío del formulario
    const handleSubmit = async (password: string, repeatPassword: string) => {
        if (password === repeatPassword) {
            const urlString = window.location.href.split('/').pop() || '';
            const token = urlString.substring(urlString.indexOf("token=") + 6);

            try {
                const response = await (new LoginService()).resetPassword(password, repeatPassword, token);

                if (response.data.success) {
                    navigate('/login');
                    setTimeout(() => {
                        toast.success(response.data.message);
                    }, 100);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error('Error al cambiar la contraseña: ' + error);
            }
        } else {
            toast.error('Las contraseñas no coinciden');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => {
            handleSubmit(values.password, values.repeatPassword);
        },
    });

    const verifyClass = (inputFieldID: keyof ResetPasswordForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof ResetPasswordForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }

    return (
        <LoginContainer>
            <form onSubmit={formik.handleSubmit} className='row g-4 max-w-580px' autoComplete="off">

                <div className='col-12'>
                    <FormGroup id='password'>
                        <FormLabel className='form-label fs-6 fw-bolder text-dark'>Nueva Contraseña</FormLabel>
                        <FormControl
                            name="password"
                            type='password'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            className={verifyClass('password')}
                        />
                        {showErrors('password')}
                    </FormGroup>
                </div>
                <div className='col-12'>
                    <FormGroup id='repeatPassword' className='mt-3'>
                        <FormLabel className='form-label fs-6 fw-bolder text-dark'>Repetir Contraseña</FormLabel>
                        <FormControl
                            name="repeatPassword"
                            type='password'
                            value={formik.values.repeatPassword}
                            onChange={formik.handleChange}
                            className={verifyClass('repeatPassword')}
                        />
                        {showErrors('repeatPassword')}
                    </FormGroup>
                </div>
                <div className='col-12 mt-5'>
                    <Button color='primary' className='w-100 fw-bold py-3' type='submit'>
                        Cambiar Contraseña
                    </Button>
                </div>
            </form>
        </LoginContainer>
    );
}

export default ResetPassword;