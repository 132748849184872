import { useFormik } from "formik";
import { FC, Fragment, useEffect, useState } from "react";
import * as yup from "yup";
import { Button, FormControl, FormGroup, FormLabel, Spinner } from "react-bootstrap";
import AsyncImg from "../../../components/form/AsyncImg";
import { toast } from "react-toastify";
import { CompanyService } from "../../../services/company/companyService";
import { KTSVG } from "../../../components/Icon/KTSVG";
interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    companyData?: any;
}

export interface ICompanyForm {
    cif?: string;
    name?: string;
    address?: string;
}


const CompanySchema = yup.object({
    cif: yup.string().matches(/^[A-H|J|N|P|Q|R|S|U|V|W]{1}[0-9]{7}[0-9,A-J]{1}$/, 'Formato C.I.F Invalido').required('Campo Obligatorio'),
    name: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    address: yup.string().required('Campo Obligatorio'),
});

const CompanyForm: FC<CreateFormProps> = ({ isLoading, submit, companyData }) => {

    const mode = companyData ? 'Editar' : 'Crear';

    const companyInitialValues: ICompanyForm = {
        cif: companyData?.cif,
        name: companyData?.name,
        address: companyData?.address,
    }

    const formik = useFormik({
        initialValues: companyInitialValues,
        validationSchema: CompanySchema,
        onSubmit: values => {
            submit(values)
        },
    });

    const [pictureValue, setPictureValue] = useState<string | ArrayBuffer | null>(null);

    const [picturePreview, setPicturePreview] = useState<string | ArrayBuffer | null>('/media/misc/image.png');

    useEffect(() => {
        if (companyData?.logo) {
            setPicturePreview(companyData?.logo?.id);
        }
    }, [companyData?.logo]);

    const handleUploadPicture = async (e: React.ChangeEvent<any>) => {

        if (e.target !== null && e.target.files?.length) {
            const files = e.target.files;
            let myFiles = Array.from(files)
            formik.setFieldValue("picture", myFiles);
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setPictureValue(fileReader.result);
            }
        };

        if (e.target !== null && e.target.files?.length) {
            fileReader.readAsDataURL(e.target?.files[0]);
        }

        if (companyData?.id) {
            const response = (await (new CompanyService()).updateCompanyLogo(companyData?.id , e.target?.files[0])).getResponseData()

            if (!response.success) {
                toast.error(response.message);
                return;
            }

            setPicturePreview(response.data.img);

            toast.success('Imagen actualizada correctamente');
        }
    };

    const verifyClass = (inputFieldID: keyof ICompanyForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof ICompanyForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
                    {mode === 'Editar' && (
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='form-group p-2 mb-5 d-flex justify-content-center flex-column align-items-center'>
                                        <div className="preview">
                                            <input
                                                id="upload_create_user_picture"
                                                form="edit-user-form"
                                                type="file"
                                                name="picture"
                                                hidden
                                                accept="image/*"
                                                onChange={
                                                    (e) => {
                                                        handleUploadPicture(e);
                                                    }
                                                } />
                                        <div onClick={() => {document.getElementById('upload_create_user_picture')?.click()}}>
                                            <AsyncImg transparent src={picturePreview} type={"preview"}></AsyncImg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <FormGroup className='col-md-4'>
                            <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Nombre de la Organización <span className="text-danger">*</span></FormLabel>
                            <FormControl id='name' onChange={formik.handleChange} value={formik.values.name || ''} onBlur={formik.handleBlur} className={verifyClass('name')}/>
                            {showErrors('name')}
                        </FormGroup>
                        <FormGroup className='col-md-4'>
                            <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>CIF <span className="text-danger">*</span></FormLabel>
                            <FormControl id='cif' onChange={formik.handleChange} value={formik.values.cif || ''} onBlur={formik.handleBlur} className={verifyClass('cif')}/>
                            {showErrors('cif')}
                        </FormGroup>
                        <FormGroup className='col-md-4'>
                            <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Dirección <span className="text-danger">*</span></FormLabel>
                            <FormControl id='address' onChange={formik.handleChange} value={formik.values.address || ''} onBlur={formik.handleBlur} className={verifyClass('address')}/>
                            {showErrors('address')}
                        </FormGroup>
                    </div>
                    <div className='col-12 d-flex justify-content-center'>
                        <Button variant='info' className='fs-6 fw-bold mt-10 m-auto' type='submit' disabled={isLoading}>
                            {isLoading  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
                            {isLoading ? <Spinner/> : mode === 'Editar' ? 'Editar Compañia' : 'Crear Compañia'}
                        </Button>
                    </div>
            </form>
        </Fragment>
    );
}

export default CompanyForm;