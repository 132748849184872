import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from "../../../components/Icon/KTSVG";
import { ProjectService } from "../../../services/project/projectService";
import ProjectForm from "../form/ProjectFom";
import { projectsMenu } from "../../../menu";

const CreateProject: FC = () => {

    const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	
   /**
	*
	* @ES Utilizamos el metodo handleCreation para crear un nuevo proyecto
	* @EN We use the handleCreation method to create a new project
	*
	* @param values 
	* @returns Toast with success or error message
	*/	

	const handleCreation = async (values: any) => {
		setLoading(true);

		try {
			setLoading(true)
			let response = await (await (new ProjectService()).createProject(values)).getResponseData();
			if(response.success) {
				toast.success(response.message);
				navigate(`/projects/show/${response.data.id}`, {replace: true})
			} else {
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al crear el Proyecto');    
		}finally{
			setLoading(false);
		}
		
	}

    return (
        <Fragment>
			<div className="container-fluid">
				<div className={`card mt-3`}>
					<div className="card-header bg-success mb-3">
						<div className="card-title flex-row">
						<span className='card-label fw-bolder text-light fw-bold fs-1 d-flex align-items-end'>
							<KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-1 p-0 text-light" />
						</span>
						<span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
							NUEVO PROYECTO
						</span>
						</div>
					</div>
					<div className="card-body pt-3">
						<ProjectForm submit={handleCreation} isLoading={loading}/>
					</div>
				</div>
			</div>
		</Fragment>
    )
}

export default CreateProject;
