import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../../services/user/userService";
import { User } from "../../../redux/authSlice";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { EditUserFieldsModel } from "../../../types/user-type";
import UserForm from "../form/UserFom";
import { KTSVG } from "../../../components/Icon/KTSVG";
import { Button } from "react-bootstrap";
import UserEditPermissions from "./UserEditPermissions";
import { RolePermission } from "../../../types/role-type";

const UserEdit: FC = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [editPermissions, setEditPermissions] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<RolePermission[] | null>(null);

  const fetchUser = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUserById(id as string);
    setUserPermissions(response.getResponseData().data.userPermissions);
    return response.getResponseData() as User;
  }, [id , editPermissions]);

  const [data] = useFetch(fetchUser);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  const handleUpdate = async (values: EditUserFieldsModel) => {
    setLoading(true);

    try {
      setLoading(true)
      let response = await (await (new UserService()).editUser({ ...values, userId: values.id })).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error('Error al editar Usuario');
    } finally {
      setLoading(false);
    }

  };

  const getContent = () => {
    if (loading) return <div> Loading </div>;

    if (error) return <div> Error </div>;

    if (data !== null) {

      const userData = {
        ...data,
        role: data.userRoles && data.userRoles[0] && data.userRoles[0]?.role?.id,
        password: "",
        re_password: "",
        company: data.userRoles && data.userRoles[0] && data.userRoles[0]?.company?.id,
        profileImg: data.profileImg,
      };
      
      return (
        <div className="container-fluid">
          <div className={`card mt-3`}>
            <div className="card-header bg-success mb-3">
              <div className="card-title flex-row d-flex justify-content-between w-100">
                <div className="d-flex flex-row">
                <span className='card-label fw-bolder text-light fw-bold fs-1'>
                  <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-1 p-0 text-light" />
                </span>
                <span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
                  EDITAR USUARIO
                </span>
                </div>
                <span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom float-end'>
                  <Button
                    className="btn-info fw-bold"
                    onClick={() => { setEditPermissions(true) }}>
                    Editar Permisos
                  </Button>
                </span>
              </div>
            </div>
            <div className="card-body pt-3">
              <UserForm isLoading={false} submit={handleUpdate} userData={userData} />
            </div>
			    </div>
        </div>
      );
    }
  };

  return (
    <Fragment>
        {getContent()}
        {editPermissions && data &&
          <UserEditPermissions
            isOpen={editPermissions}
            setIsOpen={setEditPermissions}
            userId={id}
            userPermissions={userPermissions}
            setUserPermissions={setUserPermissions}
          />
        }
    </Fragment>
  );
};

export default UserEdit;
