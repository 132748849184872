/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, Key, useEffect, useState } from 'react'
import { Pagination } from '../pagination';
import clsx from "clsx";
import { KTSVG } from '../../Icon/KTSVG';
import CustomSearchInput from '../../form/CustomSearchInput';
import SpinnerCustom from '../../Spinner/SpinnerCustom';
import { Button } from 'react-bootstrap';

interface Column {
  name: string | JSX.Element,
  sortable?: boolean,
  keyValue?: string,
  className?: string,
  cellClassName?: string,
  ordered?: "ASC" | "DESC",
  render?(element: any): JSX.Element,
  isActionCell?: boolean,
  sortColumn?(keyValue: string, order: "asc" | "desc"): void
}

interface SearchInput {
  placeholder: string,
  onSearch(value: string): void
}

interface CreateButton {
  title: string,
  element?: any,
  className?: string,
  show?: boolean,
  callback?(element: any): void,
  icon: string,
  iconColor ? : string
}

type Columns = Array<Column>;


interface Action {
  title: string,
  iconColor?: string,
  iconColorFunction?(element: any): string,
  iconPath?: string,
  description?: string,

  buttonType?: "normal" | "icon" | "link",
  additionalClasses?: string,
  callback?(element: any): void
}

type Actions = Array<Action>;

interface PaginationProps {
  pageCount: number,
  currentPage: number,
  pageSize: number
  handlePagination: Function
  handlePerPage: Function
}

type Props = {
  className: string,
  tableClassName?: string,
  title?: string,
  subtitle?: string,
  data: Array<any> | null,
  columns: Columns
  toolbar?: boolean,
  actions?: Actions | null,
  startElementToShow?: number,
  pagination?: boolean
  paginationData?: PaginationProps,
  selectableItems?: boolean,
  onSelectAllItems?(checked: boolean): void,
  defaultLimit?: number,
  defaultOrder?: any,
  onChangeCheckedItems?(id: string, event: ChangeEvent<HTMLInputElement>, element: any): void,
  onClickRow?(id: string): void,
  searchInput?: SearchInput,
  createButton?: CreateButton,
  deleteButton?: CreateButton,
  totalsRow?: { [key: string]: number } | null;
  showTotalsColumn?: boolean;
  totalsRowPosition?: 'top' | 'bottom';
}


const DEFAULT_COLUMN_WIDTH = "min-w-100px";

const TablesWidget9: React.FC<Props> = ({ className, title, subtitle, deleteButton, data, toolbar, columns, actions, tableClassName, startElementToShow, defaultLimit, defaultOrder, pagination, paginationData, onClickRow, searchInput, createButton, selectableItems, onChangeCheckedItems, onSelectAllItems, totalsRow, showTotalsColumn, totalsRowPosition }) => {

  const [confColumns, setConfColumns] = useState(columns);

  const [sortedBy, setSortedBy] = useState<Key | null>(
    defaultOrder !== undefined ? confColumns.findIndex((column: Column) => column.keyValue === defaultOrder[0]?.field && defaultOrder[0]?.order === "asc") 
    : null);

  useEffect(() => {
    if (defaultOrder !== undefined) {
      setSortedBy(confColumns.findIndex((column: Column) => column.keyValue === defaultOrder[0]?.field && defaultOrder[0]?.order === "asc"));
    }
  }, [defaultOrder]);

  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [data]);

  const tableColumn = (column: Column, index: Key) => {

    let className = column.className ?? DEFAULT_COLUMN_WIDTH;
    let columnName = column.name;
    let sortable = column.sortable ?? false;
    let checkboxColumn = null;

    if (selectableItems === true && index === 0) {
      checkboxColumn = (
        <th key={`table-checkbox` + index} className={``}>
          <input
            key={`table-checkbox-check-all` + index}
            className="form-check-input"
            type="checkbox"
            id={`table-checkbox-select-all`}
            checked={selectedAll}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              let checked = event.target.checked;

              setSelectedAll(checked);

              if (checked) {
                setSelectedItems(data?.map((item: any) => item.id) ?? []);
              } else {
                setSelectedItems([]);
              }

              onSelectAllItems && onSelectAllItems(checked);

            }}
          />
        </th>
      );
    }


    return (
      <React.Fragment key={'column-'+index}>
        {checkboxColumn}
        <th key={index} className={className}>{columnName}
          {sortable == true && (
            renderSortArrows(index, column)
        )}</th>
      </React.Fragment>
    )
  }


  /**
   * Render cell with configured props.
   * 
   * @param element 
   * @param column 
   * @returns 
   */
  const renderCell = (element: any, index: Key, column: Column) => {

    if (column.keyValue) {

      if (column.render !== undefined) {
        return (
          <td key={index + '-cell'} className={column.cellClassName ? column.cellClassName : ''}>
            {column.render(element)}
          </td>
        )
      }

      return (
        <td key={index + '-cell'} className={column.cellClassName ? column.cellClassName : ''}>
          <div key={index + '-cell-field'} className='text-dark fw-bolder text-hover-primary d-block'>
            {element[column.keyValue]}
          </div>
        </td>
      )
    }


    if (column.isActionCell === true && actions !== undefined && actions !== null) {
      return renderActions(element, index, actions);
    }

    throw new Error(`Column ${column.name} is not defined correctly.`)

  }


  const callToAction = (action: Action, element: any) => {
    if (action.callback) {
      action.callback(element);
    }
  }

  const renderTotalsRow = (totals: { [key: string]: number }) => {
    return (
      <tr>
        <td className='text-center' style={{ backgroundColor: '#F5F5F5', borderBottomLeftRadius: totalsRowPosition == 'bottom' ? '5px' : '0px'}}><b>Totals</b></td>
          {Object.keys(totals).map((key: string, index: React.Key | null | undefined) => (
            <td key={index} className='text-center fw-bold' 
            style={{ backgroundColor: '#F5F5F5', borderBottomRightRadius: totalsRowPosition == 'bottom' && Object.keys(totals).length - 1 == index ? '5px' : '0px'}}>
              <b>{totals[key]}</b>
            </td>
          ))}
      </tr>
    );
  };


  const renderSortArrows = (index: Key, column: Column) => {
    return (
      <a
        key={index + '-' + column.name}
        onClick={() => {
          if (column.sortColumn != undefined && column.keyValue != undefined) {
            if (sortedBy == index) {
              setSortedBy(null)
              column.sortColumn(column.keyValue, "desc")
            } else {
              setSortedBy(index);
              column.sortColumn(column.keyValue, "asc")
            }
          }
        }}
        data-toogle="tooltip"
        className='btn btn-icon btn-sm me-1'
      >
        <KTSVG path={'/media/icons/duotune/arrows/' + (sortedBy == index ? 'arr073' : 'arr072') + '.svg'} className='svg-icon-3' />
      </a>)
  }


  const renderActions = (element: any, index: Key, actions: Array<Action>): JSX.Element => {
    return (
      <td key={index+ '-cell'}>
        <div className='d-flex justify-content-end flex-shrink-0'>
          {actions.map((action: Action, index: number) => {
            return actionCell(action, index, element);
          })}
        </div>
      </td>
    );
  }

  const renderCheckbox = (selectableItems: boolean = false, element: any) => {
    if (selectableItems === true) {
      return (
        <td key={'cell-checkbox-' + element.id}>
          <div>
            <div key={'field-checkbox-' + element.id} className="form-check">
              <input
                key={`checkbox-${element.id}`}
                className="form-check-input"
                type="checkbox"
                id={`checkbox-${element.id}`}
                checked={selectedAll || selectedItems.includes(element.id)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {

                  setSelectedItems((prev) => {
                    if (event.target.checked) {
                      return [...prev, element.id];
                    } else {
                      return prev.filter((item) => item !== element.id);
                    }
                  });

                  onChangeCheckedItems && onChangeCheckedItems(element.id, event, element);
                }}
              />
            </div>
          </div>
        </td>
      )
    }
  }

  const actionCell = (action: Action, index: Key, element: any) => {
    return (
      <a
        key={index + '-action-' + element.id}
        onClick={() => {
          callToAction(action, element);
        }}
        title={action.description}
        data-toogle="tooltip"
        className={
          clsx({
            'btn btn-icon btn-sm me-1': action.buttonType !== "normal",
            'btn btn-sm btn-primary me-2': action.buttonType === "normal"
          }) + " " + action.additionalClasses
        }
      >
        {
          (action.buttonType === "icon" || action.buttonType === undefined) &&  (<KTSVG key={index + '-action-icon-' + element.id} path={action.iconPath ?? ""} className={`svg-icon-3 ${action.iconColor || (action.iconColorFunction ? action.iconColorFunction(element) : "")}`} />)
        }
        {
          action.buttonType === "normal" && (<span key={index + '-action-text-' + element.id} className="btn-label">{action.title.toUpperCase()}</span>)
        }
        {
          action.buttonType === "link" && (<KTSVG key={index + '-action-icon-' + element.id} path={action.iconPath ?? ""} className={`svg-icon-3 ${action.iconColor || (action.iconColorFunction ? action.iconColorFunction(element) : "")}`} />)
        }

      </a>
    );
  }

  const tableRow = (element: any, index: number, columns: Columns) => {

    if (startElementToShow !== undefined && index >= startElementToShow) {
      return (
        <tr key={index} onClick={() => onClickRow != undefined ? onClickRow(element.id) : null} >
          {renderCheckbox(selectableItems ?? false, element)}
          {columns.map((column: Column, index: number) => {
            return renderCell(element, index, column);
          })}
        </tr>
      );
    }

  }

  return (
    <div className={`card ${className}`}>

    {searchInput !== undefined || createButton !== undefined || deleteButton != undefined ? (
      <div className={`row me-5 ms-5 mt-5 d-flex ${createButton !== undefined && searchInput !== undefined ? 'justify-content-between' : 'justify-content-end' }`}>
        {createButton !== undefined && (
          <div className="col-md-3 text-end">
            <Button className={`btn ${createButton.className} ${createButton.show == true ? 'd-none' : ''}`} 
              key={`${createButton.title}`} onClick={() => {
              callToAction(createButton, createButton.element);
            }}>
                {createButton.icon && ( <KTSVG path={createButton.icon} className={`svg-icon-2 ${createButton.iconColor}`} /> )}
                {createButton.title}
            </Button>
          </div>
        )}
        {deleteButton !== undefined && (
          <div className="col-md-3 text-end">
            <Button className={`btn ${deleteButton.className} ${deleteButton.show == true ? 'd-none' : ''}`} 
              key={`${deleteButton.title}`} onClick={() => {
              callToAction(deleteButton, deleteButton.element);
            }}>
                {deleteButton.icon && ( <KTSVG path={deleteButton.icon} className='svg-icon-2' /> )}
                {deleteButton.title}
            </Button>
          </div>
        )}
        {searchInput && (
          <div className="col-md-3 text-end">
              <CustomSearchInput placeholder={searchInput.placeholder} onSearch={searchInput.onSearch}/>
          </div>
        )}
      </div>
    ) : (null
    )}



      {toolbar === true ?? (
        <div className='card-header border-0 pt-5'>

          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{title ?? title}</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{subtitle ?? subtitle}</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New User
            </a>
          </div>
        </div>
      )}

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-loading table-responsive'>
          {/* begin::Table */}
          <table className={'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' + tableClassName}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {confColumns.map(tableColumn)}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {showTotalsColumn === true && totalsRow && totalsRowPosition === 'top' && renderTotalsRow(totalsRow)}
              {data !== null ? data.map((element: any, index: number) => tableRow(element, index, columns)) : <SpinnerCustom />}
              {showTotalsColumn === true && totalsRow && totalsRowPosition === 'bottom' && renderTotalsRow(totalsRow)}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          {pagination == true && paginationData !== undefined && paginationData !== null && (

            <Pagination currentPage={paginationData.currentPage} pageCount={paginationData.pageCount}
              handlePagination={paginationData.handlePagination} handlePerPage={paginationData.handlePerPage} defaultPerPage={defaultLimit}/>

          )}

        </div>
        {/* end::Table container */}

      </div>

      {/* begin::Body */}
    </div>
  )
}

TablesWidget9.defaultProps = {
  tableClassName: '',
  startElementToShow: 0
}

export { TablesWidget9 }
