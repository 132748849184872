import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

/**
 * @ES Endpoint de backups
 * @EN Backups endpoint
 */

const BACKUP_ENDPOINT = '/backups';

export class BackupService extends RestServiceConnection {

  /**
   * 
   * @ES Crea un nuevo backup
   * @EN Create a new backup
   * 
   * @param values 
   * @returns API Response
   */  

  createBackup = async (values: any) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: BACKUP_ENDPOINT + '/create',
        data: values,
    }, true);
    return this;
  }

  /**
   * 
   * @ES Edita un backup
   * @EN Edit a backup
   * 
   * @param values 
   * @returns API Response
   */

  editBackup = async (values: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: BACKUP_ENDPOINT + '/edit',
          data: values,
          headers: {
              "Content-Type": "application/json"
          }
      }, true);
      return this;
  }

 /**
  * 
  * @ES Obtiene todos los backups
  * @EN Get all backups
  * 
  * @param filters 
  * @returns API Response
  */

  getBackups = async (filters: any) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: BACKUP_ENDPOINT + '/list',
          data: {
              ...filters
          },
      }, true) as AxiosResponse;
      return this;
  }

 /**
  * 
  * @ES Obtiene un backup por id
  * @EN Get a backup by id
  * 
  * @param id 
  * @returns API Response
  */

  getBackupById = async (id: string) => {
      this.response = await this.makeRequest({
          method: 'POST',
          url: BACKUP_ENDPOINT + '/get',
          data: {
            backupId: id
          }
      }, true);
      return this;
  }

 /**
  *  
  * @ES Elimina un backup
  * @EN Delete a backup
  * 
  * @param backupId 
  * @returns API Response
  */

  deleteBackup = async (backupId: string) => {

      this.response = await this.makeRequest({
          method: 'POST',
          url: BACKUP_ENDPOINT + '/delete',
          data: {
            backupId: backupId
          }
      }, true);

      return this
  }

  /**
   * 
   * @ES Obtiene las ejecuciones de un backup
   * @EN Gets the executions from a backup
   * 
   * @param backupId 
   * @returns API Response
   */

  getExecutionsFromBackup = async (backupId: string) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: BACKUP_ENDPOINT + '/list-executions-backup',
        data: {
            backupId: backupId
        }
    }, true);
    return this;
}
}