import React, { useEffect } from 'react';
import _ from 'lodash';
import Select, { ActionMeta, GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';

type Props = {
    options?: OptionsOrGroups<unknown, GroupBase<unknown>>
    is_multi?: boolean,
    onChange?: (selected: MultiValue<unknown>) => void,
    onChangeSingle?: (selected: SingleValue<unknown>) => void,
    onInputChange?: (inputValue: string) => void;
    placeholder?: string
    defaultValue ?: OptionsOrGroups<any, any>|null|unknown
    customFontSize?: string
    minWidth?: string
}

const CustomSelect: React.FC<Props> = ({ options, placeholder, onChange, is_multi, onChangeSingle , defaultValue, onInputChange, customFontSize, minWidth}) => {
    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            border: 'none',
            fontSize: '1.1rem',
            fontWeight: '500',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: '0 !important',
            boxShadow: 'none',
            backgroundColor: state.isFocused ? '#e4ecf2' : '#eef3f7',
            transition: 'color 0.2s ease, background-color 0.2s ease',
            padding: '0.25rem',
            fontSize: customFontSize ?  customFontSize : '1.1rem',
            fontWeight: '500',
            minWidth: minWidth ? minWidth : ''
        }),
        container: (provided: any, state: any) => ({
            ...provided,
            border: 'none'
        }),
    }

    const onSelectChange = (newValue: MultiValue<unknown>, actionMeta: ActionMeta<unknown>) => {
        if (onChange !== undefined) {
            onChange(newValue);
        }
    }

    const onSelectChangeSingle = (newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
        if (onChangeSingle !== undefined) {
            onChangeSingle(newValue);
        }
    }

    const onInputValueChange = (inputValue: string) => {
        if (onInputChange !== undefined) {
          onInputChange(inputValue);
        }
    };

    return (
        <>
            {
                is_multi === true ?
                (
                    <Select placeholder={placeholder} isSearchable={true} isMulti={true} styles={customStyles} options={options} defaultValue={defaultValue} onChange={onSelectChange} onInputChange={onInputValueChange}></Select>
                )
                :
                (
                    <Select placeholder={placeholder} isSearchable={true} isMulti={false} styles={customStyles} options={options} defaultValue={defaultValue} onChange={onSelectChangeSingle} onInputChange={onInputValueChange}></Select>
                )
            }
            
        </>
    )
}

CustomSelect.defaultProps = {
    placeholder: 'Seleccione una opción',

}
export default CustomSelect;