import React, { FC, useState } from 'react';

interface Tab {
    id: string;
    label: string;
    default?: boolean;
    chilldren?: any;
}

interface CustomTabProps {
    tabs: Tab[];
    defaultActiveTab?: string;
    onActiveTab?: (tabId: string) => void;
}

const CardWithTabs: FC <CustomTabProps> = ({ tabs , defaultActiveTab, onActiveTab }) => {

  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab || tabs[0]?.id);

  const handleTabClick = (event : any, tabId : string) => {
    event.preventDefault();
    setActiveTab(tabId);
    if (onActiveTab !== undefined) onActiveTab(tabId);
  };

  return (
    <div className="card mt-3">
      <div className="card-header w-100" style={{ minHeight: '50px' }}>
        <ul className="nav nav-tabs card-header-tabs w-100" role="tablist">
          {tabs.map((tab, key) => (
            <li className="nav-item-custom" key={tab.id + key}>
              <a
                className={`nav-link-custom ${activeTab === tab.id ? 'tabActiveCustom' : ''}`}
                href={`#${tab.id}`}
                role="tab"
                aria-controls={tab.id}
                aria-selected={activeTab === tab.id}
                onClick={(event) => handleTabClick(event, tab.id)}

              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="card-body p-1">
        <div className="tab-content mt-3">
          {tabs.map((tab, key) => (
            <div className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`} id={tab.id} role="tabpanel" key={tab.id + 'tab' + key}>
              {tab.chilldren}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardWithTabs;