import { useCallback, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { NavLink, Outlet, useParams } from "react-router-dom";
import PageLayoutData from "../../../components/Page/PageLayoutData";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import useFetch from "../../../hooks/useFetch";
import { ProjectService } from "../../../services/project/projectService";

const ProjectView = () => {

    const { id = '' } = useParams<{ id: string }>();

    const [tabs, setTabs] = useState<any>(null);

    const fetchProject = useCallback(async () => {
        const projectService = new ProjectService();
        const response = await projectService.getProjectById(id as string);
        return response.getResponseData() as any;
    }, [id]);

    const [data] = useFetch(fetchProject);

    return (
        <>
            {data ? (
                <>
                    <PageLayoutData
                        pageTitle={data.name}
                        sidebar={false}
                        headerColor={data.color}
                        breadcrumb={[
                            {
                                text: 'Módulos',
                                link: '/'
                            },
                            {
                                text: 'Proyectos',
                                link: '/projects'
                            },
                            {
                                text: data.name,
                                link: `#`
                            }
                        ]} />
                    <div className="container-fluid d-flex p-4">

                        {/* Navigation column */}
                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                            <Card className="mt-3">
                                <CardBody>
                                    <div className="d-flex flex-column gap-3">
                                        <div className="d-flex flex-column gap-5">
                                            <div className="d-flex flex-stack">
                                                <NavLink to={`/projects/show/${data.id}/resume`} className="fs-6 text-gray-800 text-hover-primary text-active-primary">Resumen</NavLink>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column gap-5">
                                            <div className="d-flex flex-stack">
                                                <NavLink to={`/projects/show/${data.id}/backups`} className="fs-6 text-gray-800 text-hover-primary text-active-primary">Backups</NavLink>
                                                <div className="badge badge-light-primary">3</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column gap-5">
                                            <div className="d-flex flex-stack">
                                                <NavLink to={`/projects/show/${data.id}/members`} className="fs-6 text-gray-800 text-hover-primary text-active-primary">Participantes</NavLink>
                                                <div className="badge badge-light-primary">2</div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        {/* Content column */}
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                            <Outlet />
                        </div>

                    </div>
                </>

            ) : (
                <SpinnerCustom />
            )}
        </>
    );
};

export default ProjectView;