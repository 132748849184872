import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import { BreadcrumbObject } from '../../components/Page/PageLayoutData';
import { set } from 'lodash';
import { MenuComponent } from '../../utils/MenuComponent';
import { useDispatch } from 'react-redux';
import { setSidebarVisibility } from '../../redux/sidebarSlice';

// Definimos el tipo para las propiedades del Layout que tiene que tener childrens
type LayoutProps = {
    pageTitle: string | undefined;
    breadcrumb: BreadcrumbObject[] | undefined;
    setPageTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    setBreadcrumb: React.Dispatch<React.SetStateAction<BreadcrumbObject[] | undefined>>;
    actionButtons: ReactNode[] | null | undefined | string | any;
    setActionButtons: React.Dispatch<React.SetStateAction<ReactNode[] | null | undefined | string | any>>;
    resetLayoutHeader: () => void;
    resetActionButtons: () => void;
    setSidebar: (sidebar: boolean) => void;
    setHeaderColor: (headerColor: string) => void;
    resetHeaderColor: () => void;
};

// Creamos un contexto para el Layout
const LayoutContext = createContext<LayoutProps | undefined>(undefined);

// Definimos un hook para acceder al contexto del Layout
export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout debe ser usado dentro de un LayoutProvider');
  }
  return context;
};

// Definimos el componente LayoutProvider
export const LayoutProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [pageTitle, setPageTitle] = React.useState<string | undefined>(undefined);
    const [breadcrumb, setBreadcrumb] = React.useState<BreadcrumbObject[] | undefined>(undefined);
    const [actionButtons, setActionButtons] = React.useState<ReactNode[] | null | undefined | string | any>(null);
    const dispatch = useDispatch();

    const resetLayoutHeader = () => {
        setPageTitle(undefined);
        setBreadcrumb(undefined);
    }

    const resetActionButtons = () => {
        setActionButtons(null);
    }

    useEffect(() => {
        MenuComponent.resetBootstrapTooltip();
        setTimeout(() => {
          MenuComponent.createBootstrapTooltips();
        }, 500);
    }, [actionButtons]);

    const setSidebar = (sidebar: boolean) => {
      let sidebarContainer = document.getElementById('kt_app_body');
      let sidebarContainerStatus = sidebarContainer?.getAttribute('data-kt-app-header-minimize');
      
      // Si se quiere mostrar el sidebar y está minimizado, lo mostramos
      if(sidebar === true) {
        if(sidebarContainerStatus === 'on') {
          console.log('Llega')
          let sidebarButton = document.getElementById('kt_app_sidebar_toggle');
          if(sidebarButton) {
            sidebarButton.click();
          }
        }
        
      }

      // Si NO se quiere mostrar el sidebar y está maximizado, lo minimizamos
      if(sidebar === false) {
        if(sidebarContainerStatus === 'off') {
          let sidebarButton = document.getElementById('kt_app_sidebar_toggle');
          if(sidebarButton) {
            sidebarButton.click();
          }
        }
      }
    }

    const setHeaderColor = (headerColor: string) => {
        let header = document.getElementById('kt_header');
        if(header) {
            header.style.backgroundColor = headerColor;
        }
    }

    const resetHeaderColor = () => {
        let header = document.getElementById('kt_header');
        if(header) {
            header.style.backgroundColor = 'var(--bs-app-header-base-bg-color)';
        }
    }

    return (
        <LayoutContext.Provider value={{ pageTitle, breadcrumb, actionButtons, setPageTitle, setBreadcrumb, resetLayoutHeader, setActionButtons, resetActionButtons, setSidebar, setHeaderColor, resetHeaderColor }}>
          {children}
        </LayoutContext.Provider>
    );
};