import { Fragment } from "react"
import { FiltersProvider } from "../../components/filters/FiltersProvider"
import { CompanyList } from "./list"

const CompaniesListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <CompanyList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default CompaniesListWrapper