import { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, FormControl, FormGroup, FormLabel, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/form/AsyncImg";
import { ProjectService } from "../../../services/project/projectService";
import { CirclePicker } from "react-color";
import useFetch from "../../../hooks/useFetch";
import { ProjectTypeService } from "../../../services/project/projectTypeService";
import CustomSelect from "../../../components/form/CustomSelect";
import { KTSVG } from "../../../components/Icon/KTSVG";
import { CompanyService } from "../../../services/company/companyService";
import { CompaniesApiResponse } from "../../../types/company-type";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    projectData?: any;
}

interface IProjectForm {
    name: string;
    projectType: string;
    description: string;
    color: string;
    picture : any;
}

const ProjectSchema = yup.object({
    name: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    projectType: yup.string().required('Campo Obligatorio'),
    description: yup.string().required('Campo Obligatorio'),
    color: yup.string().required('Campo Obligatorio'),
});


const ProjectForm :FC<CreateFormProps> = ({isLoading, submit, projectData}) => {

    const mode = projectData ? 'Editar' : 'Crear';

    const { userCan } = useContext(PrivilegeContext);

    const [pictureValue, setPictureValue] = useState<string | ArrayBuffer | null>(null);

    const [picturePreview, setPicturePreview] = useState<string | ArrayBuffer | null>('/media/misc/image.png');

    const projectInitialValues: IProjectForm = {
        name: projectData?.name,
        color: projectData?.color,
        description: projectData?.description,
        projectType: projectData?.projectType?.id || '',
        picture: null
    }

    useEffect(() => {
        if (projectData?.projectImg) {
            setPicturePreview(projectData?.projectImg?.id);
        }
    }, [projectData?.projectImg]);

    const handleUploadPicture = async (e: React.ChangeEvent<any>) => {

        if (e.target !== null && e.target.files?.length) {
            const files = e.target.files;
            let myFiles = Array.from(files)
            formik.setFieldValue("picture", myFiles);
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setPictureValue(fileReader.result);
            }
        };

        if (e.target !== null && e.target.files?.length) {
            fileReader.readAsDataURL(e.target?.files[0]);
        }

        if (projectData?.id) {
            const response = (await (new ProjectService()).updateImage(e.target?.files[0], projectData?.id)).getResponseData()

            if (!response.success) {
                toast.error(response.message);
                return;
            }

            console.log(response.data.img);

            setPicturePreview(response.data.img);

            toast.success('Imagen actualizada correctamente');
        }
    };

    const formik = useFormik({
        initialValues: projectInitialValues,
        validationSchema: ProjectSchema ,
        onSubmit: values => {
            values = {
                ...projectData,
                ...values
            }
            
            submit(values)
        },
    });

    const fetchProjectTypes = useCallback(async () => {
        const projectTypeService = new ProjectTypeService();
        const response = await projectTypeService.getProjectTypes();
        return response.getResponseData() as any;
    }, []);

    const [projectTypes, fetchingProjectTypes, projectTypeError] = useFetch(fetchProjectTypes);

    const getProjectTypesList = () => {
        if (projectTypes as any) {
            return projectTypes.projectTypes?.map((projectType: { id: any; name: any; }) => {
                return {
                    value: projectType.id,
                    label: projectType.name,
                }
            })
        }
        return [];
    }


    const fetchCompanies = useCallback(async () => {

        const companyService = new CompanyService();
        if (userCan("list" , "companies")) return;
        const response = await companyService.getCompanies();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

    const getCompaniesList = () => {
        if (companies as any) {
            return companies?.companies?.map((company: any) => {
                return {
                    value: company.id,
                    label: company.name
                }
            }) || [];
        }
    }

    const verifyClass = (inputFieldID: keyof IProjectForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof IProjectForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as any}</div> : <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="row mb-3">
                    {mode === 'Editar' && (
                    <div className="col-md-5">
                        <div className='form-group p-2 pb-0 mb-5 d-flex justify-content-center flex-column align-items-center'>
                            <div className="preview">
                                <input
                                    id="upload_create_project_picture"
                                    form="edit-project-form"
                                    type="file"
                                    name="picture"
                                    hidden
                                    accept="image/*"
                                    onChange={
                                        (e) => {
                                        handleUploadPicture(e);
                                    }
                                } />
                                <div onClick={() => {document.getElementById('upload_create_project_picture')?.click()}}>
                                    <AsyncImg transparent src={picturePreview} type={"preview"} styles="max-270px"></AsyncImg>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    <div className={`${mode === 'Editar' ? 'col-md-7' : 'col-md-4' } row`}>
                        <div className="col-md-12">
                            <FormGroup controlId="formName">
                                    <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Nombre</FormLabel>
                                    <FormControl type="text"
                                        autoComplete='off'
                                        name="name"
                                        value={formik.values.name || ''}
                                        onChange={formik.handleChange}
                                        className={verifyClass('name')}
                                    />
                                    {showErrors('name')}
                            </FormGroup>
                            { projectTypes && (
                            <FormGroup controlId="formName" className="mt-2">
                                <FormLabel className='form-label fs-6 fw-bolder text-dark mb-3'>Tipo de Proyecto</FormLabel>
                                <CustomSelect options={getProjectTypesList()} defaultValue={getProjectTypesList().find((projectType: any) => projectType.value === formik.values.projectType) || ''}
                                onChangeSingle={(selected: any) =>{formik.setFieldValue('projectType', selected.value)}}/>
                            </FormGroup> )}
                        </div>
                    </div>
                    <div  className={`${mode === 'Editar' ? '' : 'col-md-8' } row`}>
                        <div className="col-md-6">
                            <FormGroup id='description' >
                                <FormLabel className="form-label fs-6 fw-bolder text-dark mb-3">Descripcion <span className="text-danger">*</span></FormLabel>
                                <FormControl as="textarea" required rows={4} name="description" value={formik.values.description == null ? '' : formik.values.description} onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup id='color' controlId="formColor">
                                <FormLabel className="form-label fs-6 fw-bolder text-dark mb-3">Color <span className="text-danger">*</span></FormLabel>
                                <div className="d-flex justify-content-center">
                                    <CirclePicker color={formik.values.color} onChangeComplete={(color: any) => {
                                        formik.setFieldValue('color', color.hex)
                                    }}/>
                                </div> 
                            </FormGroup>
                        </div>
                        </div>
                </div>
                <div className='col-12 d-flex justify-content-center'>
                    <Button variant='info' className='fs-6 fw-bold mt-10 m-auto' type='submit' disabled={isLoading}>
                        {isLoading  ? null : <KTSVG path="/media/icons/duotune/technology/teh011.svg" className="svg-icon-2 svg-icon-success me-1" />}
                        {isLoading ? <Spinner variant="info" /> : mode === 'Editar' ? 'Editar Proyecto' : 'Crear Proyecto'}
                    </Button>
                </div>
            </form>
        </Fragment>
    );
};

export default ProjectForm;