/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import ReactSelect from 'react-select'

type Props = {
    pageCount: number,
    currentPage: number,
    rangeDisplayed?: number,
    handlePagination: Function,
    handlePerPage: Function
    defaultPerPage?: number
}

const Pagination: React.FC<Props> = ({ pageCount, currentPage, rangeDisplayed, handlePagination, handlePerPage, defaultPerPage }) => {

    const pageSizeOptions = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 50, label: '50' },
    ]

    useEffect(() => {
        if (defaultPerPage) {
          handlePerPage({ value: defaultPerPage })
        }
      }, [defaultPerPage])

    return (

        <div className='d-flex flex-stack flex-wrap pt-10'>

            <div className='d-flex fs-6 fw-bold text-gray-700'>

                <div className='align-self-center me-2'> Entries</div>

                <ReactSelect 
                menuPlacement='top' 
                onChange={e => handlePerPage(e)} 
                options={pageSizeOptions}
                defaultValue={defaultPerPage ? pageSizeOptions.find(e => e.value === defaultPerPage) : pageSizeOptions[0]}
                />

            </div>

            <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={rangeDisplayed}
                initialPage={currentPage - 1}
                onPageChange={page => handlePagination(page)}
                activeLinkClassName={'active'}
                nextClassName={'page-item'}
                previousClassName={'page-item'}
                className={'pagination'}
                pageClassName={'page-item'}
                activeClassName={'active'}
                nextLabel={<i className='next'></i>}
                previousLabel={<i className='previous'></i>}
                nextLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                breakLinkClassName={'page-link'}
                breakClassName={'page-item'} />

        </div>
    )
}

Pagination.defaultProps = {
    rangeDisplayed: 2
}

export { Pagination }
