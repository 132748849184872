
import { RestServiceConnection } from "../restServiceConnection";
import { NewRole } from "../../types/role-type";

/**
 * @ES Endpoint de roles
 * @EN Role endpoint
 */

const ROLES_ENDPOINT = "/roles";

export class RoleService extends RestServiceConnection {
  
 /**
  * 
  * @ES Obtiene todos los roles
  * @EN Get all roles
  * 
  * @param filters 
  * @returns API response
  */ 

  getRoles = async (filters?: any) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/list',
      data: filters ? {...filters} : {},
    }, true);
    return this;
  };
  
 /**
  * 
  * @ES Obtiene un rol por id
  * @EN Get a role by id
  * 
  * @param id 
  * @returns API response
  */ 

  getRoleById = async (id: string) => {
    this.response = await this.makeRequest({
        method: 'POST',
        url: ROLES_ENDPOINT + '/get',
        data: {
            roleId: id
        },
        headers: {
            "Content-Type": "application/json"
        }
    }, true);
    return this;
  }
  
 /**
  * 
  * @ES Crea un rol
  * @EN Create a role
  * 
  * @param role 
  * @returns API response
  */ 

  createRole = async (role: NewRole) => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: ROLES_ENDPOINT + "/create",
        data: role,
      },
      true
    );
    return this;
  };

 /**
  * 
  * @ES Edita un rol
  * @EN Edit a role
  * 
  * @param roleData 
  * @returns API response
  */ 

  editRole = async (roleData: any) => {

    this.response = await this.makeRequest({
        method: 'POST',
        url: ROLES_ENDPOINT + '/edit',
        data: roleData,
    }, true);
    return this;
  }

 /**
  * 
  * @ES Obtiene los roles de una compañia
  * @EN Get company roles
  * 
  * @param companyId 
  * @returns API response
  */ 

  getCompanyRoles = async (companyId: string) => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: ROLES_ENDPOINT + "/list",
        data: {
          filter_filters: {
            companyid: companyId
          }
        },
      },
      true
    );
    return this;
  };

 /**
  * 
  * @ES Obtiene los roles de un usuario
  * @EN Get user roles
  * 
  * @param filters 
  * @returns 
  */ 

  listRoles = async (filters?: any) => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: ROLES_ENDPOINT + "/list",
        data: filters
      },
      true
    );
    return this;
  };

  /**
   * 
   * @ES Obtiene los roles de una compañia
   * @EN Get company roles
   * 
   * @param companyId 
   * @returns 
   */

  listRolesByCompany = async (companyId: string) => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: ROLES_ENDPOINT + "/list",
        data: {
          filter_filters: {
            companyId: companyId, 
            active: true
          }
        },
      },
      true
    );
    return this;
  }

 /**
  * 
  * @ES Borra un rol
  * @EN Delete a role
  * 
  * @param id 
  * @returns 
  */ 

  deleteRole = async (id: string) => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: ROLES_ENDPOINT + "/delete",
        data: {roleId: id},
      },
      true
    );
    return this;
  };

 /**
  * 
  * @ES Cambia el estado de un rol
  * @EN Change a role status
  * 
  * @param id 
  * @param status 
  * @returns API response
  */ 

  toggleRoleStatus = async (id: string, status: boolean) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/toggle',
      data: {
        role: id,
        active: status
      }
    }, true);
    return this;
  }
}
