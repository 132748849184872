import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App/App';
import { createRoot } from 'react-dom/client';
import './styles/style.scss';
import './styles/style.react.scss';
import { registerServiceWorker } from './sw';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const client = new QueryClient();

const children = (
	<Provider store={store}>
		<QueryClientProvider client={client}>
			<Router>
				<React.StrictMode>
					<App />
				</React.StrictMode>
			</Router>
		</QueryClientProvider>
	</Provider>
);

// Init aplication when service worker is ready
registerServiceWorker().then(() => {

	const container = document.getElementById('root');
	createRoot(container as Element).render(children);

});