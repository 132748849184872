import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import { WithChildren, checkIsActive, toAbsoluteUrl } from '../../../../utils/utils'
import { KTSVG } from '../../../../components/Icon/KTSVG'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isSub?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  isSub = false,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          <span className='menu-icon'>
            <KTSVG path={`/media/icons/duotune/${icon}`} className={`svg-icon-muted ${ isSub ? 'svg-icon-1hx' : 'svg-icon-2hx'}`} />
          </span>
        <span className='menu-title fw-bold'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
