import { ToastContainer } from 'react-toastify';
import { PrivilegeProvider } from '../components/priviledge/PriviledgeProvider';
import AppRouter from '../routes';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <>
            <ToastContainer />
            <PrivilegeProvider>
                <AppRouter />
            </PrivilegeProvider>
        </>
    );
};
export default App;