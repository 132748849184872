import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import sidebarReducer from './sidebarSlice';
import activeTabReducer from './activeTabSlice'
import { debounce } from '../utils/utils';
import { loadState, saveState } from './browser-storage';

const store = configureStore({
  reducer: {
    // @ts-ignore
    auth: authReducer,
    sidebar: sidebarReducer,
    activeTab: activeTabReducer,
  },
  preloadedState: loadState(),
});

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;