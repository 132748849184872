import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { backupMenu, usersMenu } from "../../../menu";
import { KTSVG } from "../../../components/Icon/KTSVG";
import BackupForm from "../form/BackupFom";
import { BackupService } from "../../../services/backup/backupService";


const CreateBackup: FC = () => {

    const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const handleCreation = async (values: any) => {
		setLoading(true);

		try {
			setLoading(true)
			let response = await (await (new BackupService()).createBackup(values)).getResponseData();
			if(response.success) {
				toast.success(response.message);
				navigate(-1)
			} else {
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al crear la configuracion de backup');    
		}finally{
			setLoading(false);
		}
		
	}

    return (
        <Fragment>
			<div className="container-fluid">
				<div className={`card mt-3`}>
					<div className="card-header bg-success mb-3">
						<div className="card-title flex-row">
						<span className='card-label fw-bolder text-light fw-bold fs-1 d-flex align-items-end'>
							<KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-1 p-0 text-light" />
						</span>
						<span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
							NUEVA CONFIGURACION DE BACKUP
						</span>
						</div>
					</div>
					<div className="card-body pt-3">
						<BackupForm submit={handleCreation} isLoading={loading}/>
					</div>
				</div>
			</div>
		</Fragment>
    )
}

export default CreateBackup;
