import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../../services/user/userService";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { EditUserFieldsModel } from "../../../types/user-type";
import { KTSVG } from "../../../components/Icon/KTSVG";
import { Spinner } from "react-bootstrap";
import { ProjectService } from "../../../services/project/projectService";
import ProjectForm from "../form/ProjectFom";
import PageLayoutData from "../../../components/Page/PageLayoutData";

const ProjectEdit: FC = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();

  const fetchProject = useCallback(async () => {
    const projectService = new ProjectService();
    const response = await projectService.getProjectById(id as string);
    return response.getResponseData() as any;
  }, [id]);

  const [data] = useFetch(fetchProject);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  const handleUpdate = async (values: EditUserFieldsModel) => {
    setLoading(true);

    try {
      setLoading(true)
      let response = await (await (new ProjectService()).editProject({ ...values, projectId: values.id })).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error('Error al editar Usuario');
    } finally {
      setLoading(false);
    }

  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className={`card mt-3`}>
          <div className="card-body pt-3">
            {
              data ? (
                <>
                  <PageLayoutData
                    pageTitle={`Editar proyecto: ${data.name}`}
                    sidebar={true}
                    breadcrumb={[
                      {
                        text: 'Módulos',
                        link: '/'
                      },
                      {
                        text: 'Admin. Proyectos',
                        link: '/admin-projects'
                      },
                      {
                        text: 'Editar Proyecto',
                        link: '#'
                      },
                      {
                        text: data.name,
                        link: `#`
                      }
                    ]} />
                    <ProjectForm isLoading={false} submit={handleUpdate} projectData={data} />
                </>
                
              ) : (<div className="text-center d-flex justify-content-center"><Spinner /></div>)
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProjectEdit;
