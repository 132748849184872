import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { TablesWidget9 } from "../../../components/widgets";
import { useFiltersPR } from "../../../components/filters/FiltersProvider";
import { UsersApiResponse } from "../../../types/user-type";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import moment from "moment";
import PageContentTitle from "../../../components/Page/PageData";
import { CustomDrawer } from "../../../components/Drawer/CustomDrawer";
import { handleConfirmationAlert } from "../../../components/SweetAlert/ConfirmationAlert";
import ProjectTypeListFilters from "../filters/ProjectTypeListFilters";
import SpinnerCustom from "../../../components/Spinner/SpinnerCustom";
import { ProjectTypeService } from "../../../services/project/projectTypeService";
import ProjectTypeCreateModal from "../create/ProjectTypeCreateModal";
import ColorPicker from "../../../components/form/ColoPicker";

const breadcrumbs = [
  {
    title: 'ADMINISTRACIÓN',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'TIPOS DE PROYECTOS',
    path: 'admin/projects/project-types',
    isSeparator: true,
    isActive: true,
  },
]

export const ProjecTypeList: FC = () => {

  const {userCan} = useContext(PrivilegeContext)

  const [isOpenCreate ,setIsOpenCreate] = useState(false);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [operationTypeToEdit, setOperationTypeToEdit] = useState<any>(null);

  const actions = [
    {
      show: userCan('create','projectTypes'),
      title: "Crear Tipo de proyecto",
      icon : '/media/icons/duotune/general/gen041.svg',
      colorIcon: "text-light",
      className: 'btn-success me-3 fw-bold text-light',
      callback: () => {
        userCan('create','projectTypes') && setIsOpenCreate(true);
      }
    },
    {
      title: "Filtros",
      icon : '/media/icons/duotune/general/gen031.svg',
      className: 'btn-info me-3 fw-bold',
      callback: () => {
        setOpenFilterDrawer(true);
      }
    }
  ]

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchProjectTypes = useCallback(async () => {
    const projectTypeService = new ProjectTypeService();
    const response = await projectTypeService.getProjectTypes(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchProjectTypes);

  const handleSearch = (search: string) => {
    updateFilters({ search_array : search });
  }

	const deleteProjectType = async (id: string) => {
		let response = (await new ProjectTypeService().deleteProjectType(id)).getResponseData();
		if (response.success) {
			toast.success('Tipo de proyecto eliminado correctamente');
			refetch();
		}
	}

  const _onCloseModal = (message?: string) => {
    toast.success(message ? message : "Accion Realizada correctamente");
    refetch();
  }

  return (
    <>
      {
        data ? (
            <>
              <CustomDrawer title="Tipos de Proyectos" anchor="right" open={openFilterDrawer} onClose={handleClose}>
                <ProjectTypeListFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
              </CustomDrawer>
              <PageContentTitle title='Tipos de Proyectos' breadcrumbs={breadcrumbs} actions={actions}/>
              <TablesWidget9 className="card-primary m-3" title="Project Types"
                subtitle="List of Project Types"
                defaultLimit={filters.limit || 50}
                defaultOrder={filters.filter_order || undefined}
                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                data={data?.projectTypes ? data.projectTypes : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit, 
                  pageCount: (data as UsersApiResponse) ? data.lastPage : 1, 
                  currentPage: filters.page,
                  handlePagination: updatePage, 
                  handlePerPage: updatePageSize
                }}
                columns={
                  [
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="text-center">
                            {element.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Descripción",
                      keyValue: "description",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span>{element.description}</span>
                          </div>
                        )
                      }
                    },
                    {
                      name: "Color",
                      keyValue: "color",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center" key={element.color}>
                                <ColorPicker item={element} handleSave={() => { }} disabled={true} filters={filters} />
                          </div>
                        )
                      }
                    },
                    {
                      name: "Fecha de creación",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                          </div>
                        )
                      },
                    },   
                    {
                      name: "Última Modificación",
                      keyValue: "updatedAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (item: any) => {
                        return <div className="text-center text-muted">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                      }
                    }, 
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]
                }
                actions={
                  [
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-black',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-black ${!userCan('edit','projectTypes') ? 'd-none' : ''}`,
                      description: "Editar tipo de proyecto",
                      callback: (item: any) => {
                        setOperationTypeToEdit(item);
                        setIsOpenEdit(true);
                      },
                    },
      
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${!userCan('delete','projectTypes') ? 'd-none' : ''}`,
                      description: "Eliminar tipo de proyecto",
                      callback: (item: any) => {
                          handleConfirmationAlert({
                            title: "Eliminar Tipo de Proyecto",
                            text: "¿Está seguro que desea eliminar este tipo de proyecto? Todos los proyectos asociados a este tipo de proyecto quedarán huérfanos.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteProjectType(item.id);
                            },
                          });
                      },
                    },
                  ]
                }
              ></TablesWidget9>
              {
                isOpenCreate && (
                  <ProjectTypeCreateModal
                    isOpen={isOpenCreate}
                    setIsOpen={setIsOpenCreate}
                    onClose={_onCloseModal}
                    mode="create"
                  />)
              }
              {
                isOpenEdit && (
                  <ProjectTypeCreateModal
                    isOpen={isOpenEdit}
                    setIsOpen={setIsOpenEdit}
                    onClose={_onCloseModal}
                    projectType={operationTypeToEdit}
                    mode="edit"
                  />)
              }
            </>
        ) : (
          <>
            <SpinnerCustom />
          </>
        )
      }
    </>
  );
}