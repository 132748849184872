import {FC, useState, useRef, useEffect} from 'react';
import { DocumentService } from '../../services/documents/documentService';
import { toAbsoluteUrl } from '../../utils/utils';
import { AxiosResponse } from 'axios';
import { Spinner } from 'react-bootstrap';
import SpinnerCustom from '../Spinner/SpinnerCustom';


interface IAsyncImg {
    src: string | ArrayBuffer | null,
    isBackground?: boolean,
    height?: string,
    width?: string,
    styles?: string
    noReferer?: boolean
    type?: "avatar" | "preview" | null
    transparent?: boolean
}

const DEFAULT_IMAGE_URL = "/media/misc/image.png";

const AsyncImg: FC<IAsyncImg> = ({src, styles='', isBackground = false, noReferer, transparent, type,   ...props}) => {


    const [imgSrc, setImgSrc] = useState<string>(toAbsoluteUrl(DEFAULT_IMAGE_URL));

    const divRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async (docId: any) => {
            try {
                setLoading(true);
                const response = (await (new DocumentService()).renderDocument(docId)).getResponse() as AxiosResponse;
                if (response.status === 200 && response.data) {
                    let file = new Blob([response.data], { type: 'image/jpeg' });
                    let stream = URL.createObjectURL(file);
                    setImgSrc(stream);
                }
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };
        src ? fetchData(src) : setError(true);
    }, [src]);

    if (loading) return <SpinnerCustom />;
    
    if (type === "avatar") {
        styles += transparent ? "avatar" : "avatar";
    }

    if (type === "preview") {
        styles += transparent ? " img-thumbnail-transparent" : " img-thumbnail";
    }
    
    if ( error ) { 
        return <img {...props} className={styles} src={toAbsoluteUrl(DEFAULT_IMAGE_URL)} alt='img'/> 
    }

    if (isBackground && divRef.current) {
        divRef.current.style.backgroundImage = `url(${imgSrc})`;
        divRef.current.style.backgroundPosition = 'center';
        divRef.current.style.backgroundSize = 'cover';
        //@ts-ignore
        divRef.current.referrer = noReferer == true ? 'no-referrer' : '';
        return (
            <div ref={divRef} className={styles} style={{ ...props }}></div>
        )
    }

    //@ts-ignore
    return <img {...props} referrer={noReferer ? 'no-referrer' : ''} className={styles} src={imgSrc} alt='img' onError={() => {
        setError(true);
    }}/>
}

export default AsyncImg;