import { FC, Fragment, useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CompanyForm from "../form/CompanyForm";
import { CompanyService } from "../../../services/company/companyService";
import useFetch from "../../../hooks/useFetch";
import { Company } from "../../../types/company-type";
import { KTSVG } from "../../../components/Icon/KTSVG";

const CompanyEdit: FC = () => {

	const navigate = useNavigate();

	const { id = '' } = useParams<{ id: string }>();

	const fetchUser = useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.getCompanyById(id as string);
		return response.getResponseData() as Company;
	}, [id]);

	const [data] = useFetch(fetchUser);
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);


	const handleUpdate = async (values: any) => {
		setLoading(true);

		const formData = new FormData();

		formData.append('companyId', data['id']);

		for (let key in values) {
			if (!values[key]) {
				formData.append(key, '');
			} else {
				formData.append(key, values[key])
			}
		}


		try {
			setLoading(true)

			let response = await (await (new CompanyService()).editCompany(formData, values.logo instanceof File)).getResponseData();
			if (response.success) {
				toast.success(response.message);
				navigate(-1);
			} else {
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al editar Organización');
		} finally {
			setLoading(false);
		}

	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className={`card mt-3`}>
				<div className="card-header bg-success mb-3">
					<div className="card-title flex-row">
					<span className='card-label fw-bolder text-light fw-bold fs-1 d-flex align-items-end'>
						<KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-1 p-0 text-light" />
					</span>
					<span className='card-label fw-bolder text-light fw-bold fs-1 align-text-bottom'>
						NUEVA ORGANIZACIÓN
					</span>
					</div>
				</div>
				<div className="card-body pt-3">
					{
						data ? (
							<CompanyForm isLoading={false} submit={handleUpdate} companyData={data} />
						) : (<div className="text-center d-flex justify-content-center"><Spinner /></div>)
					}
				</div>
				</div>
			</div>
		</Fragment>
	)
}

export default CompanyEdit;