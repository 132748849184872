import clsx from 'clsx'
import {useEffect, useRef} from 'react'
import {SidebarMenu} from './sidebar-menu/SidebarMenu'
import {SidebarLogo} from './SidebarLogo'
import { useDispatch, useSelector } from 'react-redux'
import { selectSidebarVisibility, setSidebarVisibility } from '../../../redux/sidebarSlice'

const Sidebar = () => {

  const sidebarRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch();

  const sidebarVisible = useSelector(selectSidebarVisibility);

  const mediaQueryList = window.matchMedia("(min-width: 1024px)");

  // Función para manejar el clic fuera del sidebar
  const handleOutsideClick = (event: any) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      event.target.id !== 'kt_sidebar_mobile_toggle'
    ) {
      // Despacha la acción para cambiar el estado del sidebar
      dispatch(setSidebarVisibility(false));
    }
  };

  useEffect(() => {

    const sidebar = document.getElementById('kt_app_sidebar');

    if (sidebarVisible) {
      sidebar?.classList.add('drawer', 'drawer-start', 'drawer-on');
    } else {
      sidebar?.classList.remove('drawer', 'drawer-start', 'drawer-on');
    }

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [sidebarVisible, setSidebarVisibility]);

  useEffect(() => {
    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        dispatch(setSidebarVisibility(false));
      }
    };
  
    mediaQueryList.addEventListener('change', handleMediaQueryChange);
  
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    };
  }, [mediaQueryList]);

  return (
    <>
        <div
          ref={sidebarRef}
          id='kt_app_sidebar'
          className={clsx('app-sidebar flex-column aside-toggle')}
          // @ts-ignore
          data-kt-drawer='true'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction='start'
          data-kt-drawer-toggle='#kt_aside_mobile_toggle'
          data-kt-toggle='true'
          data-kt-toggle-state='active'
          data-kt-toggle-target='body'
          data-kt-toggle-name='aside-minimize'
        >
          <SidebarLogo sidebarRef={sidebarRef} />
          <SidebarMenu />
        </div>
    </>
  )
}

export {Sidebar}
