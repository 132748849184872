import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomSelect from '../form/CustomSelect';

interface RenderDateBackupProps {
    formik: any,
    showErrors : any,
    verifyClass : any
}

const RenderDateBackup:FC<RenderDateBackupProps> = ({ formik, showErrors, verifyClass }) =>  {

    const weeekDays = [
        { value: 7, label: 'Lunes' },
        { value: 2, label: 'Martes' },
        { value: 3, label: 'Miercoles' },
        { value: 4, label: 'Jueves' },
        { value: 5, label: 'Viernes' },
        { value: 6, label: 'Sabado' },
        { value: 1, label: 'Domingo' }
    ]

    const monthDays = Array.from({ length: 30 }, (_, index) => (
        { value: index + 1, label: index + 1}
    ));

    const [frequencyBackup, setFrequencyBackup] = useState(formik.values.frequencyBackup);

    useEffect(() => {
        setFrequencyBackup(formik.values.frequencyBackup);
    }, [formik.values.frequencyBackup]);

    switch (frequencyBackup) {
        case 'Monthly':
            return (
                <Form.Group className="mt-3" controlId="formLastName">
                    <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Dia de backup</Form.Label>
                    <div className="row">
                        <div className="col-md-6">
                            <CustomSelect
                                defaultValue={monthDays.find((day: any) => day.value === formik.values.monthDayBackup) || ''}
                                onChangeSingle={(selected: any) => { formik.setFieldValue('monthDayBackup', selected.value) }}
                                options={monthDays} />
                            {showErrors('monthDayBackup')}
                        </div>
                        <div className="col-md-6">
                            <Form.Control
                                type="time"
                                value={formik.values.dayBackup}
                                onChange={formik.handleChange}
                                name="dayBackup"
                                className={verifyClass('dayBackup')}
                            />
                        </div>
                    </div>
                </Form.Group>
            );
        case 'Weekly':
            return (
                <Form.Group className="mt-3" controlId="formLastName">
                    <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Dia de backup</Form.Label>
                    <div className="row">
                        <div className="col-md-6">
                            <CustomSelect
                                defaultValue={weeekDays.find((day: any) => day.value === formik.values.weekDayBackup) || ''}
                                onChangeSingle={(selected: any) => { formik.setFieldValue('weekDayBackup', selected.value) }}
                                options={weeekDays} />
                            {showErrors('weekDayBackup')}
                        </div>
                        <div className="col-md-6">
                            <Form.Control
                                type="time"
                                value={formik.values.dayBackup}
                                onChange={formik.handleChange}
                                name="dayBackup"
                                className={verifyClass('dayBackup')}
                            />
                        </div>
                    </div>
                </Form.Group>
            );
        case 'Daily':
            return (
                <Form.Group className="mt-3" controlId="formLastName">
                    <Form.Label className='form-label fs-6 fw-bolder text-dark mb-3'>Hora de backup</Form.Label>
                    <Form.Control
                        type="time"
                        value={formik.values.dayBackup}
                        onChange={formik.handleChange}
                        name="dayBackup"
                        className={verifyClass('dayBackup')}
                    />
                    {showErrors('dayBackup')}
                </Form.Group>
            );
        default:
            return null;
    }
}

export default RenderDateBackup;