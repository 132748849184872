import axios from "axios";
import { RestServiceConnection } from "../restServiceConnection";

export class LoginService extends RestServiceConnection {

 /**
  * 
  * @ES Autentica las credenciales de un usuario
  * @EN Authenticate user credentials
  * 
  * @param username 
  * @param password 
  * @returns API response
  */ 

  authUserCredentials = async (username: string, password: string) => {
    this.response = await this.makeRequest({
      method: "POST",
      url: "/login_check",
      data: {
        username: username,
        password: password,
      },
    });
    return this;
  };

 /**
  * 
  * @ES Obtiene el usuario autenticado
  * @EN Get authenticated user
  * 
  * @param email 
  * @returns API response
  */ 

  sendEmailToResetPassword = async (email: string) => {
    let response = axios.post(this.apiPublicUrl + "/send-email", {
      email: email,
    });
    return response;
  }

 /**
  * 
  * @ES Obtiene el usuario autenticado
  * @EN Get authenticated user
  * 
  * @param password 
  * @param password_confirmation 
  * @param token 
  * @returns API response
  */ 

  resetPassword = async (password: string, password_confirmation: string, token: string) => {
    let response = axios.post(this.apiPublicUrl + "/reset-password", {
      password: password,
      password_confirmation: password_confirmation,
      query_token: token,
    });
    return response;
  }
}