import {Navigate, Route, Routes} from 'react-router-dom';
import Login from '../pages/auth/Login';
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import AppLayout from '../pages/layout/Layout';
import ProtectedRoute from './ProtectedRoute';
import contents from './routes';
import ResetPassword from '../pages/auth/ResetPassword';
import { ResetForm } from '../pages/auth/ResetForm';
import ErrorPage from '../pages/extra/ErrorPage';
import { LayoutProvider } from '../pages/layout/LayoutProvider';

const AppRouter = () => {

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const renderRoute = (page: any, index: number) => {

    page.element = <ProtectedRoute component={page.element} access={page.access}/>;

    return (
      <Route key={index} {...page}>
        {page.sub?.map((subPage: any, index: number) =>
          renderRoute(subPage, index)
        )}
      </Route>
    );

  };

  return (
      <Routes>
        <Route element={<LayoutProvider><AppLayout/></LayoutProvider>}>
          {contents.map((page, index) => renderRoute(page, index))}
        </Route>
        <Route path="/login" element={<Login/>}/>
        {/*TODO: Change redirect to default route when an authenticathed user go to / path. Users for now*/}
        {isAuthenticated && <Route path="/" element={<Navigate to={"/users"}/>}/>}
        {!isAuthenticated && <Route path="*" element={<Navigate to={"/login"}/>}/>}
        {!isAuthenticated && <Route path="/recover-password" element={<ResetForm />} />}
        {!isAuthenticated && <Route path="/reset-password" element={<ResetPassword />} />}
        {isAuthenticated && <Route path="*" element={<ErrorPage typeError='404'/>}/>}

      </Routes>
  );
};

export default AppRouter;