
import { RestServiceConnection } from '../restServiceConnection';

/**
 * @ES Endpoint de documentos
 * @EN Document endpoint
 */

const DOCUMENTS_ENDPOINT = '/documents';

export class DocumentService extends RestServiceConnection {

   /**
    * 
    * @ES Renderiza un documento
    * @EN Render a document
    * 
    * @param id 
    * @returns API response 
    */ 

    renderDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            responseType: 'blob',
            url: DOCUMENTS_ENDPOINT + '/render',
            data: {
                document: id
            },
            headers: {
                "Content-Type": "application/json",
                "responseType": "blob"
            }
        }, true);
        return this;
    }

    renderDocumentURL = (id?: string) => {
        if(!id) return '';
        return `${process.env.REACT_APP_API_URL}${DOCUMENTS_ENDPOINT}/render-document/${id}`
    }
}